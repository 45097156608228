/*==================
   25. Responsive css
===================*/
@media (min-width: 1470px) {
  .container {
    max-width: 1440px;
  }
}

@media (max-width: 1470px) {
  .menu {
    .menu-content {
      .menu-right {
        .full-mega-menu {
          min-width: 950px !important;
        }
      }
    }
  }
}

@media (max-width: 1440px) {

  .animation-section {
    .decor-animation {
      .decor10 {
        width: 18%;
        bottom: 18%;
      }
    }
    &.main_section {
      .decor-animation {
        .decor9 {
          width: 10%;
          bottom: 19%;
        }
        .decor11 {
          width: 20%;
          top: 4%;
        }
      }
    }
  }

  .product_box {
    .product_image {
      .product_icon {
        i {
          padding: 7px;
        }
      }
    }
  }

  // Title
  .title_prime {
    h2 {
      line-height: 50px;
      padding-top: 40px;
    }
    p {
      margin-top: -16px;
      padding-top: 40px;
    }
  }
  .title_second {
    h5 {
      margin-top: -3px;
    }
  }

  // Button
  .btn_dark {
    padding: 13px 30px;
    letter-spacing: 2px;
  }
  .btn_lite {
    padding: 13px 30px;
    letter-spacing: 2px;
  }

  // Menu
  .menu {
    .menu-content {
      .navbar {
        .nav-link {
          padding-right: 15px;
          padding-left: 15px;
        }
        .navbar-brand {
          img {
            width: 99px;
          }
        }
      }
    }
  }

  // Home slider
  .home-slider {
    .main_section {
      height: 85vh;
      .main_section_contain {
        height: 85vh;
        h1 {
          line-height: 75px;
        }
        p {
          letter-spacing: 0.11em;
          padding: 40px 0;
        }
        .new {
          margin-bottom: 20px;
          margin-left: -5px;
          margin-top: 32px;
        }
      }
    }
  }

  // advertisment section
  .advertise_section {
    .bottle {
      img {
        width: 85%;
        bottom: -50px;
      }
    }
  }

  // Testimonial
  .testimonial_section {
    .testimonial {
      .comment_box {
        margin-left: 50px;
      }
    }
    .nav_image {
      margin-bottom: -25px;
      padding-top: 0;
    }
  }

  // Footer
  .footer {
    .footer-title {
      margin-top: -4px;
    }
    .links {
      padding: 0;
    }
    .footer-content {
      .mo-number {
        margin-bottom: 0;
      }
      .jvector-map-height {
        .jvectormap-container {
          height: 230px;
          svg {
            top: -50px;
            height: 230px;
          }
        }
      }
    }
  }

  //Bread Crumb
  .theme-breadcrumb {
    p {
      margin-bottom: -6px;
    }
    h2 {
      margin-top: 6px;
    }
  }

  // about page section
  .about-page {
    p {
      margin-bottom: -7px;
    }
    h4 {
      margin-top: -7px;
    }
  }
  .team {
    h6 {
      margin-bottom: -3px;
    }
  }

  // // Call-to-action
  .call-to-action-section {
    .contact {
      .newsletter {
        padding-left: 35px;
      }
      .media {
        .media-body {
          padding-right: 35px;
        }
      }
    }

    .email-box {
      .email {
        width: 400px;
      }
    }
  }

  // Feature section
  .feature-section {
    .bottle-image {
      margin-top: 75px;
    }

    .feature-box {
      > div {
        &:nth-child(1) {
          margin-top: 200px;
        }

        &:nth-child(2) {
          margin-top: 80px;
        }
      }
    }
  }

  // Mission section
  .mission-section {
    .title_prime {
      .para2 {
        margin-top: -5px;
        padding-top: 30px;
      }

      .btn_dark {
        margin-top: 40px;
      }
    }
  }

  // Advertise_section
  .advertise_section {
    padding: 100px 0;
    margin-top: 116px;

    .adv_contain {
      margin-top: -3px;

      p {
        padding: 25px 0 0 0;
      }
    }
  }

  // Service section
  .service-section {
    .title_prime {
      .btn {
        margin-top: 30px;
      }
    }
    .image-container {
      margin: 40px 0;
    }
  }

  //  About-us section
  .about-us-section {
    .main-image {
      h4 {
        top: 59px;
        right: -28.5%;
      }

      .sub-image {
        width: 235px;
      }
    }

    .main-contain {
      h2 {
        line-height: 50px;
        margin-top: -8px;
      }

      .para1 {
        padding-top: 35px;
      }

      .para2 {
        padding-top: 15px;
      }

      .btn_lite {
        margin-top: 20px;
      }
    }
  }

  // General Product_box
  .product_box {
    .product_image {
      .timer-box {
        .timer {
          .padding-l {
            padding-left: 15px;
          }

          span {
            width: 55px;

            .timer-cal {
              padding-left: 7px;
            }
          }
        }
      }
    }
  }

  // Branded Hair Color
  .hair_color_section {
    .hair_color {
      .main-box {
        .main-color {
          padding: 10px;

          &:before {
            height: 22px;
            width: 22px;
            border: 6px solid white;
          }
          li {
            padding: 10px;
          }
        }
      }
    }
  }

  // inner pages //
  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width: 100%;
          border-left: 1px solid $theme-primary;
          border-bottom: 1px solid $theme-primary;
          text-align: center;
        }

        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left: 1px solid $theme-primary;
        }

        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;

          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }

        .product-page-filter {
          border-right: 1px solid  $theme-primary;
          width: 30%;
        }

        .product-page-per-view {
          width: 30%;
        }
      }

      .popup-filter {
        .collection-view {
          width: 10%;
        }

        .product-page-per-view {
          width: 25%;
        }
      }
    }
  }

  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 38px;
        }
      }
    }

    tbody {
      tr {
        td {
          min-width: 175px;

          .qty-box {
            .input-group {
              .form-control {
                width: 75px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 41px;
        }
      }
    }
  }

  .product-right {
    .product-icon {
      .product-social {
        li {
          padding-right: 10px;
        }
      }
    }
  }

  .product-form-box {
    .timer {
      padding-left: 17px;

      span {
        width: 55px;
      }
    }
  }

  .color-selector {
    ul {
      li {
        &.active {
          &:after {
            top: 9px;
            right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1367px) {
  // Advertise_section
  .advertise_section {
    padding: 100px 0;
    margin-top: 122px;
  }

  // Home slider
  .home-slider {
    .main_section {
      height: 80vh;
      .main_section_contain {
        height: 80vh;
        h1 {
          line-height: 70px;
          letter-spacing: 0.025em;
        }
        p {
          letter-spacing: 0.075em;
          padding: 35px 0;
        }
        .new {
          margin-bottom: 20px;
          margin-top: 28px;
        }
      }
    }
  }
  // about page section
  .about-page {
    h4 {
      margin-top: -6px;
    }
  }
}

@media (max-width: 1199px) {
  section {
    padding: 75px 0;
  }
  .footer  {
    .container{
      .row{
          div{
            &+div{
              &+div{
                margin-top: 30px;
              }
            }
          }
      }
    }
    p {
      margin-bottom: 0;
    }
    .links {
     padding-left: 15px;
      padding-right: 15px;
    }
  }
  .collection-filter-block{
    padding: 0 20px;
    .collapse-block-title{
      margin: 25px 0 0 0;
    }
  }
  .collection-filter {
    .theme-card {
      padding: 20px;
    }
  }
  .theme-card {
    .slick-next {
      right: -10px;
    }
    .slick-prev{
      right: 10px;
    }
  }
  .section-t-space {
    padding-top: 75px;
  }
  .section-b-space {
    padding-bottom: 75px;
  }
  // Title
  .title_prime {
    h2 {
      max-width: 335px;
      line-height: 45px;
      padding-top: 35px;
    }
    p {
      padding-top: 35px;
    }
  }
  .title_second {
    margin-bottom: 37px;
    h5 {
      margin-top: -2px;
    }
  }

  //product-box
  .prodcut_left_margin {
    > div {
      &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3) {
        margin-top: 30px;
      }
    }
  }

  // Button
  .btn_dark {
    padding: 12px 24px;
    letter-spacing: 1px;
  }
  .btn_lite {
    padding: 12px 24px;
    letter-spacing: 1px;
  }
  // Menu

  .menu {
    &.active {
      .according-menu {
        font: normal normal normal 14px/1 FontAwesome;
        &:before {
          content: "+";
          position: absolute;
          right: 2px;
          top: 2px;
        }
      }
    }
    .according-menu {
      font: normal normal normal 14px/1 FontAwesome;
      &:before {
        content: "+";
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
    .menu-content {
      .menu-right {
        .full-mega-menu {
          right: 0 !important;
          min-width: 100% !important;
          margin-left: 0 !important;
          padding: 0 40px 0 24px !important;
          margin-top: 5px;
          h5 {
            margin: 10px 0 !important;
            background: transparent;
            color: $theme-primary;
            font-size: 16px;
            text-shadow: none;
            font-weight: 400;
            text-transform: capitalize;
          }
          .mega-box {
            &.col {
              flex-basis:unset !important;
            }
          }
        }
      }
      .menu-left {
        .brand-logo {
          a {
            padding: 0;
          }
        }
      }
      a {
        padding: 10px 15px;
      }
      .toggle-nav {
        display: block;
        margin-right: 25px;
      }
      .sm-horizontal  {
        position: fixed;
        width: 300px;
        top: 0;
        height: 100vh;
        right: -300px;
        z-index: 99;
        transition: all 0.3s ease;
        background: $white;
        overflow: scroll;
        .mobile-back {
          padding: 20px;
          font-size: 18px;
          color: $theme-primary;
          font-weight: 700;
          text-transform: uppercase;
          border-bottom: 1px solid #efefef;
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        ul {
          li {
            a {
              text-transform: capitalize;
              font-weight: 400;
            }
          }
        }
      }
      .navbar {
        .nav-link {
          padding-right: 8px;
          padding-left: 8px;
        }
        .navbar-brand {
          img {
            width: 90px;
          }
        }
      }
    }
  }

  .main-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    background: white;
  }

  // Home slider
  .home-slider {
    .main_section {
      height: 75vh;
      .main_section_contain {
        height: 75vh;
        h1 {
          line-height: 65px;
        }
        p {
          letter-spacing: 0.055em;
          padding: 30px 0;
        }
        .new {
          margin-bottom: 15px;
          margin-left: -5px;
          margin-top: 25px;
        }
      }
    }
  }

  // Call-to-action
  .call-to-action-section {
    .contact {
      .media {
        .media-body {
          padding-right: 0;
        }
      }
      .newsletter {
        padding-left: 15px;
      }
    }

    .email-box {
      .email {
        width: 375px;
      }
    }
  }

  // Feature section
  .feature-section {
    .bottle-image {
      margin-top: 65px;
    }

    .feature-box {
      > div {
        &:nth-child(1) {
          margin-top: 175px;
        }

        &:nth-child(2) {
          margin-top: 75px;
        }
      }
    }
  }

  // Mission section
  .mission-section {
    .box-container {
      .box-item {
        .flip-box {
          .flip-box-back {
            .inner {
              h6,
              p {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    .title_prime {
      .para2 {
        margin-top: -7px;
        padding-top: 25px;
      }

      .btn_dark {
        margin-top: 33px;
      }
    }
  }

  // Service section
  .service-section {
    .image-container {
      margin: 33px 0;
    }

    .title_prime {
      .btn {
        margin-top: 23px;
      }
    }
  }

  //  About-us
  .about-us-section {
    .main-image {
      h4 {
        top: 53px;
        right: -32%;
      }

      .sub-image {
        width: 200px;
      }
    }

    .main-contain {
      h2 {
        line-height: 40px;
        margin-top: -5px;
      }

      .para1 {
        padding-top: 25px;
      }

      .para2 {
        padding-top: 10px;
      }

      .btn_lite {
        margin-top: 15px;
      }
    }
  }

  //Blog Section
  .blog_section {
    .blog {
      .card {
        .card-body {
          h6 {
            padding: 5px 0;
          }
        }
      }
    }
  }

  // Branded Hair Color
  .hair_color_section {
    .hair_color {
      .main-box {
        .main-color {
          padding: 7px;

          &:before {
            height: 20px;
            width: 20px;
            border: 5px solid white;
          }
          li {
            padding: 7px;
          }
        }
      }
    }
  }

  // Footer
  .footer {
    .footer-content {
      .mo-number {
        margin-bottom: 10px;
      }
      .jvector-map-height {
        height: 230px;
        margin-bottom: -4px;
        .jvectormap-container {
          height: 230px;
          svg {
            top: 0;
            height: 230px;
          }
        }
      }
    }
  }

  // General Product_box
  .product_box {
    .product_image {
      .product_icon {
        i {
          padding: 5px;
        }
      }
    }
  }

  .product_contain {

    h5 {
      padding-bottom: unset;
    }
  }

  //Advertise_section
  .advertise_section {
    padding: 68px 0;
    margin-top: 32px;

    .bottle {
      img {
        bottom: -50px;
      }
    }
    .adv_contain {
      margin-top: -2px;
      h3 {
        margin-bottom: 0;
      }
    }
  }

  // Brand_logo section
  .brand_section {
    .logo {
      img {
        width: 90%;
      }
    }
  }

  // Testimonial
  .testimonial_section {
    .testimonial {
      .space {
        .test_image {
          img {
            width: 125px;
          }
          .chat_icon {
            width: 60px;
          }
        }
        .comment_box {
          margin-left: 40px;
          margin-top: 15px;
        }
      }
    }
    .nav_img {
      padding: 35px 13%;
    }
  }

  // Inner pages //
  .cart-section,
  .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 100%;
        }
      }
    }
  }

  //Search
  .search-block {
    .form-header {
      padding-top: 15px;
    }
  }

  .blog-detail-page {
    .comment-section {
      li {
        padding-top: 45px;
        padding-bottom: 45px;
      }
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width: 100%;
          border-left: 1px solid  $theme-primary;
          border-bottom: 1px solid  $theme-primary;
        }

        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left: 1px solid  $theme-primary;
        }

        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;

          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }

        .product-page-filter {
          width: 30%;
        }

        .product-page-per-view {
          width: 30%;
        }
      }
    }
  }


  .collection-collapse-block {
    .collection-collapse-block-content {
      .collection-brand-filter {
        .category-list {
          li {
            &:first-child {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .collection-filter-block {
    .product-service {
      .media {
        .media-body {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }

  .product-right {
    h2 {
      font-size: 20px;
    }
  }

  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 30px;

          p {
            line-height: 1.5;
          }
        }
      }
    }
  }

  .blog-page {
    .blog-media {
      margin-bottom: 20px;

      .blog-right {
        display: block;
        margin-top: 15px;

        p {
          line-height: 1.3;
        }
      }
    }
  }

  .tab-product {
    .tab-content {
      &.nav-material {
        .single-product-tables {
          table {
            width: 50%;
          }
        }
      }
    }
  }

  .product-description-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }

    .product-icon {
      .product-social {
        li {
          padding-right: 4px;
        }
      }

      .wishlist-btn {
        span {
          padding-left: 4px;
        }
      }
    }
  }

  .product-form-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }

    .timer {
      padding-left: 17px;

      span {
        width: 55px;
      }
    }

    .product-buttons {
      .btn_dark,
      .btn-outline {
        padding: 7px 13px;
      }
    }
  }

  .product-accordion {
    .single-product-tables {
      table {
        width: 70%;
      }
    }
  }

  .tab-product {
    .flex-column {
      flex-direction: unset !important;
      justify-content: center;
    }

    .nav-material {
      &.nav-tabs {
        border-bottom: 1px solid $round-border;
        border-right: 0;

        .nav-item {
          .nav-link {
            &.active {
              background-color: unset;
            }
          }
        }
      }
    }
  }
  .tab-border {
    padding-top: 0;
  }

  .color-selector {
    ul {
      li {
        &.active {
          &:after {
            right: -1px;
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }

  // working-hourse //
  .working-hourse-main  {
    .working-hourse-icon {
      >div  {
        &:nth-child(n+4) {
          .logo {
            padding-top: 30px;
          }
        }
        .logo {
          padding-bottom: 30px;
          img  {
            width: 40px; 
            height: auto;
          }
        }
      }
    }
    .working-hourse-block {
      >div {
        &:nth-child(2) {
          padding-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {

  //About us
  .about-us-section {
    .main-image {
      h4 {
        top: 49px;
        right: -21%;
      }
    }
  }
  //Advertise Section
  .advertise_section {
    padding: 99px 0;
    margin-top: 34px;
  }
  //slider
  .slick-prev {
    left: 0;
    z-index: 7;
  }
  .slick-next {
    right: 0;
    z-index: 7;
  }
}

@media (max-width: 991px) {
  section {
    padding: 60px 0;
  }
  .section-t-space {
    padding-top: 60px;
  }
  .section-b-space {
    padding-bottom: 60px;
  }
  .alert{
    max-width: 45% !important;
  }
  .map{
    order: 1;
    margin-top: 1.5rem;
  }
  .checkout-page{
    .common-card{
      margin-top: 30px;
      padding-bottom: 0;
    }
  }
  .quick-view-modal {
    .product-right {
      .product-buttons {
        justify-content: center;
      }
    }
  }
  .abjust-space{
    margin-top: 20px;
    height: auto;
  }
  .search-block {
    .form-header {
      padding: 15px 20% 0 20%;
    }
  }

  .decor-animation {
    opacity: 0.6;
  }

  //forget-password
  .pwd-page {
    .theme-form {
      input {
        margin:  0 auto ;
      }
    }
    .forget-btn {
      margin-top: 25px;
    }
  }

  // Button
  .btn_dark {
    padding: 10px 20px;
    &:after {
      left: -85px;
    }
  }
  .btn_lite {
    padding: 10px 20px;
    &:after {
      left: -85px;
    }
  }

  // Home slider
  .home-slider {
    .main_section {
      height: 65vh;
      .main_section_contain {
        height: 65vh;
        h1 {
          line-height: 60px;
        }
        p {
          letter-spacing: 0;
          padding: 30px 0;
        }
        .new {
          margin-bottom: 5px;
          margin-left: -10px;
          margin-top: 18px;
        }
      }
    }
  }
  // Menu
  .menu {
    .menu-content {
      padding: 5px 0;
      .navbar {
        background: transparent !important;
        .forground {
          opacity: 0.8;
          visibility: visible;
          z-index: 10;
        }
        .back {
          display: block;
          text-align: right;
          background: transparent;
          border: unset;
          color: $theme-primary;
          font-weight: bold;
          font-family: $font_1;
          font-size: 16px;
          cursor: pointer;
          padding: 15px;
          border-bottom: 1px solid $theme-primary;
          width: 100%;

          &:after {
            content: "\f054";
            font: normal normal normal 14px/1 FontAwesome;
          }
        }
        .navbar-collapse {
          position: fixed;
          transform: translateX(300px);
          top: 0;
          right: 0;
          width: 300px;
          background: $white;
          height: 100vh;
          transition: all 0.5s;
          &.show {
            transform: translateX(0);
            height: 100vh;
            z-index: 9;
          }
          .navbar-nav {
            height: 100vh;
            text-align: left;
            padding-left: 15px;
          }
        }
        .navbar-toggler {
          color: $theme-primary;
        }
        .nav-item {
          .nav-link {
            &:hover {
              background: $white;
              color: $theme-primary;
            }
          }
        }
      }
    }
  }
  .sidenav-overly {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
  }

  // Mission section
  .mission-section {
    .mission-image {
      > div {
        margin-top: 30px;
      }
    }
    .box-container {
      .box-item {
        .flip-box {
          .flip-box-back {
            .inner {
              h6,
              p {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    .title_prime {
      text-align: center;
      .para2 {
        margin-top: -10px;
      }

      h2 {
        max-width: 100%;
      }

      .icon-image {
        margin-left: 0;
      }

      .btn_dark {
        margin-top: 25px;
      }
    }
  }

  // Tap-To-Top
  .tap-top {
    width: 40px;
    height: 40px;
    font-size: 22px;
    padding: 3px;
  }
  //Bread Crumb
  .theme-breadcrumb {
    h2 {
      margin-top: 5px;
    }
  }
  //About Page section
  .about-page {
    h4 {
      margin-top: -5px;
    }
  }

  // Call-to-action
  .call-to-action-section {
    .contact {
      .media {
        .media-body {
          padding-right: 15px;
        }
      }
      float: unset;
      margin: 0 0 5px auto;
      text-align: center;
    }

    .email-container {
      display: flex;
      justify-content: center;
      margin: 5px 0;

      .email-box {
        float: unset;

        .email {
          width: 432px;
          padding: 5px 20px;
        }

        .submit-btn {
          padding: 5px 20px;

          i {
            padding-right: 5px;
          }
        }
      }
    }
  }

  // Feature section
  .feature-section {
    .title {
      h5 {
        margin-top: 0;
      }
    }
    .bottle-image {
      margin-top: 50px;
      margin-bottom: -13px;
    }

    .feature-box {
      > div {
        &:nth-child(1) {
          margin-top: 100px;
        }

        &:nth-child(2) {
          margin-top: 50px;
        }
      }
    }
  }

  // Mission section
  .mission-section {
    .title_prime {
      .para2 {
        padding-top: 20px;
      }
    }
  }
  .about-us-section, .service-section {
    .btn_dark {
      margin-top: 25px;
    }
  }
  // Title Section
  .title_prime {
    h2 {
      line-height: 40px;
      padding-top: 30px;
      max-width: 305px;
      margin-top: -10px;
    }
    p {
      margin-top: -15px;
      padding-top: 30px;
    }

    h5 {
      padding-top: 18px;

      &:before {
        top: 28px;
      }
    }
  }

  .title_second {
    margin-bottom: 35px;
    h5 {
      margin-top: 0;
      line-height: 13px;
    }
    h2 {
      padding-top: 10px;
    }
  }

  // Service section
  .service-section {
    .title_prime {
      .btn {
        margin-top: 19px;
      }
    }

    .image-container {
      margin: 40px 0;

      .handle {
        &:before {
          height: 25px;
          width: 25px;
          border: 5px solid $white;
        }
      }

      .back-div {
        height: 101%;
      }
    }
  }

  // About-us
  .about-us-section {
    .main-image {
      margin-bottom: 0;

      h4 {
        top: 48px;
        right: -32%;
      }

      .sub-image {
        width: 145px;
      }
    }

    .main-contain {
      h2 {
        line-height: 35px;
        margin-top: -2px;
        padding-bottom: 25px;
      }

      .para1 {
        padding-top: 20px;
      }

      .para2 {
        padding-top: unset;
      }

      .btn_lite {
        margin-top: 5px;
      }
    }
  }

  // Branded Hair Color
  .hair_color_section {
    .hair_color {
      .main-box {
        .main-color {
          &:before {
            height: 18px;
            width: 18px;
            border: 5px solid white;
          }
        }
      }
    }
  }

  // General Product_box
  .product_contain {
    padding: 10px 0 0 0;
  }

  // product_section
  .product_section {
    .tab-content {
      .product-row {
        .rank {
          order: 1;
        }
      }
    }
  }

  //Advertise_section
  .advertise_section {
    padding: 35px 0;
    margin-top: 64px;

    .bottle {
      img {
        bottom: -6px;
        height: 304px;
        width: 100%;
      }
    }
    .adv_contain {
      .btn_dark {
        font-size: 14px;
        padding: 7px 15px;
        margin-top: 25px;
      }
    }
  }

  // brand_logo Section...
  .brand_section {
    .logo {
      img {
        width: 80%;
      }
    }

    .brand {
      > div {
        &:nth-child(1) {
          border-bottom: unset;
          border-image: linear-gradient(-90deg, $gradient, $white);
          border-image-slice: 1;
        }

        &:nth-child(2) {
          border-right: unset;
          border-left: 2px solid;
          border-bottom: unset;
          border-image: linear-gradient(0deg, $gradient, $white);
          border-image-slice: 1;
        }

        &:nth-child(3) {
          border-bottom: 2px solid;
          border-top: 2px solid;
          border-image: linear-gradient(90deg, $white, $gradient);
          border-image-slice: 1;
        }

        &:nth-child(5) {
          border-right: unset;
          border-left: unset;
        }

        &:nth-child(4) {
          border-top: 2px solid;
          border-left: 2px solid;
          border-bottom: 2px solid;
          border-image: linear-gradient(90deg, $gradient, $white);
          border-image-slice: 1;
        }

        &:nth-child(6) {
          border-left: 2px solid;
          border-image: linear-gradient(-180deg, $gradient, $white);
          border-image-slice: 1;
        }
      }
    }
  }

  // testimonial section
  .testimonial_section {
    .testimonial {
      .space {
        padding: unset;

        .test_image {
          img {
            width: 115px;
          }

          .chat_icon {
            width: 55px;
          }
        }

        .comment_box {
          text-align: center;
          margin-top: unset;
          margin-left: unset;

          .space {
            margin: 25px 25px 0;
          }
        }
      }
    }

    .rating {
      justify-content: center;
    }

    .nav_image {
      padding-top: 0;
      .slick-current {
        img {
          transform: scale(1.5);
        }
      }
    }

    .slick-slider {
      .slick-prev {
        z-index: 5;
        top: 75%;
      }

      .slick-next {
        top: 75%;
        z-index: 5;
      }
    }
  }

  // Footer
  .footer {
    .footer-content {
      .jvector-map-height {
        height: 165px;
        margin-bottom: -2px;
        .jvectormap-container {
          height: 165px;
          svg {
            height: 165px;
          }
        }
      }
    }
  }

  // inner pages //
  .collection-filter-block {
    .product-service {
      padding: 0;
    }
  }
  .collection-collapse-block {
    padding-bottom: 0;
  }
  .login-page {
    .authentication-right {
      height: auto;
    }
  }

  .color-selector {
    ul {
      li{
        &.active {
          &:after {
            right: 3px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 0 30px;
          height: 100%;
          align-self: center;

          p {
            margin-bottom: 0;
          }
        }
      }

      .slick-track {
        .slick-slide {
          &:nth-child(even) {
            .media {
              border-left: none;
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .blog-detail-page {
    .blog-advance {
      ul {
        margin-bottom: 20px;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .top-banner-content {
    h4 {
      font-size: 20px;
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      border-top: none;

      .product-filter-content {
        .search-count {
          border-top: 1px solid  $theme-primary;
        }

        .collection-view {
          display: none;
        }

        .collection-grid-view {
          display: none;
        }

        .product-page-per-view,
        .product-page-filter {
          width: 50%;

          &:before {
            right: 15px !important;
          }

          select {
            border-left: 1px solid $theme-primary;
            padding: 21px 34px 21px 34px;
            border-top: none;
            border-bottom: none;
          }
        }

        .product-page-per-view {
          select {
            border-right: none !important;
          }
        }
      }

      .popup-filter {
        .product-page-per-view,
        .product-page-filter {
          border-top: 1px solid $round-border;
        }
      }
    }
  }

  .filter-main-btn {
    display: block;
  }

  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    width:320px;
    background-color:$white;
    z-index: 99;
    overflow-y: scroll;
    padding: 15px 0 15px 15px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px #ddd;

    .collection-sidebar-banner {
      text-align: center;
    }

    .theme-card {
      padding-left: 30px;
      padding-right: 30px;
      border: 0;
    }
    .theme-card {
      .offer-slider {
        .media {
          .media-body {
            a {
              h6 {
                margin-right: 20px;
              }
            }
            .rating {
              i {
                padding-right: 3px;
              }
            }
          }
        }
      }
      &.card-border {
        .offer-slider {
          padding-top: 0;
        }
      }
    }

    .collection-sidebar-banner {
      padding: 0 30px;
    }
  }

  .collection-filter-block {
    border: none;
  }

  .collection-mobile-back {
    display: block;
  }

  .collection {
    .section-t-space {
      padding-top: 30px;
    }

    .partition-collection {
      > div {
        &:nth-last-child(1) {
          margin-top: 30px;
        }

        &:nth-last-child(2) {
          margin-top: 30px;
        }
      }
    }
  }

  .right-login {
    margin-top: 30px;
  }

  .contact-page {
    .map {
      iframe {
        height: 350px;
      }
    }

    .contact-right {
      padding-bottom: 0;

      ul {
        li {
          padding-left: 0;
          border-bottom: 1px solid $round-border;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 15px;
          &:last-child{
            border-bottom: 0;
          }
          .contact-icon {
            position: relative;
            margin: 0 auto;
            border-right: 0;
          }

          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .cart-section {
    .cart-table {
      thead {
        th {
          &:last-child {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 115px;
          &:last-child {
            display: none;
          }
        }
      }
    }
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 15px;
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 0;
        }
      }
    }
  }

  .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 106px;
        }
      }
    }
  }

  .product-right {
    h2 {
      margin-top: 15px;
    }
    .product-buttons{
      justify-content:center;
    }
  }

  .product-right {
    text-align: center;
    margin: 20px 0 10px 0;

    .detail-section,
    .product-icon {
      justify-content: center;
    }

    .product-description {
      .qty-box {
        justify-content: center;
      }
    }

    .size-text {
      text-align: left;
    }

    .timer {
      text-align: left;
    }

    .product-icon {
      .product-social {
        li {
          padding-right: 20px;
        }
      }
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 55%;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
          margin-bottom: 20px;
          background: none $theme-primary;

          .open-popup {
            text-align: left;
          }

          > a {
            color: $white;
          }

          .collection-filter {
            height: 100vh;
            left: -350px;
            transition: all 0.5s ease;
          }
        }

        .search-count {
          width: 100%;
          padding: 10px 0;
          text-align: center;
        }

        .collection-view,
        .collection-grid-view {
          display: none;
        }

        .product-page-per-view,
        .product-page-filter {
          width: 50%;

          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:before {
            top: 13px;
          }
        }

        .product-page-filter {
          &:before {
            left: unset;
            right: 35px;
          }
        }
      }
    }
  }

  .tab-product {
    padding-top: 0;
  }

  .tab-product {
    .nav-material {
      &.nav-tabs {
        justify-content: center;
      }
    }
  }

  .pro_sticky_info {
    border: none;
    padding: 10px 0;
  }

  .product-form-box {
    margin-bottom: 10px;
  }

  .dashboard-left {
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 25px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 $round-border;

    .block-title {
      h2 {
        display: none;
      }
    }

    .block-content {
      border: none;
      padding: 0;
      margin-top: 20px;

      ul {
        li {
          &:hover {
            padding-left: 0;
          }
        }
      }
    }
  }

  .account-sidebar {
    display: block;
    .popup-btn{
      color: $white;
    }
  }

  .typography_section {
    .row {
      > div {
        &:first-child {
          .typography-box {
            &:last-child {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .product-slick,
  .rtl-product-slick,
  .product-right-slick,
  .rtl-product-right-slick {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }

    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }
  }

  .order-up {
    order: -1;
  }

  .bundle_img {
    justify-content: center;
  }

  .bundle_detail {
    .theme_checkbox {
      padding: 0 20%;
    }
  }
}

@media (max-width: 768px) {
  .home-slider {
    &.slick-slider {
      display: block;
    }
  }
  .delivery-sec{
    h2{
      margin-bottom: -5px;
    }
  }
  .footer {
    .footer-content {
      .media {
        i {
          font-size: 16px;
        }
      }
    }
  }
  .pwd-page {
    .theme-form {
      input {
        width: 100%;
      }
    }
  }

  .service-section {
    .image-container {
      .ba-slider {
        .before-lable {
          left: -10px;
        }
        .after-lable {
          right: -10px;
        }
      }
    }
  }


}

@media (max-width: 767px) {
  section {
    padding: 50px 0;
  }
  .section-t-space {
    padding-top: 50px;
  }
  .section-b-space {
    padding-bottom: 50px;
  }
  .about-us-section {
    .main-contain {
      h2 {
        margin-top: 15px;
      }
    }
  }
  .about-us-section, .service-section {
    .btn_dark {
      margin-top: 20px;
    }
  }
  .menu  {
    .menu-content {
      padding: 0;
    }
  }

  // Button

  .btn_lite {
    padding: 8px 10px;
  }
  .animation-section {
    .decor-animation {
      display: none;
    }
  }

  //prodcut-box
  .prodcut_left_margin {
    > div {
      &:nth-last-child(1),&:nth-last-child(4),&:nth-last-child(3) {
        margin-top: 30px;
      }
    }
  }

 

  //blog-pages
  .blog-page {
    .order-sec {
      order:-1;
    }
    .blog-media {
      margin-bottom: 0;
      .blog-right {
        margin-bottom: 30px;
        &.blog-padding {
          margin-bottom: -0px;
        }
      }
    }
  }
  .blog-detail-page {
    .blog-detail {
      p {
        line-height: 1.3;
      }
    }
  }

  // Home-Slider
  .home-slider {
    &.slick-slider {
      &:hover {
        .slick-prev {
          left: 30px;
        }
        .slick-next {
          right: 30px;
        }
      }
    }
    .main_section {
      height: 55vh;
      .main_section_contain {
        height: 55vh;
        h1 {
          line-height: 40px;
          letter-spacing: 0.01em;
          font-size: 30px;
        }
        p {
          padding-top: 70px;
          padding-bottom: 60px;
          line-height: 20px;
        }
        .new {
          margin-bottom: 0;
          margin-left: -15px;
          margin-top: 8px;
        }
      }
    }
  }

  // Call-to-action
  .call-to-action-section {
    .email-container {
      .email-box {
        .email {
          width: 375px;
        }
      }
    }
  }

  // Footer
  .footer {
    .links {
      padding: 0 15px;
    }
    .footer-title {
      h5 {
        margin-bottom: 10px;
      }
    }

    .footer-content {
      .jvector-map-height {
        height: 145px;
        margin-bottom: -13px;
        .jvectormap-container {
          height: 145px;
          svg {
            height: 145px;
          }
        }
      }
      .media {
        i {
          font-size: 16px;
        }

        .media-body {
          max-width: 80%;
        }
      }
    }
  }

  // Feature Section
  .feature-section {
    .bottle-image {
      display: none;
    }

    .box {
      margin-top: 40px;
    }

    .feature-box {
      .last-para {
        margin-bottom: -3px;
      }
      > div {
        &:nth-child(1) {
          margin-top: 0;
        }

        &:nth-child(2) {
          margin-top: 0;
        }
      }

      .features-rtl {
        text-align: unset;
        img {
          position: absolute;
          left: 0;
        }
        .media-body {
          margin-left: 67px;
        }
      }
    }
  }

  //Title Section
  .title_prime {
    text-align: center;
    .icon-image {
      margin-left: unset;
    }
    h2 {
      padding-top: 20px;
      max-width: unset;
    }

    h5 {
      padding-top: 15px;

      &:before {
        top: 25px;
      }
    }
  }
  .title_second {
    margin-bottom: 27px;
    h5 {
      margin-top: 0;
      line-height: 16px;
    }
    h2 {
      padding-top: 10px;
    }
  }

  // Mission section
  .mission-section {
    .mission-image {
      > div {
        margin-top: 30px;
      }
    }
    .box-container {
      .box-item {
        .flip-box {
          .flip-box-back {
            .inner {
              h6,
              p {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    .title_prime {
      .para2 {
        margin-top: -10px;
      }

      .btn_dark {
        margin-top: 20px;
      }
    }
  }

  //Service Section
  .service-section {
    .title_prime {
      .btn {
        margin-bottom: 20px;
        padding-left: 0;
        margin-top: 17px;

        &:before {
          width: 50px;
        }
      }
    }

    .image-container {
      margin: 75px 0 50px 0;

      .back-div {
        height: 120%;
      }
    }
  }

  // About-us
  .about-us-section {
    .main-image {
      width: 350px;
      left: 50%;
      transform: translate(-50%, 0);
      margin-bottom: 5px;
      h4 {
        top: 42px;
        right: -22%;
      }

      .sub-image {
        width: 145px;
      }
    }

    .main-contain {
      text-align: center;

      .para1 {
        &:before {
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //Branded Hair Color
  .hair_color_section {
    .hair_color {
      .main-box {
        .main-color {
          transform: translateY(5px);
        }
      }
    }
  }

  //General Product_box
  .product_box {
    .product_image {
      .timer-box {
        .timer {
          .padding-l {
            padding-left: 10px;
          }

          span {
            width: 45px;

            .timer-cal {
              padding-left: 5px;
            }
          }
        }
      }

      ul {
        > li {
          z-index: 5;

          &:nth-child(2) {
            opacity: 1;
            transform: translateY(0);
          }

          &:nth-child(3) {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.2s;
          }

          &:nth-child(4) {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.4s;
          }
        }
      }

      .product_icon {
        i {
          padding: 5px;
        }
      }
    }
  }

  //product_section
  .product_section {
    ul.tabs {
      li {
        padding: 5px 15px;
      }
    }
  }

  //Advertise_section
  .advertise_section {
    padding: 30px 0;
    margin-top: 28px;

    .bottle {
      img {
        bottom: 15px;
        height: 228px;
      }
    }
    .adv_contain {
      p {
        padding-top: 15px;
      }
      .btn_dark {
        margin-top: 20px;
      }
    }
  }

  // testimonial section
  .testimonial_section {
    .testimonial {
      .space {
        .test_image {
          img {
            width: 100px;
          }

          .chat_icon {
            width: 50px;
          }
        }
      }
    }
    .nav_image {
      margin-bottom: 0;
    }
    .nav_img {
      padding: 10px 13%;
    }

    .slick-slider {
      .slick-prev, .slick-next {
        display: none;
      }
    }
  }

  //cart section
  .cart-section,
  .wishlist-section {
    tbody {
      tr {
        td {
          .icon {
            margin-right: 0 !important;
          }
          a {
            justify-content: center;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .cart-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n + 4) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n + 4) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }

  .product-pagination {
    margin: 20px 0 50px 0;
  }

  //wishlist
  .cart-section,
  .wishlist-section {
    tbody {
      tr {
        td {
          a {
            justify-content: center;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n + 4) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n + 4) {
            display: none;
          }         
        }
      }
    }
  }

  .search-product {
    > div {
      &:nth-child(11) {
        margin-top: 30px;
      }
      &:nth-child(12) {
        margin-top: 30px;
      }
    }
  }

  .wishlist-section {
    .cart-table {
      
      thead {
        th {
          &:nth-last-child(-n + 3) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n + 3) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;           
          }
        }
      }
    }
  }
  .product-order {
    .product-order-detail {
      img {
        height: auto;
      }
    }
  }

  //inner page
  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        justify-content: center;
        border-left: 1px solid $theme-primary;
        .page-item {
          a {
            padding: 15px 18px;
          }
        }
      }
      .product-search-count-bottom {
        justify-content: center;
        padding-right: 0;
        h5 {
          padding: 10px 0;
        }
      }
      .theme-paggination-block {
        nav {
          border-right: 1px solid  $theme-primary;
          border-bottom: 1px solid  $theme-primary;
        }
      }
    }
  }
  .faq-section {
    .theme-accordion {
      .card {
        .card-body {
          p {
            line-height: 1.5 !important;
          }
        }
        .card-header {
          button {
            font-size: 14px !important;
            white-space: normal;
            width: 100%;
            padding: 16px 30px 16px 18px !important;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  //login//
  .auth-page {
    .theme-card {
      .theme-form {
        .form-row{
          display:block;
          >div{
            &:nth-child(n+2){
              margin-left:auto;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 577px) {
  .alert {
    right: 0 !important;
    left: 0;
    max-width: 90% !important;
  }
  .portfolio-section {
    .filter-button {
      font-size: 16px;
      padding: 0 10px;
      line-height:30px;
    }
  }
  .filter-section {
    .filter-container {
      ul{
        &.filter {
          >li {
            padding: 5px 10px;
            >a {
              font-size: 16px;
              line-height: 13px;
            }
          }
        }
      }
    }
  }
  .home-slider {
    .main_section {
      .main_section_contain {
        h1 {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
  }
  .advertise_section  {
    .bottle  {
      img {
        height: 242px;
      }
    }
  }
  .search-block  {
    .form-header {
      padding: 15px 0 0 0;
    }
  }
  .pwd-page {
    .theme-form {
      input {
        width:100%;
      }
    }
    .forget-btn {
      margin-top: 25px;
    }
  }

  .blog-detail-page {
    .blog-detail {
      .post-social {
        display: block;
      }
    }
  }
}

@media (max-width: 576px) {
  section {
    padding: 40px 0;
  }
  .section-t-space {
    padding-top: 40px;
  }
  .footer {
    .portfolio{
        overflow: hidden;
    }
    .row {
      > div {
        &+div{
            margin-top: 10px !important;
        }
        &:first-child {
          .footer-title {
            padding-top: 0;
          }
        }
      }
    }
  }
  .order_detail{
    padding: 0;
  }
  .section-b-space {
    padding-bottom: 40px;
  }
  .small-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .common-card{
    padding: 20px;
  }
  .dashboard .box-account > div + div {
    margin-top: 0px;
  }
  .portfolio-section {
    .isotopeSelector {
      margin-bottom: 15px;
    }
  }
  .checkout-page .checkout-form .form-group .field-label {
    margin-bottom: 5px;
  }
  .dashboard .box .box-title > a{
    position: relative;
    padding-bottom: 10px;
    display: block;
  }
  .product_contain {
    .card-title {
      margin-bottom: 5px;
    }
  }
  .prodcut_left_margin {
    > div {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .home-slider {
    .main_section {
      .main_section_contain {
        .new {
          margin-top: 5px;
        }
      }
    }
  }
  .service-section {
    .image-container {
      margin: 70px 0 40px 0;
    }
  }

  //Top Header
  .top_header {
    display: none;
  }

  // Menu
  .menu {
    top: 0;
    padding: 5px;
    .menu-content {
      .main_menu_right {
        z-index: 9;
        > button {
          a {
            color: $theme-primary;
          }
          + button {
            margin-left: unset;
          }
        }
        position: fixed;
        bottom: 0;
        background: linear-gradient(270deg, $brown7, $brown8, $brown7);
        height: unset !important;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        .setting {
          display: block;
        }

        button {
          width: 25%;
          color: $theme-primary;
          border: unset;
          background: unset;
          padding: 10px;
          span {
            font-size: 20px;
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
      .toggle-nav {
        margin-right: 0;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .brand-logo {
        img {
          width: 70%;
        }
      }
    }
  }
  .main_section {
    height: 45vh;
    .main_section_contain {
      height: 45vh;
      h1 {
        line-height: 35px;
      }
      .new {
        margin-bottom: -1px;
        margin-left: -20px;
        margin-top: 19px;
      }
    }
  }

  //Title Section
  .title_prime {
    .icon-image {
      width: 45px;
    }
    h2 {
      padding-top: 15px;
    }
    p {
      margin-top: -16px;
    }

    h5 {
      padding-top: 10px;

      &:before {
        top: 20px;
      }
    }
  }

  .title_second {
    margin-bottom: 27px;
    h5 {
      margin-top: -2px;
    }
  }

  // Service Section
  .service-section {
    .image-container {
      .ba-slider {
        .after-lable {
          display: none;
        }
      }
      .resize {
        .before-lable {
          display: none;
        }
      }
    }
  }
  // Feature
  .feature-section {
    .box {
      margin-top: 35px;
    }
    .title {
      h5 {
        margin-top: -1px;
      }
    }
    .feature-box {
      .last-para {
        margin-bottom: -2px;
      }
    }
  }


  // Footer
  .footer {
    .links {
      padding-left: 15px;
      padding-right: 15px;
    }

    .footer-title {
      display: block;
    }

    .footer-content {
      padding: 10px 0;
    }

    .footer-title {
      padding: 10px 0;
      text-align: left;
      border-bottom: 1px solid rgba($white, 0.2);
      position: relative;

      h5 {
        margin-bottom: 0;
        cursor: pointer;
      }

      &.active {
        .according-menu {
          font: normal normal normal 15px/1 FontAwesome;

          &:before {
            content: "\f106";
            position: absolute;
            right: 2px;
            bottom: 10px;
          }
        }
      }

      .according-menu {
        font: normal normal normal 15px/1 FontAwesome;

        &:before {
          content: "\f107";
          position: absolute;
          right: 2px;
          bottom: 10px;
        }
      }
    }

    .footer-title {
      .according-menu {
        &:before {
          bottom: 18px;
        }
      }
    }
  }

  // Call-to-action
  .call-to-action-section {
    .contact {
      .media {
        .media-body {
          padding-right: 10px;
        }
      }
      .newsletter {
        padding-left: 10px;
      }
    }

    .email-container {
      .email-box {
        .email {
          width: 350px;
        }
      }
    }
  }

  //  About-us
  .about-us-section {
    .main-image {
      h4 {
        position: relative;
        top: 0;
        right: 0;
        transform: rotate(0);
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0;
      }
    }
    .main-contain {
      h2 {
        line-height: 30px;
        margin-top: 0;
      }
    }
  }

  //product_section
  .product_section {
    .tab-content {
      .product-row {
        > div {
          &:nth-child(n) {
            margin-top: 30px;
          }
        }
      }
    }
    ul {
      &.tabs {
        margin-top: 5px;
      }
    }
  }

  //Advertise_section
  .advertise_section {
    margin-top: 0;
    .bottle {
      display: none;
      img  {
        height:242px;
      }
    }
    .adv_contain {
      text-align: center;
      p {
        padding-top: 20px;
      }
    }
  }

  //Brand_logo Section
  .brand_section {
    .logo {
      padding: 30px 0;

      img {
        width: 75%;
      }
    }

    .brand {
      > div {
        + div {
          &:nth-child(n) {
            border-top: 2px solid;
            border-image-slice: 1;
            border-image: radial-gradient($gradient, $white);
            background-repeat: no-repeat;
          }

          &:nth-child(2),
          &:nth-child(3) {
            border-bottom: unset;
            border-top: 2px solid;
            border-image: radial-gradient($gradient, $white);
            border-image-slice: 1;
          }

          &:nth-child(5) {
            border-right: unset;
            border-left: unset;
          }

          &:nth-child(4) {
            border-top: 2px solid;
            border-image: radial-gradient($gradient, $white);
            border-image-slice: 1;
            border-left: unset;
            border-bottom: 2px solid;
          }

          &:nth-child(6) {
            border-bottom: unset;
            border-image: radial-gradient($gradient, $white);
            border-image-slice: 1;
            border-left: unset;
          }
        }
        &:first-child {
          .logo {
            padding-top: 0;
          }
        }
        &:last-child {
          .logo {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  // testimonial section
  .testimonial_section {
    .testimonial {
      .space {
        .test_image {
          img {
            width: 90px;
          }

          .chat_icon {
            width: 45px;
          }
        }
      }
    }
    .nav_image {
      margin-bottom: 0;
      padding-top: 0;
    }

    .slick-slider {
      .slick-prev {
        display: none !important;
      }

      .slick-next {
        display: none !important;
      }
    }
    .slick-slider {
      .slick-prev:before {
        font-size: 25px;
      }

      .slick-next:before {
        color: $theme-primary;
        font-size: 25px;
      }
    }
  }

  // inner pages //
  .checkout-page {
    .checkout-form {
      .form-group {
        margin-bottom: 20px;
      }
      input {
        &[type="text"],
        &[type="email"],
        &[type="password"],
        &[type="tel"],
        &[type="number"],
        &[type="url"] {
          height: 38px;
        }
      }
    }
  }
  .template-password {
    #container {
      #login {
        margin-bottom: 0;
      }
    }
  }

  .login-page {
    .theme-card {
      .theme-form {
        input {
          padding: 10px 16px;
        }
      }
    }
  }

  .error-section {
    padding: 100px 0;

    h1 {
      font-size: 100px;
      line-height: 1.4;
    }

    h2 {
      margin: 20px 0;
      font-size: 18px;
    }

    .btn_dark {
      padding: 10px 25px;
      font-size: 14px;
    }
  }

  .about-page {
    .about-head {
      display: inline-block;
      width: 100%;
      text-align: center;

      .theme-breadcrumb {
        .breadcrumb {
          justify-content: center;
          padding-top: 10px;
          padding-bottom: 0;
        }
      }
    }
  }

  //Search
  .search-block {
    .form-header {
      padding-top: 10px;
    }
  }

  .blog-detail-page {
    .blog-detail {
      .post-social {
        li {
          display: block;

          & + li {
            padding-left: 0;
            margin-left: 0;
            border-left: none;
            display: block;
          }
        }
      }
    }
  }

  .collection {
    .collection-block {
      .collection-content {
        p {
          margin-bottom: 10px;
        }

        h4,
        h3 {
          margin-bottom: 5px;
        }
      }
    }
  }

  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-box {
        margin-top: 30px;
      }
    }

    .product-top-filter {
      .product-filter-content {
        .search-count {
          padding: 10px 20px 10px 20px;

          h5 {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .product-page-per-view,
        .product-page-filter {
          width: 100%;

          select {
            padding: 10px 20px 10px 20px;
          }

          &:before {
            top: 11px;
          }
        }

        .product-page-per-view {
          border-right: 1px solid $theme-primary !important;
          border-bottom: 1px solid $theme-primary;
        }
      }

      .popup-filter {
        .search-count {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .product-page-per-view {
          border-bottom: 1px solid $theme-primary;
          width: 100%;

          select {
            border-right: none;
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:before {
            top: 14px;
          }
        }

        .product-page-filter {
          width: 100%;
          border-top: none;

          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:before {
            left: unset;
            right: 35px !important;
            top: 14px;
          }
        }
      }
    }
  }

  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 15px;
        }
      }

      .btn_dark {
        padding: 7px 8px;
      }
    }
  }

  .wishlist-section {
    .btn_dark {
      padding: 7px 6px;
    }
  }

  .collection-wrapper {
    .order-up {
      order: -1;
    }
  }

  .checkout-page {
    .checkout-form {
      .btn_dark {
        padding: 10px 25px;
        font-size: 14px;
      }
    }
  }

  .search-product {
    > div {
      &:nth-child(10) {
        margin-top: 30px;
      }
    }
  }

  .product-box {
    .img-wrapper {
      .lable-block {
        .lable3 {
          font-size: 12px;
          padding: 13px 8px;
        }
      }
    }

    .img-block {
      .lable-wrapper {
        .lable1,
        .lable2 {
          padding: 6px 9px 8px 15px;
          font-size: 12px;
        }
      }
    }
  }

  .tab-product,
  .product-full-tab {
    .tab-content {
      &.nav-material {
        p {
          padding: 15px 0;
        }
      }
    }
  }

  .tab-product {
    .tab-content {
      &.nav-material {
        p {
          line-height: 1.3;
        }
      }
    }
  }

  .slider-right-nav {
    .slick-slide {
      &:first-child {
        > div {
          margin-top: 15px;
        }
      }
    }
  }

  .lookbook {
    .lookbook-block {
      .lookbook-dot {
        .dot-showbox {
          width: 90px;
          top: -70px;

          .dot-info {
            h5 {
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .layout7-product {
    .product-box {
      .details-product {
        padding: 5px;
      }

      &:hover {
        .details-product {
          animation: none;
        }
      }
    }
  }

  .padding-cls {
    padding-left: 15px;
    padding-right: 15px;
  }

  .success-text {
    i {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    h2 {
      margin-bottom: 10px;
    }
  }
  .order-success-sec {
    > div {
      margin-top: 15px;
    }
  }
  .delivery-sec {
    padding: 15px;
    margin-top: 15px;

    h3,
    h2 {
      font-size: 20px;
    }
  }

  .product-order {
    h3 {
      font-size: 20px;
    }

    .product-order-detail {
      .order_detail {
        h4,
        h5 {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }

    .total-sec {
      ul {
        li {
          font-size: 16px;
        }
      }
    }

    .final-total {
      h3 {
        font-size: 16px;
      }
    }
  }

  .beauty-about {
    .service {
      .service-block1 {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .main-menu {
    .menu-left {
      .navbar {
        i {
          font-size: 24px;
        }
      }
    }
  }

  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 0;
            }
          }
          .btn_dark {
            &.btn-sm {
              padding: 3px 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
// working-hourse-main //
.working-hourse-main  {
  .working-hourse-icon {
    >div {
      .logo {
        padding-bottom: 20px; 
        
      }
      &:nth-child(n+4) {
        .logo {
          padding-top: 0; 
        }
      }
      &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6) {
      border:0 ;
    }
   }
  }
  .working-hourse-block {
    >div {
      &:nth-child(2) {
        padding-bottom: 0;
      }
    }
  }
  .working-hourse-contain  {
    ul  {
      li {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
}

@media (max-width: 480px) {
  section {
    padding: 30px 0;
  }
  .section-t-space {
    padding-top: 30px;
  }
  .section-b-space {
    padding-bottom: 30px;
  }
  .copyright{
    padding: 10px;
  }
  .checkout-form{
    margin-bottom: 20px;
  }
  //forget-password
  .pwd-page {
    .forget-btn {
      margin-top: 15px;
    }
  }
  // Home-Slider
  .home-slider {
    .main_section {
      height: 40vh;
      .main_section_contain {
        height: 40vh;
        .new {
          margin-bottom: -5px;
          margin-left: -20px;
          margin-top: 2px;
        }
        p {
          line-height: 20px;
        }
      }
    }
  }

  // Call-to-action
  .call-to-action-section {
    .contact {
      display: unset;
      justify-content: unset;
      align-items: unset;
      float: unset;
      text-align: center;

      .media {
        display: block;
        .media-body {
          padding-right: unset;
          padding-bottom: 10px;
        }

        svg {
          margin-right: unset;
        }
      }

      .newsletter {
        padding-left: unset;
        border-left: unset;
        border-top: 2px solid $brown13;
        padding-top: 10px;
      }

      .media-body {
        padding-right: unset;
      }
    }
  }

  // Feature Section
  .feature-section {
    .box {
      margin-top: 30px;
    }
  }
  //Title Section
  .title_prime {
    .icon-image {
      width: 40px;
    }
    p {
      margin-top: -10px;
      padding-top: 20px;
    }
  }
  .title_second {
    margin-bottom: 17px;
  }

  // Mission section
  .mission-section {
    .title_prime {
      .btn_dark {
        margin-top: 20px;
      }
    }
  }

  // Service Section
  .service-section {
    .image-container {
      margin: 50px 0 30px 0;
    }
    .title_prime {
      .btn {
        padding-left: 0;
        margin-top: 12px;
        margin-bottom: 15px;

        &:before {
          width: 40px;
        }
      }
    }
  }

  // About-us
  .about-us-section {
    .main-image {
      .sub-image {
        display: none;
      }
    }
  }

  //General Product_box
  .product_box {
    .product_image {
      .product_icon {
        i {
          padding: 10px;
        }
      }
    }
  }

  //testimonial section
  .testimonial_section {
    .testimonial {
      .comment_box {
        .rating {
          display: unset;
          align-items: center;
          justify-content: center;

          h6 {
            padding-left: 0 !important;
            padding-top: 5px;
          }
          p {
            padding-top: 5px;
          }

          .reg {
            padding-left: 0 !important;
          }
        }
      }

      .slick-slider {
        .slick-prev:before {
          font-size: 25px;
        }

        .slick-next:before {
          color: $theme-primary;
          font-size: 25px;
        }
      }
    }
  }

  // Theme Breadcrumb
  .theme-breadcrumb {
    h2 {
      margin-top: 3px;
    }
  }

  // inner pages //
  .review-page {
    .comnt-sec {
      justify-content: center;
    }
  }

  .select_input {
    select {
      padding: 12px 15px;
      margin-bottom: 18px;
    }
  }

  .about-page {
    h4 {
      margin-top: -4px;
    }
    p {
      line-height: 1.5;
      margin-bottom: -3px;
    }
  }

  .testimonial {
    .testimonial-slider {
      .media {
        display: inline-block;

        img {
          margin: 0 auto;
          height: 100px;
          width: 100px;
        }

        .media-body {
          padding: 0;
          text-align: center;
        }
      }
    }
  }

  .blog-detail-page {
    .comment-section {
      li {
        img {
          margin-right: unset;
        }
      }
    }
    .blog-contact {
      h2 {
        font-size: 30px;
        margin-bottom: 25px;
      }

      .theme-form {
        input,
        textarea {
          padding: 12px 20px;
          margin-bottom: 20px;
        }
      }
    }

    .blog-detail {
      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      img {
        margin-bottom: 25px;
      }
    }

    .blog-advance {
      ul {
        line-height: 1.5;
      }

      img {
        margin-bottom: 20px;
        margin-top: 10px;
      }

      p {
        line-height: 1.5;
      }
    }

    .comment-section {
      li {
        padding-top: 30px;
        padding-bottom: 30px;

        p {
          line-height: 1.5;
        }

        h6 {
          margin-top: 10px;
          margin-bottom: 10px;

          span {
            display: inherit;
            margin-left: 0;
          }
        }
      }

      .media {
        display: inline-block;
        text-align: center;
      }
    }
  }

  .faq-section {
    .theme-accordion {
      .card {
        .card-body {
          p {
            line-height: 1.5 !important;
          }
        }
        .card-header {
          button {
            padding: 12px 35px 12px 12px !important;
          }
        }
      }
    }

    .accordion {
      &.theme-accordion {
        .card {
          .card-header {
            button {
              &:before {
                top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .pwd-page {
    padding: 70px 0;
    h2 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  .login-page {
    .authentication-right {
      h6 {
        margin-bottom: 10px;
      }
    }
    .theme-card {
      padding: 25px;
    }
  }
  .auth-page {
    .theme-card {
      padding: 18px;
      .theme-form {
        input {
          margin-bottom: 20px;
          padding: 12px 15px;
        }
      }
    }
  }
  .contact-page {
    .map {
      iframe {
        height: 275px;
      }
    }

    .theme-form {
      input {
        padding: 12px 15px;
        margin-bottom: 18px;
      }
    }
  }

  .blog-page {
    .blog-media {

      .blog-right {
        h6 {
          margin-bottom: 0;
        }

        p {
          line-height: 1.3;
        }

        h4 {
          line-height: 1.2;
        }

        ul {
          margin-bottom: 5px;

          li {
            display: block;

            & + li {
              padding-left: 0;
              margin-left: 0;
              border-left: none;
            }
          }
        }
      }
    }
  }

  .account-sidebar {
    width: 45%;
  }

  .success-text {
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .delivery-sec {
    h3,
    h2 {
      font-size: 18px;
    }
  }

  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            margin: 5px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .home-slider {
    .main_section {
      .main_section_contain {
        .new {
          margin-top: 0;
        }
      }
    }
  }
  //product
  .product-right {
    .product-buttons {
      .btn-solid,
      .btn-outline {
        padding: 7px 13px;
      }
    }
    &.product-form-box {
      .timer {
        padding-left: 29px;
        span {
          width: 45px;
        }
      }
    }
    .timer {
      padding-left: 35px;
      span {
        width: 50px;
      }
    }
    .product-icon {
      .product-social {
        margin-top: 0;
        li {
          padding-right: 5px;
          a {
            i {
              font-size: 14px;
            }
          }
        }
      }
      .wishlist-btn {
        i {
          font-size: 14px;
          padding-left: 10px;
          margin-left: 5px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
  .tab-product {
    .nav-material {
      &.nav-tabs {
        display: block;
        .nav-item {
          width: 100%;
          .nav-link {
            padding: 10px 20px;
          }
        }
      }
    }
  }

  //checkout
  .order-box {
    .sub-total {
      .shipping {
        width: unset;
        float: unset;
        display: flex;
        .shopping-option {
          &:last-child {
            padding-left: 20px;
          }
        }
      }
    }
  }

  .top-banner-wrapper {
    .top-banner-content {
      p {
        line-height: 1.4;
      }
    }
  }
}

@media (max-width: 360px) {
  .quick-view-modal {
    .product-right {
      .product-buttons {
        a {
          &:last-child {
            margin-left: 0;
          }
        }
        .btn_dark {
          padding: 8px;
          font-size: 12px;
        }
      }
    }
  }
  .wishlist-buttons{
    .btn{
      width: 100%;
      &+.btn{
        margin-top: 10px;
      }
    }
  }
  .search-block {
    .form-header {
      .input-group {
        .btn {
          padding: 10px 5px;
        }
        input {
          padding: 10px 12px;
        }
      }
    }
  }
  // Home-Slider
  .home-slider {
    .main_section {
      height: 35vh;
      .main_section_contain {
        height: 35vh;
        .new {
          margin-top: 0;
          margin-bottom: -6px;
          margin-left: 0px;
          font-size: 18px;
        }
        h1 {
          line-height: 20px;
          font-size: 16px;
        }
        p {
          padding-bottom: 55px;
          padding-top: 75px;
          line-height: 17px;
        }
        .btn_dark {
          padding:8px 7px;
        }
      }
    }
  }

  // 14. About-us
  .about-us-section {
    .main-image {
      width: auto;
    }
    .main-contain {
      h2 {
        line-height: 25px;
      }

      .para1 {
        padding-top: 20px;
      }

      .btn_lite {
        margin-top: unset;
      }
    }
  }

  // General Product-Box
  .product_box {
    .product_image {
      .product_icon {
        i {
          padding: 5px;
        }
      }
    }
  }

  // Call-to-action
  .call-to-action-section {
    .email-container {
      .email-box {
        .email {
          width: 300px;
        }
      }
    }
  }

  // product_section
  .product_section {
    ul.tabs {
      li {
        padding: 5px 12px;
      }
    }
  }

  // Title Section
  .title_prime {
    .icon-image {
      width: 30px;
    }
    p {
      padding-top: 15px;
    }
    h2 {
      padding-top: 10px;
    }
  }
  .title_second {
    h2 {
      padding-top: 5px;
    }
  }
  // Testimonial
  .testimonial_section {
    .nav_image {
      padding-top: 0;
    }
    .slick-slider {
      .slick-prev:before {
        font-size: 20px;
      }
      .slick-next:before {
        color: $theme-primary;
        font-size: 20px;
      }
    }
  }

  // Feature
  .feature-section {
    .title {
      h5 {
        margin-top: -3px;
      }
    }
  }

  //inner page css
  .product-right {
    .product-buttons {
      .btn_dark {
        padding: 8px 10px;
        font-size: 14px;       
      }
    }
  }
  //Search
  .search-block {
    .form-header {
      .form-control {
        font-size: 14px;
      }
    }
  }
  //cart section
  .cart-section,
  .wishlist-section {
    .wishlist-buttons {
      &:last-child {
        a {
          margin-left: 0;
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 110px;
          .mobile-cart-content {
            .col-xs-3 {
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          a {
            img {
              height: 80px;
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 27px;
          h2 {
            font-size: 21px;
          }
        }
      }
    }
  }
  //blog section
  .blog-detail-page {
    .blog-contact {
      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  //inner page
  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 10px;
          }
        }
      }
      .product-search-count-bottom {
        padding-left: 10px;
        h5 {
          line-height: 15px;
        }
      }
    }
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 80%;
        }
      }
    }
  }

  // Tap-To-Top
  .tap-top {
    width: 30px;
    height: 30px;
    font-size: 15px;
    padding: 3px;
  }
}

@media (max-width: 320px) {
  section {
    padding: 30px 0;
  }

  //Title Section
  .title_prime {
    h2 {
      line-height: 30px;
      letter-spacing: 0.01em;
    }
    p {
      margin-top: -16px;
    }
  }
  .title_second {
    margin-bottom: 15px;
  }

  // Home-Slider
  .home-slider {
    .main_section {
      .main_section_contain {
        .new {
          margin-bottom: -6px;
          margin-left: -25px;
        }
      }
    }
  }
  // Service
  .service-section {
    .title_prime {
      .btn {
        margin-top: 5px;
      }
    }
  }

  //testimonial section
  .testimonial_section {
    .slick-slider {
      .slick-prev:before {
        font-size: 15px;
      }

      .slick-next:before {
        color: $theme-primary;
        font-size: 15px;
      }
    }
  }
  .product-right {
    .product-buttons {
      display: flex;
    }
  }
  // Call-to-action
  .call-to-action-section {
    .email-container {
      .email-box {
        .email {
          width: 275px;
        }
      }
    }
  }
}
