$font_0: "Prata", serif;
$font_1: "Work Sans", sans-serif;
$font_2: 'Dancing Script', cursive;
$font_3:  'Poppins', sans-serif;
$white: #ffffff;
$grey: #e5e5e5;
$black: #000000;
$black1: rgba(0, 0, 0, 0.27);
$grey-para: #33322d;
$brown2: #d7cab9;
$brown3: #d9cab5;
$brown4: #e2d8c9;
$brown5: rgba(106, 64, 49, 0.48);
$brown6: #eee4d8;
$brown7: #978262;
$brown8: #cbbaa6;
$brown10: #a68c7d;
$brown11: #d8c7ad;
$brown12: #e3d8ca;
$brown13: #a58e77;
$theme-primary: #6a4031;
$theme-primary-light: #c7ac85;
$theme-primary-light2: #927364;
$hoverColor: rgba(240, 163, 76, 0.4);
$gradient: rgb(202, 183, 154);
$round-border: #dddddd;
$grey-about: #f7f7f7;
$font-grey: #aaaaaa;
$font-dark-gray: #777777;
$gradient1: #01effc;
$gradient2: #485ff2;
$cirkal-color7:#d35400;
$cirkal-color2:#EAB543;
$cirkal-color4:#FD7272;
$cirkal-color5:#82589F;


$dark-font: #222222;
$top-header: #f8f8f8;
$grey2: #555555;
$border: #dddada;
$grey4: #444444;
$grey5: #888888;
$border-grey: #f1f5f4;
$font-color: #333333;
$grey-lighter: #f9f9f9;
$grey6: #6f6f6f;
$white-dark: #eeeeee;
$header-font: #999999;
$gray: #bfbfbf;
$pink: #f1e7e6;
$blue: #d0edff;

//font
$font-awesome: FontAwesome;
