/*=====================
    24. Menu css start
==========================*/

@mixin round_corner($amount, $chainable: 'ul > li:last-child > ', $level: 4, $chain_prefix: '> li:last-child > ', $chain: '', $selector: '') {
  $chain: $chain_prefix;
  $selector: $chain + 'a, ' + $chain + '*:not(ul) a, ' + $chain + 'ul';
  @for $i from 1 through $level {
    $chain: $chain + $chainable;
    $selector: $selector + ',
' + $chain + ' a, ' + $chain + '*:not(ul) a, ' + $chain + ' ul';
  }
  #{$selector} {
    border-radius: 0 0 $amount $amount;
  }
  $chain: $chain_prefix;
  $selector: $chain + 'a.highlighted, ' + $chain + '*:not(ul) a.highlighted';
  @for $i from 1 through $level {
    $chain: $chain + $chainable;
    $selector: $selector + ',
' + $chain + ' a.highlighted, ' + $chain + '*:not(ul) a.highlighted';
  }
  #{$selector} {
    border-radius: 0;
  }
}
@mixin sub_items($amount, $chainable: 'ul ', $level: 4, $chain: '') {
  @for $i from 1 through $level {
    $chain: $chain + $chainable;
    #{$chain} a,
    #{$chain} a:hover,
    #{$chain} a:focus,
    #{$chain} a:active {
      border-left: ($amount * ($i + 1)) solid transparent;
    }
  }
}
%extend_1 {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}
.sm {
  box-sizing: border-box;
  position: relative;
  z-index: 9999;
  @extend %extend_1;
  ul {
    @extend %extend_1;
    display: none;
  }
  li {
    @extend %extend_1;
    position: relative;
  }
  > li {
    > h1 {
      margin: 0;
      padding: 0;
    }
    > h2 {
      margin: 0;
      padding: 0;
    }
    > h3 {
      margin: 0;
      padding: 0;
    }
    > h4 {
      margin: 0;
      padding: 0;
    }
    > h5 {
      margin: 0;
      padding: 0;
    }
    > h6 {
      margin: 0;
      padding: 0;
    }
  }
  a {
    position: relative;
    display: block;
    &.disabled {
      cursor: default;
    }
  }
  * {
    box-sizing: inherit;
  }
  *::before {
    box-sizing: inherit;
  }
  *::after {
    box-sizing: inherit;
  }
}
.sm::after {
  content: "";
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  overflow: hidden;
}

// -----------------------------------------------------------------------------------------------------------------
// 1. Theme Quick Settings (Variables)
// -----------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------
// :: 1.1. Colors
// ----------------------------------------------------------

$theme-default: #ff4c3b !default;
$theme-default-dark:									darken($theme-default, 5%) !default;
$theme-default-darker: #c33a32 !default;
$theme-default-light:									lighten($theme-default, 30%) !default;
$theme-deafult__white:										#fff !default;
$theme-deafult__gray:											darken($theme-deafult__white, 34%) !default;
$dark :	#333333;
$theme-deafult__text-shadow:									rgba(0, 0, 0, 0.2) !default;
$theme-deafult__box-shadow:									rgba(0, 0, 0, 0.2) !default;

// ----------------------------------------------------------
// :: 1.2. Breakpoints
// ----------------------------------------------------------
$theme-deafult__desktop-vp:									1200px !default;		// switch from collapsible to desktop

// ----------------------------------------------------------
// :: 1.3. Typography
// ----------------------------------------------------------
$theme-deafult__font-size-base:								18px !default;
$theme-deafult__font-size-small:								16px !default;
$theme-deafult__line-height:									23px !default;

// ----------------------------------------------------------
// :: 1.4. Borders
// ----------------------------------------------------------
$theme-deafult__border-width:									1px !default;
$theme-deafult__border-radius-base:							8px !default;
$theme-deafult__border-radius-small:							4px !default;

// ----------------------------------------------------------
// :: 1.5. Collapsible main menu
// ----------------------------------------------------------
// Menu box
$theme-deafult__collapsible-bg:								transparent !default;
$theme-deafult__collapsible-border-radius:					$theme-deafult__border-radius-base !default;
$theme-deafult__collapsible-box-shadow:						0 1px 4px $theme-deafult__box-shadow !default;

// Items
$theme-deafult__collapsible-item-color:						$theme-deafult__white !default;
$theme-deafult__collapsible-item-bg:							$theme-default !default;
$theme-deafult__collapsible-item-current-color:				$theme-deafult__white !default;
$theme-deafult__collapsible-item-current-bg:					$theme-default-darker !default;
$theme-deafult__collapsible-item-disabled-color:				lighten($theme-default, 30%) !default;
$theme-deafult__collapsible-item-padding-vertical:			10px !default;
$theme-deafult__collapsible-item-padding-horizontal:			20px !default;

// Toggle button (sub menu indicators)
$theme-deafult__collapsible-toggle-bg:						rgba(0, 0, 0, 0.1) !default;

// ----------------------------------------------------------
// :: 1.6. Collapsible sub menus
// ----------------------------------------------------------

// Menu box
$theme-deafult__collapsible-sub-bg:							$theme-deafult__white !default;

// Items
$theme-deafult__collapsible-sub-item-color:					$theme-default-dark !default;
$theme-deafult__collapsible-sub-item-bg:						transparent !default;
$theme-deafult__collapsible-sub-item-current-color:			$theme-deafult__white !default;
$theme-deafult__collapsible-sub-item-current-bg:				$theme-default-darker !default;
$theme-deafult__collapsible-sub-item-disabled-color:			darken($theme-deafult__white, 30%) !default;

// Items separators
$theme-deafult__collapsible-sub-separators-color:				rgba(0, 0, 0, 0.05) !default;

// Items text indentation for deeper levels
$theme-deafult__collapsible-sub-item-indentation:				8px !default;

// ----------------------------------------------------------
// :: 1.7. Desktop main menu
// ----------------------------------------------------------

// Items separators
$theme-deafult__desktop-separators-size:						1px !default;
$theme-deafult__desktop-separators-color:						darken($theme-default, 5%) !default;

// Sub menu indicators
$theme-deafult__desktop-arrow-size:							5px !default;		// border-width
$theme-deafult__desktop-arrow-color:							$theme-default-light !default;

// Vertical menu box
$theme-deafult__desktop-vertical-box-shadow:					0 1px 4px $theme-deafult__box-shadow !default;

// Vertical items
$theme-deafult__desktop-vertical-item-padding-vertical:		9px !default;
$theme-deafult__desktop-vertical-item-padding-horizontal:		23px !default;

// ----------------------------------------------------------
// :: 1.8. Desktop sub menus
// ----------------------------------------------------------

// Menu box
$theme-deafult__desktop-sub-bg:								$theme-deafult__white !default;
$theme-deafult__desktop-sub-border-color:						$theme-deafult__gray !default;
$theme-deafult__desktop-sub-border-radius:					$theme-deafult__border-radius-small !default;
$theme-deafult__desktop-sub-box-shadow:						0 5px 12px $theme-deafult__box-shadow !default;
$theme-deafult__desktop-sub-padding-vertical:					7px !default;
$theme-deafult__desktop-sub-padding-horizontal:				0 !default;

// Items
$theme-deafult__desktop-sub-item-color:						$theme-default-dark !default;
$theme-deafult__desktop-sub-item-bg:							transparent !default;
$theme-deafult__desktop-sub-item-hover-color:					$theme-deafult__white !default;
$theme-deafult__desktop-sub-item-hover-bg:					$theme-default !default;
$theme-deafult__desktop-sub-item-current-color:				$theme-deafult__white !default;
$theme-deafult__desktop-sub-item-current-bg:					$theme-default-darker !default;
$theme-deafult__desktop-sub-item-disabled-color:				darken($theme-deafult__white, 30%) !default;
$theme-deafult__desktop-sub-item-padding-vertical:			9px !default;
$theme-deafult__desktop-sub-item-padding-horizontal:			23px !default;

// ----------------------------------------------------------
// :: 2.1. Collapsible mode (mobile first)
// ----------------------------------------------------------

// calc item height and sub menus toggle button size
$theme-deafult__item-height: $theme-deafult__line-height + $theme-deafult__collapsible-item-padding-vertical * 2;
// set toggle button size to 80% of item height
$theme-deafult__toggle-size: floor($theme-deafult__item-height * 0.8);
$theme-deafult__toggle-spacing: floor($theme-deafult__item-height * 0.1);

// Main menu box
.pixelstrap {
  a {
    font-size: 16px;
    &,
    &:hover,
    &:focus,
    &:active {
      padding-right: 45px;
      color: $theme-primary;
      text-decoration: none;
    }
    &.disabled {
      color: $theme-deafult__collapsible-item-disabled-color;
    }
    .sub-arrow {
      position: absolute;
      top: 50%;
      margin-top: -17px;
      left: auto;
      right: 15px;
      width: 34px;
      height: 34px;
      overflow: hidden;
      font: bold 16px/34px monospace !important;
      text-align: center;
      text-shadow: none;
      border-radius: 4px;
    }
    .sub-arrow::before {
      content: '+';
    }
    &.highlighted .sub-arrow::before {
      content: '-';
    }
  }
  @include round_corner($theme-deafult__collapsible-border-radius);
  // Sub menus box
  ul {
    background: $theme-deafult__collapsible-sub-bg;
    ul {
      background: rgba(darken($theme-deafult__collapsible-sub-bg, 60%), 0.1);
    }
    a {
      &,
      &:hover,
      &:focus,
      &:active {
        background: $theme-deafult__collapsible-sub-item-bg;
        color: $theme-primary;
        font-size: $theme-deafult__font-size-small;
        text-shadow: none;
        // add indentation for sub menus text
        border-left: $theme-deafult__collapsible-sub-item-indentation solid transparent;
      }
      &.current {
        background: $theme-deafult__collapsible-sub-item-current-bg;
        color: $theme-deafult__collapsible-sub-item-current-color;
      }
      &.disabled {
        color: $theme-deafult__collapsible-sub-item-disabled-color;
      }
    }
    // Add indentation for sub menus text for deeper levels
    @include sub_items($theme-deafult__collapsible-sub-item-indentation);

    // Sub menus items separators
    li {
      border-top: none;
      &:first-child {
        border-top: 0;
      }
    }
  }
}

// ----------------------------------------------------------
// :: 2.2. Desktop mode
// ----------------------------------------------------------

@media (min-width: $theme-deafult__desktop-vp) {

  /* Switch to desktop layout  */
  .pixelstrap ul{position:absolute;}
  .pixelstrap li{float:left;}
  .pixelstrap.sm-rtl li{float:right;}
  .pixelstrap ul li,.pixelstrap.sm-rtl ul li,.pixelstrap.sm-vertical li{float:none;}
  .pixelstrap a{white-space:nowrap;}
  .pixelstrap ul a,.pixelstrap.sm-vertical a{white-space:normal;}
  .pixelstrap .sm-nowrap > li > a,.pixelstrap .sm-nowrap > li > :not(ul) a{
    white-space:nowrap;
    font-size: 14px;
    text-transform: capitalize;
  }
  /* ...end */

  // Main menu box
  .pixelstrap {
    > li {
      > a {
        font-weight: 600;
      }
    }
    a {
      .sub-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px;
        left: auto;
        right: 20px;
        width: 34px;
        height: 34px;
        overflow: hidden;
        font: bold 16px/34px monospace !important;
        text-align: center;
        text-shadow: none;
        border-radius: 4px;
        &:after {
          content: "\f107";
          font-family: FontAwesome;
          position: absolute;
        }
      }
      .sub-arrow::before {
        display: none;
      }
    }
    ul {
      border: 1px solid rgba($brown7, 0.3);
      padding: $theme-deafult__desktop-sub-padding-vertical $theme-deafult__desktop-sub-padding-horizontal;
      background: $theme-deafult__desktop-sub-bg;
      border-radius: 0 !important;
      min-width: 12em !important;
      ul {
        border-radius: $theme-deafult__desktop-sub-border-radius !important;
        background: $theme-deafult__desktop-sub-bg;
      }
      a {
        &,
        &:hover,
        &:focus,
        &:active,
        &.highlighted {
          border: 0 !important;
          padding: $theme-deafult__desktop-sub-item-padding-vertical $theme-deafult__desktop-sub-item-padding-horizontal;
          color:$theme-primary;
          border-radius: 0 !important;
        }
        &:hover,
        &:focus,
        &:active,
        &.highlighted {
          background-color: transparent;
          margin-left: 8px;
          &:after {
            content: ">";
            position: absolute;
            left: 10px;
          }
        }
        &.current {
          background: $theme-deafult__desktop-sub-item-current-bg;
          color: $theme-deafult__desktop-sub-item-current-color;
        }
        &.disabled {
          background: $theme-deafult__desktop-sub-bg;
          color: $theme-deafult__desktop-sub-item-disabled-color;
        }
        .sub-arrow {
          position: absolute;
          top: 50%;
          margin-top: -11px;
          left: auto;
          right: 15px;
          width: 34px;
          height: 34px;
          overflow: hidden;
          font: bold 14px/34px monospace !important;
          text-align: center;
          text-shadow: none;
          border-radius: 4px;
          transform: rotate(-90deg);
        }
      }
      li {
        border: 0;
      }
      &.full-mega-menu {
        ul {
          min-width: auto !important;
        }
        a {
          &:hover,
          &:focus,
          &:active,
          &.highlighted {
            &:after {
              content: ">";
              position: absolute;
              left: -10px;
            }
          }
        }
      }
    }
    .scroll-up,
    .scroll-down {
      position: absolute;
      display: none;
      visibility: hidden;
      overflow: hidden;
      background: $theme-deafult__desktop-sub-bg;
      height: 20px;
    }
    .scroll-up-arrow,
    .scroll-down-arrow {
      position: absolute;
      top: -2px;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      overflow: hidden;
      border-width: 8px; // tweak size of the arrow
      border-style: dashed dashed solid dashed;
      border-color: transparent transparent $theme-deafult__desktop-sub-item-color transparent;
    }
    .scroll-down-arrow {
      top: 6px;
      border-style: solid dashed dashed dashed;
      border-color: $theme-deafult__desktop-sub-item-color transparent transparent transparent;
    }
    // Main menu box
    &.sm-rtl {
      &.sm-vertical {
        a {
          .sub-arrow {
            right: auto;
            left: 10px;
            border-style: dashed solid dashed dashed;
            border-color: transparent $theme-primary transparent transparent;
          }
        }
      }
      > li {
        &:first-child {
          border-left: $theme-deafult__desktop-separators-size solid $theme-deafult__desktop-separators-color;
        }
        &:last-child {
          border-left: 0;
        }
      }
      ul {
        a {
          .sub-arrow {
            right: auto;
            left: 10px;
            border-style: dashed solid dashed dashed;
            border-color: transparent $theme-primary transparent transparent;
          }
        }
      }
    }
    // Main menu box
    &.sm-vertical {
      box-shadow: $theme-deafult__desktop-vertical-box-shadow;
      a {
        padding: $theme-deafult__desktop-vertical-item-padding-vertical $theme-deafult__desktop-vertical-item-padding-horizontal;
        .sub-arrow {
          top: 50%;
          margin-top: -$theme-primary;
          bottom: auto;
          left: auto;
          margin-left: 0;
          right: 10px;
          border-style: dashed dashed dashed solid;
          border-color: transparent transparent transparent $theme-primary;
        }
      }
      > li {
        border-left: 0 !important;
      }
    }
  }

}