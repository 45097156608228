/*-----------------------------------------------------------------------------------

    Template Name:Salon and Barber
    Template URI: 
    Description: This is E-commerce website
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

----------------------------------------------------------------------------------- */
/* 1.0. General
   1.1. Button
   1.2. Title */
/* 2. Header */
/* 3. Home slider  */
/* 4. Instagram */
/* 5. Brand Logo */
/* 6. Testimonial */
/* 7. Blog */
/* 8. Advertise */
/* 9. Product_section */
/* 10. New Arrivals*/
/* 11. hair_color*/
/* 12. About-us*/
/* 13. Service*/
/* 14. Mission*/
/* 15. Feature*/
/* 16. Call-to-action */
/* 17. Portfolio*/
/* 18. Add to cart*/
/* 19. Animation*/
/* 20. Footer*/
/* 21. Loader*/
/* 22. Tap to top*/
/* 23.Inner-page */
/* 24. Menu */
/* 25. Responsive */
/*=====================
    1.0.General
=======================*/
.container, .container-fluid {
  --bs-gutter-x: 15px !important; }

.row {
  --bs-gutter-x: 30px !important; }

section {
  padding: 100px 0; }

.section-t-space {
  padding-top: 100px; }

.section-b-space {
  padding-bottom: 100px; }

body {
  font-family: "Prata", serif, "Work Sans", sans-serif, "Dancing Script", cursive;
  position: relative;
  background: #ffffff;
  font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320))); }

h1 {
  font-style: "Work Sans", sans-serif;
  font-size: calc(22px + (80 - 22) * ((100vw - 320px) / (1920 - 320)));
  color: #000000;
  font-weight: 700;
  text-transform: uppercase; }

h2 {
  font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Prata", serif;
  color: #6a4031; }

h3 {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  font-family: "Work Sans", sans-serif; }

h4 {
  font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  font-family: "Work Sans", sans-serif; }

h5 {
  font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400; }

h6 {
  font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  font-family: "Work Sans", sans-serif;
  color: #6a4031; }

ul {
  padding-left: 0;
  margin-bottom: 0; }

li {
  display: block;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))); }

p {
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.6;
  color: #33322d;
  font-family: "Work Sans", sans-serif;
  font-weight: 400; }

span {
  font-size: 14px; }

a {
  text-decoration: none;
  transition: 0.5s ease;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))); }
  a:hover {
    text-decoration: none;
    transition: 0.5s ease; }
  a:focus {
    outline: none; }

.mt-cls {
  margin-top: 30px; }

button:focus, .btn:focus {
  outline: none;
  box-shadow: none; }

:focus {
  outline: none;
  box-shadow: none; }

.bg-light0 {
  background-color: #bfbfbf; }

.bg-light1 {
  background-color: #f1e7e6; }

.bg-light2 {
  background-color: #d0edff; }

.small-section {
  padding-top: 35px;
  padding-bottom: 35px; }

.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px; }

.btn_dark:hover {
  color: white; }

.slick-slide {
  line-height: 0; }

.slick-prev {
  left: -20px; }

.slick-next {
  right: -20px; }

.light-grey-bg {
  background-color: rgba(238, 228, 216, 0.4); }

.alert {
  font-size: 14px; }
  .alert .progress {
    height: 7px; }

.common-bg {
  background-image: url("../images/common_image/common_bg.png");
  background-attachment: fixed;
  position: relative; }

.common-decor .circel {
  position: absolute;
  height: 25px;
  width: 25px;
  border: 4px solid #eee4d8;
  border-radius: 100%;
  left: 30px;
  animation: myOrbit 9s linear infinite; }
  .common-decor .circel:nth-child(2) {
    right: 30px;
    left: unset;
    bottom: 50%;
    animation: myOrbit 9s linear infinite reverse; }
  .common-decor .circel:nth-child(3) {
    right: 50%;
    left: unset;
    bottom: 50px;
    animation: myOrbit 9s linear infinite reverse; }

.common-decor .plus {
  position: absolute;
  bottom: 50px;
  left: 50px;
  animation: star 7s linear infinite; }
  .common-decor .plus:before {
    content: "";
    height: 20px;
    position: absolute;
    width: 6px;
    background: linear-gradient(to top, #d7cab9 0%, #d9cab5 100%);
    border-radius: 5px;
    transform: rotate(-85deg); }
  .common-decor .plus:after {
    content: "";
    background-color: #fe7d0b;
    height: 20px;
    position: absolute;
    width: 6px;
    background: linear-gradient(to top, #d7cab9 0%, #d9cab5 100%);
    border-radius: 5px; }
  .common-decor .plus + .plus {
    left: unset;
    right: 30px;
    animation: star 15s linear infinite; }

.media {
  display: flex; }

/*==================
     1.1. Button
 ===================*/
.btn_dark {
  border: 1px solid #6a4031;
  background: #6a4031;
  color: #ffffff;
  padding: 15px 40px;
  border-radius: 100px;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
  overflow: hidden; }
  .btn_dark:after {
    background: #ffffff;
    content: "";
    height: 155px;
    left: -80px;
    opacity: 0.2;
    position: absolute;
    top: -30px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 1; }
  .btn_dark:hover:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }

.btn_lite {
  border: 1px solid #c7ac85;
  background: #c7ac85;
  color: #ffffff;
  padding: 15px 40px;
  border-radius: 100px;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1;
  overflow: hidden;
  position: relative; }
  .btn_lite:after {
    background: #ffffff;
    content: "";
    height: 155px;
    left: -80px;
    opacity: 0.2;
    position: absolute;
    top: -30px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 1; }
  .btn_lite:hover:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }

.btn-close:focus {
  box-shadow: none; }

/*======================
     1.2. Title
=======================*/
.title_prime {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  height: 100%; }
  .title_prime .icon-image {
    width: 50px;
    fill: #6a4031;
    margin-left: 37px; }
  .title_prime h5 {
    font-size: calc(14px + (28 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 23px;
    padding-left: 20px;
    position: relative;
    color: #6a4031;
    font-family: "Dancing Script", cursive;
    text-transform: capitalize;
    display: inline-block;
    letter-spacing: 0.02em;
    line-height: 26px; }
    .title_prime h5:before {
      content: "";
      height: 3px;
      width: 14px;
      position: absolute;
      left: 7px;
      top: 70%;
      background: #6a4031;
      transform: translate(-50%, 0); }
  .title_prime h2 {
    letter-spacing: 0.02em;
    line-height: 60px;
    padding-top: 30px;
    max-width: 340px;
    margin-top: -12px;
    margin-bottom: 0;
    text-transform: uppercase; }
  .title_prime p {
    margin-top: 0;
    padding-top: 20px;
    margin-bottom: 0; }

.title_second {
  margin-bottom: 50px; }
  .title_second h5 {
    color: #c7ac85;
    font-family: "Dancing Script", cursive;
    text-transform: capitalize;
    margin-top: -3px;
    font-size: calc(14px + (28 - 14) * ((100vw - 320px) / (1920 - 320))); }
  .title_second h2 {
    padding-top: 20px;
    margin-bottom: -8px; }

.title1 {
  text-align: center; }
  .title1 h4 {
    color: #6a4031;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-top: -3px; }
  .title1 .title-inner1 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative; }
    .title1 .title-inner1:after {
      position: absolute;
      height: 5px;
      width: 70px;
      background-color: #6a4031;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0; }
  .title1.title-gradient .title-inner1:after {
    background: #6a4031;
    background: linear-gradient(180deg, #01effc 0%, #485ff2 99%);
    background-color: #6a4031; }
  .title1.title5 .title-inner1 {
    padding-bottom: 0;
    margin-bottom: 0; }
    .title1.title5 .title-inner1:after {
      display: none; }
  .title1.title5 hr[role="tournament6"] {
    border: 0 solid;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(106, 64, 49, 0.75), rgba(0, 0, 0, 0));
    display: block;
    width: 25%;
    margin: 15px auto 30px auto; }
  .title1.title5 hr[role="tournament6"]::before {
    position: absolute;
    background-color: #efefef;
    border: 1px solid;
    border-color: #6a4031;
    padding: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 50%;
    margin: -6px 0px 0px 0;
    content: ""; }

.title2 {
  text-align: center; }
  .title2 h4 {
    color: #6a4031;
    padding-bottom: 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-top: -3px; }
  .title2 .title-inner2 {
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    padding: 0 25px; }
    .title2 .title-inner2:after {
      position: absolute;
      height: 5px;
      width: 100%;
      background-color: #8d5541;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 3px;
      z-index: -1; }

.title3 {
  text-align: center; }
  .title3 h4 {
    color: #6a4031;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-top: -3px; }
  .title3 .title-inner3 {
    margin-bottom: 20px;
    position: relative;
    margin-top: -5px; }
  .title3 .line {
    position: relative;
    height: 5px;
    width: 45px;
    background-color: #6a4031;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    margin-bottom: 50px; }
    .title3 .line:after, .title3 .line:before {
      position: absolute;
      height: 5px;
      width: 10px;
      content: "";
      background-color: #6a4031; }
    .title3 .line:after {
      right: 50px; }
    .title3 .line:before {
      left: 50px; }

.title4 {
  text-align: center; }
  .title4 .title-inner4 {
    padding-bottom: 25px;
    margin-bottom: 0;
    position: relative;
    margin-top: -5px; }
  .title4 .line {
    position: relative;
    height: 1px;
    width: 180px;
    background-color: #6a4031;
    margin: 0 auto;
    margin-bottom: 55px;
    bottom: 0;
    top: 0; }
    .title4 .line:after, .title4 .line:before {
      position: absolute;
      height: 10px;
      width: 10px;
      content: "";
      background-color: #6a4031;
      top: -4px; }
    .title4 .line:after {
      left: 78px; }
    .title4 .line:before {
      left: 64px; }
    .title4 .line span:after, .title4 .line span:before {
      position: absolute;
      height: 10px;
      width: 10px;
      content: "";
      background-color: #6a4031;
      top: -4px; }
    .title4 .line span:after {
      right: 78px; }
    .title4 .line span:before {
      right: 64px; }

.title6 {
  text-align: center; }
  .title6 h2 {
    margin-top: -5px; }

.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px; }

/*==================
     2. Header
====================*/
.top_header {
  font-family: "Poppins", sans-serif;
  padding: 5px 0;
  background: linear-gradient(270deg, #978262, #cbbaa6, #978262); }
  .top_header .greeting {
    display: flex;
    align-items: center;
    height: 100%;
    float: left; }
  .top_header ul.list-inline {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end; }
  .top_header h6 {
    color: #6a4031;
    font-size: 14px;
    line-height: 30px;
    font-family: "Poppins", sans-serif; }
  .top_header .dropdown .dropdown-menu {
    background: white;
    border-radius: 0; }
    .top_header .dropdown .dropdown-menu .dropdown-item {
      color: #6a4031; }
      .top_header .dropdown .dropdown-menu .dropdown-item:hover {
        color: #6a4031;
        font-weight: 700; }
      .top_header .dropdown .dropdown-menu .dropdown-item:active {
        background-color: white; }
  .top_header .dropdown .btn {
    background: transparent;
    color: #6a4031;
    border-radius: unset;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 1; }
    .top_header .dropdown .btn:focus {
      box-shadow: unset; }
  .top_header ul > li:nth-child(2) {
    border-right: 1px solid rgba(106, 64, 49, 0.4);
    padding: 0 15px 0 0;
    margin-right: 1.2rem !important; }
  .top_header i {
    color: #6a4031;
    font-size: 14px; }

.toggle-nav {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer; }

.menu {
  background: #ffffff; }
  .menu a {
    padding-top: 40px;
    padding-bottom: 40px;
    font-weight: 500;
    text-transform: uppercase; }
  .menu .menu-content {
    display: flex;
    justify-content: center;
    align-items: center; }
    .menu .menu-content .sm-horizontal .mobile-back {
      display: none; }
    .menu .menu-content .toggle-nav {
      display: none; }
      .menu .menu-content .toggle-nav i {
        font-size: 24px;
        color: #6a4031; }
    .menu .menu-content .menu-right {
      display: flex; }
      .menu .menu-content .menu-right nav {
        align-items: center;
        display: flex; }
      .menu .menu-content .menu-right .main-menu .mobile-back {
        display: none; }
      .menu .menu-content .menu-right .full-mega-menu {
        min-width: 1100px !important;
        padding: 20px !important;
        left: unset !important;
        right: 0; }
        .menu .menu-content .menu-right .full-mega-menu h5 {
          font-size: 14px;
          color: #6a4031;
          text-transform: uppercase;
          line-height: 14px;
          font-weight: 700;
          font-family: Poppins; }
        .menu .menu-content .menu-right .full-mega-menu ul {
          display: block;
          position: relative;
          box-shadow: none;
          border: none;
          background-color: transparent; }
          .menu .menu-content .menu-right .full-mega-menu ul a {
            padding: 9px 0;
            font-size: 14px; }
    .menu .menu-content .navbar .back {
      display: none; }
    .menu .menu-content .navbar .nav-link {
      color: #6a4031;
      padding-right: 24px;
      padding-left: 24px; }
    .menu .menu-content .navbar .navbar-toggler {
      color: #6a4031;
      border: unset; }
    .menu .menu-content a {
      font-family: "Poppins", sans-serif;
      line-height: 14px; }
    .menu .menu-content ul.navbar-nav .nav-link {
      transition: all 0.5s ease-in-out; }
      .menu .menu-content ul.navbar-nav .nav-link:hover {
        background: #6a4031;
        color: #ffffff;
        transition: all 0.5s ease-in-out; }
    .menu .menu-content .main_menu_right {
      display: flex;
      align-items: center; }
      .menu .menu-content .main_menu_right > button a {
        color: #ffffff;
        line-height: 0; }
      .menu .menu-content .main_menu_right > button + button {
        margin-left: 10px; }
      .menu .menu-content .main_menu_right .setting {
        display: none; }
      .menu .menu-content .main_menu_right button {
        cursor: pointer;
        color: #ffffff;
        border: 1px solid #6a4031;
        background: #6a4031;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease; }
        .menu .menu-content .main_menu_right button span {
          font-size: 16px; }
        .menu .menu-content .main_menu_right button:hover {
          background-color: white;
          color: #6a4031;
          transition: all 0.5s ease; }

.inner_header {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;
  background: #b6a48b;
  margin: 0 auto;
  border-top: 1px solid #6a4031;
  padding: 25px 0;
  box-sizing: border-box; }
  .inner_header .mid_header_right {
    display: flex;
    padding: 20px 0; }
    .inner_header .mid_header_right .location {
      margin-left: auto;
      max-width: 38%; }
  .inner_header .media span {
    color: #6a4031; }
  .inner_header .media .media-body p {
    color: #6a4031;
    font-weight: medium;
    font-size: 12px;
    font-family: "Work Sans", sans-serif; }

/*======================
     3. Home Slider
=======================*/
.home-slider.slick-slider .slick-prev {
  left: 10px;
  z-index: 1;
  opacity: 0; }
  .home-slider.slick-slider .slick-prev:before {
    content: "\f053";
    font: normal normal normal 20px/1 FontAwesome;
    border: 1px solid transparent;
    border-radius: 100%;
    padding: 5px;
    background: transparent;
    color: #6a4031; }

.home-slider.slick-slider .slick-next {
  right: 10px;
  z-index: 1;
  opacity: 0; }
  .home-slider.slick-slider .slick-next:before {
    content: "\f054";
    font: normal normal normal 20px/1 FontAwesome;
    border: 1px solid transparent;
    border-radius: 100%;
    padding: 5px;
    background: transparent;
    color: #6a4031; }

.home-slider.slick-slider:hover .slick-prev {
  left: 50px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  transform: scale(1); }

.home-slider.slick-slider:hover .slick-next {
  right: 50px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  transform: scale(1); }

.home-slider .main_section {
  height: 86vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }
  .home-slider .main_section.home1 {
    background-image: url("../images/home/1.jpg"); }
  .home-slider .main_section.home2 {
    background-image: url("../images/home/2.jpg"); }
  .home-slider .main_section.home3 {
    background-image: url("../images/home/3.jpg"); }
  .home-slider .main_section.home4 {
    background-image: url("../images/home/4.jpg"); }
  .home-slider .main_section.home5 {
    background-image: url("../images/home/5.jpg"); }
  .home-slider .main_section.home6 {
    background-image: url("../images/home/6.jpg"); }
  .home-slider .main_section.home7 {
    background-image: url("../images/home/7.jpg"); }
  .home-slider .main_section.home8 {
    background-image: url("../images/home/8.jpg"); }
  .home-slider .main_section.home9 {
    background-image: url("../images/home/9.jpg"); }
  .home-slider .main_section.home10 {
    background-image: url("../images/home/10.jpg"); }
  .home-slider .main_section.home11 {
    background-image: url("../images/home/11.jpg"); }
  .home-slider .main_section.home12 {
    background-image: url("../images/home/12.jpg"); }
  .home-slider .main_section.home13 {
    background-image: url("../images/home/13.jpg"); }
  .home-slider .main_section.home14 {
    background-image: url("../images/home/14.jpg"); }
  .home-slider .main_section.home15 {
    background-image: url("../images/home/15.jpg"); }
  .home-slider .main_section.home16 {
    background-image: url("../images/home/16.jpg"); }
  .home-slider .main_section.home17 {
    background-image: url("../images/home/17.jpg"); }
  .home-slider .main_section.home18 {
    background-image: url("../images/home/18.jpg"); }
  .home-slider .main_section.home19 {
    background-image: url("../images/home/19.jpg"); }
  .home-slider .main_section.home20 {
    background-image: url("../images/home/20.jpg"); }
  .home-slider .main_section .main_section_contain {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 86vh;
    width: 100%; }
    .home-slider .main_section .main_section_contain h1 {
      line-height: 110px;
      letter-spacing: 0.039em;
      margin-bottom: -47px; }
    .home-slider .main_section .main_section_contain .new {
      font-weight: 300;
      font-family: "Dancing Script", cursive;
      color: #6a4031;
      text-transform: capitalize;
      font-size: calc(25px + (150 - 25) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 20px;
      margin-top: 47px;
      line-height: 26px;
      letter-spacing: 0.019em; }
    .home-slider .main_section .main_section_contain p {
      font-weight: 500;
      color: #000000;
      line-height: 110px;
      letter-spacing: 0.179em;
      font-size: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)));
      margin-top: -74px;
      padding-top: 37px;
      margin-bottom: -47px;
      padding-bottom: 50px; }

/*====================
     4. Instagram
======================*/
.silk_insta_slide {
  line-height: 0; }
  .silk_insta_slide .instabox {
    position: relative;
    overflow: hidden; }
    .silk_insta_slide .instabox .overlay {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(240, 163, 76, 0.4);
      transition: all 0.5s ease; }
      .silk_insta_slide .instabox .overlay i {
        font-size: 25px;
        color: #ffffff; }
      .silk_insta_slide .instabox .overlay:hover {
        opacity: 1;
        transform: scale(3);
        transition: all 0.5s ease;
        cursor: pointer; }

/*======================
     5. Brand
========================*/
.brand_section {
  background: url(../images/hair_color/bg.png) scroll top center;
  background-repeat: no-repeat; }
  .brand_section .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0; }
  .brand_section .brand > div:nth-child(1) {
    border-bottom: 2px solid;
    border-image: linear-gradient(-90deg, #cab79a, #ffffff);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(2) {
    border-right: 2px solid;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-image: linear-gradient(0deg, #cab79a, #ffffff);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(3) {
    border-bottom: 2px solid;
    border-image: linear-gradient(-90deg, #ffffff, #cab79a);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(5) {
    border-right: 2px solid;
    border-left: 2px solid;
    border-image: linear-gradient(0deg, #ffffff, #cab79a);
    border-image-slice: 1; }

/*======================
     6. Testimonial
========================*/
.testimonial_section {
  background: url(../images/testimonial/bg1.jpg) scroll top center;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .testimonial_section .testimonial .test_image {
    position: relative;
    display: inline-block; }
    .testimonial_section .testimonial .test_image img {
      border-radius: 100px;
      z-index: 2;
      width: 140px; }
    .testimonial_section .testimonial .test_image .chat_icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -2;
      width: 63px;
      transform: translate(65%); }
  .testimonial_section .testimonial .comment_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 30px; }
    .testimonial_section .testimonial .comment_box .comment {
      position: relative;
      display: inline-block; }
      .testimonial_section .testimonial .comment_box .comment:before {
        content: "\f10d";
        font: normal normal normal 20px/1 FontAwesome;
        position: absolute;
        top: -20px;
        left: -20px; }
      .testimonial_section .testimonial .comment_box .comment:after {
        content: "\f10e";
        font: normal normal normal 20px/1 FontAwesome;
        position: absolute;
        bottom: -20px;
        right: 0; }
    .testimonial_section .testimonial .comment_box .rating {
      display: flex;
      align-items: center; }
      .testimonial_section .testimonial .comment_box .rating > i:nth-child(n) {
        color: #6a4031; }
      .testimonial_section .testimonial .comment_box .rating > i:nth-child(5) {
        color: #ffffff; }
      .testimonial_section .testimonial .comment_box .rating p {
        color: #927364; }
    .testimonial_section .testimonial .comment_box h6 {
      font-weight: 600;
      color: #6a4031; }
    .testimonial_section .testimonial .comment_box .reg {
      position: relative;
      display: inline-block;
      font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320))); }
      .testimonial_section .testimonial .comment_box .reg:before {
        content: "";
        position: absolute;
        background: #6a4031;
        height: 2px;
        width: 10px;
        top: 50%;
        margin: 0 -16px;
        transform: translate(-20%, -50%); }
  .testimonial_section .nav_image {
    margin-bottom: -20px;
    padding-top: 0; }
    .testimonial_section .nav_image .slick-current img {
      transform: scale(1.5); }
  .testimonial_section .nav_img {
    border-radius: 100%;
    padding: 35px 13%;
    width: 100%;
    cursor: pointer;
    position: relative; }
    .testimonial_section .nav_img:after {
      position: absolute;
      content: " ";
      height: 100%;
      width: 100%;
      border-radius: 100%;
      left: 0;
      top: -20px;
      background: #000000;
      background: linear-gradient(#ffffff, #000000);
      opacity: 0.7; }
  .testimonial_section .theme-arrow.slick-slider .slick-prev:before {
    content: "\f177";
    color: #6a4031;
    font-size: 35px;
    font: normal normal normal 20px/1 FontAwesome; }
  .testimonial_section .theme-arrow.slick-slider .slick-next:before {
    content: "\f178";
    color: #6a4031;
    font-size: 35px;
    font: normal normal normal 20px/1 FontAwesome; }

/*====================
    7. Blog
======================*/
.blog_section {
  background: url(../images/photo-gallery/bg.png) scroll top center;
  background-repeat: no-repeat; }
  .blog_section .slick-slide {
    margin: 0 10px; }
  .blog_section .blog .card {
    border: none; }
    .blog_section .blog .card:hover img {
      transform: scale(1.09);
      transition: all 0.5s ease-in-out; }
    .blog_section .blog .card .card_image {
      overflow: hidden; }
      .blog_section .blog .card .card_image .card-img-top {
        transition: all 0.5s ease-in-out; }
    .blog_section .blog .card .card-body {
      padding: 15px 0 0 0; }
      .blog_section .blog .card .card-body h5 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-family: "Prata", serif;
        letter-spacing: 0.022em;
        font-weight: bold;
        position: relative;
        color: #6a4031;
        display: inline-block;
        margin-bottom: 6px; }
      .blog_section .blog .card .card-body p {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))); }
    .blog_section .blog .card h6 {
      padding-top: 15px;
      padding-bottom: 5px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #6a4031;
      text-align: left;
      margin-bottom: 0;
      font-size: 12px; }
      .blog_section .blog .card h6 .country {
        padding-right: 5px; }
      .blog_section .blog .card h6 .date {
        border-left: 1px solid rgba(106, 64, 49, 0.1);
        padding: 0 5px; }
    .blog_section .blog .card .btn {
      border-radius: unset; }
      .blog_section .blog .card .btn:focus {
        box-shadow: unset; }
    .blog_section .blog .card a {
      font-size: 12px;
      font-family: "Poppins", sans-serif;
      color: #6a4031;
      padding: 0;
      font-weight: 600;
      letter-spacing: 0.09em;
      margin-bottom: -5px; }

/*===================
   8. Advertise
=====================*/
.advertise_section {
  background: url(../images/Hording/bg_banner.jpg) scroll top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 192px 0;
  margin-top: 118px;
  position: relative; }
  .advertise_section .bottle img {
    position: absolute;
    bottom: -99px; }
  .advertise_section .adv_contain {
    margin-top: -4px; }
    .advertise_section .adv_contain h3 {
      color: #6a4031;
      font-family: "Prata", serif;
      margin-bottom: 0; }
    .advertise_section .adv_contain p {
      margin-bottom: -2px;
      padding: 25px 49px 0 0;
      margin-top: -6px; }
    .advertise_section .adv_contain .btn_dark {
      margin-top: 35px; }

/*======================
  working days section
========================*/
.working-hourse-main .working-hourse-contain ul li {
  border-bottom: 1px solid #c7ac85;
  padding-bottom: 15px;
  padding-top: 15px;
  text-transform: capitalize;
  color: #6a4031;
  letter-spacing: 0.08em;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))); }
  .working-hourse-main .working-hourse-contain ul li:first-child {
    padding-top: 0; }
  .working-hourse-main .working-hourse-contain ul li span {
    float: right; }

.working-hourse-main .working-hourse-icon > div:nth-child(1) {
  border-bottom: 2px solid;
  border-image: linear-gradient(-90deg, #cab79a, #ffffff);
  border-image-slice: 1; }

.working-hourse-main .working-hourse-icon > div:nth-child(2) {
  border-right: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-image: linear-gradient(0deg, #cab79a, #ffffff);
  border-image-slice: 1; }

.working-hourse-main .working-hourse-icon > div:nth-child(3) {
  border-bottom: 2px solid;
  border-image: linear-gradient(-90deg, #ffffff, #cab79a);
  border-image-slice: 1; }

.working-hourse-main .working-hourse-icon > div:nth-child(5) {
  border-right: 2px solid;
  border-left: 2px solid;
  border-image: linear-gradient(0deg, #ffffff, #cab79a);
  border-image-slice: 1; }

.working-hourse-main .working-hourse-icon > div .logo {
  padding-bottom: 40px; }
  .working-hourse-main .working-hourse-icon > div .logo p {
    margin-bottom: 0;
    padding-top: 10px;
    text-transform: capitalize;
    line-height: 1;
    color: #6a4031; }

.working-hourse-main .working-hourse-icon > div:nth-child(n+4) .logo {
  padding-top: 40px; }

.working-hourse-main .title_second h2 {
  margin-bottom: 0; }

/*======================
   9. Product_section
========================*/
.cattagory-margin {
  margin-bottom: -50px; }

.product_box {
  position: relative;
  overflow: hidden; }
  .product_box.product-margin {
    margin-bottom: 30px; }
  .product_box.catagory_box {
    margin-top: 25px; }
  .product_box:hover img {
    transform: scale(1.09);
    transition: all 0.5s ease-in-out; }
  .product_box:hover .product_image ul > li:nth-child(2) {
    opacity: 1;
    transform: translateY(0); }
  .product_box:hover .product_image ul > li:nth-child(3) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s; }
  .product_box:hover .product_image ul > li:nth-child(4) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s; }
  .product_box .tringle-topleft {
    position: absolute;
    top: 0;
    left: 0; }
    .product_box .tringle-topleft:before {
      content: "";
      position: absolute;
      border-top: 70px solid #6a4031;
      border-right: 70px solid transparent; }
    .product_box .tringle-topleft h6 {
      color: #ffffff;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.02em;
      position: absolute;
      left: 3px;
      top: 15px;
      transform: rotate(-45deg); }
  .product_box .product_image {
    overflow: hidden;
    position: relative; }
    .product_box .product_image .timer-box {
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%; }
      .product_box .product_image .timer-box .timer {
        background: rgba(106, 64, 49, 0.48);
        padding: 10px 0; }
        .product_box .product_image .timer-box .timer p {
          color: #ffffff;
          margin-bottom: 0; }
        .product_box .product_image .timer-box .timer span {
          width: 70px;
          display: inline-block; }
          .product_box .product_image .timer-box .timer span .timer-cal {
            color: #ffffff;
            text-align: left;
            padding-left: 10px; }
        .product_box .product_image .timer-box .timer .sec {
          padding-right: 30px; }
        .product_box .product_image .timer-box .timer .padding-l {
          padding-left: 22px;
          display: inline; }
    .product_box .product_image .product_icon {
      position: absolute;
      top: 10px;
      right: 10px; }
      .product_box .product_image .product_icon i {
        color: #6a4031;
        border: none;
        border-radius: 100%;
        padding: 8px;
        background: rgba(255, 255, 255, 0.6); }
    .product_box .product_image ul > li + li {
      padding-top: 5px; }
  .product_box ul > li {
    transition: all 0.5s ease-in-out; }
    .product_box ul > li:nth-child(2) {
      opacity: 0;
      transform: translateY(-50px); }
    .product_box ul > li:nth-child(3) {
      opacity: 0;
      transform: translateY(-50px); }
    .product_box ul > li:nth-child(4) {
      opacity: 0;
      transform: translateY(-50px); }

.card-img-top {
  transition: all 0.5s ease-in-out;
  border-radius: 0; }

.img-lable {
  position: absolute;
  top: 10px;
  left: 10px; }
  .img-lable h6 {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.02em;
    border: 1px solid #6a4031;
    padding: 2px 9px;
    background: #6a4031; }

.product_contain {
  background: white;
  padding: 15px 0 0 0; }
  .product_contain .card-title {
    margin-bottom: 5px;
    font-weight: 600;
    color: #6a4031; }
  .product_contain h6 {
    font-weight: 600;
    margin-bottom: -2px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.022em;
    color: #000000; }
    .product_contain h6 del {
      font-weight: 400; }

.product_section {
  background: url(../images/photo-gallery/bg.png) scroll top center;
  background-size: cover; }
  .product_section ul.tabs {
    list-style: none;
    text-align: center;
    margin-top: -15px; }
    .product_section ul.tabs li {
      background: transparent;
      color: #6a4031;
      display: inline-block;
      padding: 6px 15px;
      cursor: pointer;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      letter-spacing: 0.02em; }
      .product_section ul.tabs li.current {
        background: #6a4031;
        color: white;
        border-radius: 50px; }
  .product_section .tab-content {
    display: none; }
    .product_section .tab-content.current {
      display: inherit; }
      .product_section .tab-content.current .product_box {
        animation: zoomIn 300ms ease-in-out; }
    .product_section .tab-content .product-row > div:nth-child(n) {
      margin-top: 35px; }

/*====================
   10. New Arrivals_section
=====================*/
.new_arrival_section {
  background: url(../images/photo-gallery/bg.png) scroll top center;
  background-size: cover; }
  .new_arrival_section .slick-slide {
    margin: 0 10px; }
  .new_arrival_section .slick-slider .slick-prev:before {
    content: "\f053";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 50%;
    left: 0;
    color: #6a4031;
    border: 1px solid #eee4d8;
    padding: 5px;
    background: #eee4d8; }
  .new_arrival_section .slick-slider .slick-next:before {
    content: "\f054";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 50%;
    right: 0;
    color: #6a4031;
    border: 1px solid #eee4d8;
    padding: 5px;
    background: #eee4d8; }

/*=====================
    11. hair_color
=======================*/
.hair_color_section {
  background: url(../images/hair_color/bg.png) scroll top center;
  background-size: cover; }
  .hair_color_section .slick-slide {
    margin: 0 10px; }
  .hair_color_section .hair_color .main-box {
    position: relative; }
    .hair_color_section .hair_color .main-box:hover .main-color {
      transform: translateY(5px);
      transition: all 300ms ease-in-out; }
    .hair_color_section .hair_color .main-box .main-color {
      position: absolute;
      width: 100%;
      bottom: 5px;
      left: 0;
      background: #d7cab9;
      display: flex;
      justify-content: center;
      padding: 15px;
      transform: translateY(90%);
      transition: all 300ms ease-in-out; }
      .hair_color_section .hair_color .main-box .main-color:before {
        content: "";
        position: absolute;
        height: 25px;
        width: 25px;
        background: #6a4031;
        border: 7px solid white;
        border-radius: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 0; }
    .hair_color_section .hair_color .main-box li {
      padding: 15px 10px;
      cursor: pointer; }

/*=====================
    12. About-us
=======================*/
.about-us-section {
  background: url(../images/About-us/4.png) scroll top center;
  background-size: cover; }
  .about-us-section .main-image {
    position: relative;
    display: inline-block;
    margin-bottom: 0; }
    .about-us-section .main-image h4 {
      position: absolute;
      top: 71px;
      color: #c7ac85;
      font-family: "Prata", serif;
      right: -23%;
      transform: rotate(-90deg) translateY(0);
      letter-spacing: 0.019em;
      line-height: 25.99px; }
  .about-us-section .main-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .about-us-section .main-contain h2 {
      color: #6a4031;
      text-transform: capitalize;
      line-height: 70px;
      margin-top: -14px;
      margin-bottom: -11px;
      letter-spacing: 0.02em;
      padding-bottom: 37px; }
      .about-us-section .main-contain h2 .experience-text {
        font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        display: block; }
    .about-us-section .main-contain .para1 {
      position: relative;
      padding-top: 45px; }
      .about-us-section .main-contain .para1:before {
        content: "";
        height: 5px;
        width: 125px;
        background: linear-gradient(90deg, #6a4031, #ffffff);
        border-image-slice: 1;
        position: absolute;
        top: 0;
        left: 0; }
    .about-us-section .main-contain .para2 {
      padding-top: 0;
      margin-bottom: -2px; }
    .about-us-section .main-contain .btn_lite {
      margin-top: 32px; }
  .about-us-section .btn_dark {
    margin-top: 35px; }

/*==================
    13. Service
===================*/
.service-section {
  background: url(../images/service/bg.jpg) scroll top center;
  background-size: cover;
  background-attachment: fixed; }
  .service-section .title_prime .btn {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 30px;
    letter-spacing: 0.02em;
    font-weight: 500;
    padding: 0;
    position: relative;
    border-radius: unset;
    font-family: "Poppins", sans-serif;
    color: #6a4031;
    margin-bottom: -10px;
    margin-top: 39px;
    transition: all 0.5s ease-in-out; }
    .service-section .title_prime .btn:focus {
      box-shadow: unset; }
  .service-section .btn_dark {
    margin-top: 35px; }
  .service-section .image-container {
    margin: 50px 0;
    position: relative; }
    .service-section .image-container .ba-slider {
      position: relative;
      z-index: 2; }
      .service-section .image-container .ba-slider img {
        width: 100%;
        display: block; }
      .service-section .image-container .ba-slider .after-lable {
        position: absolute;
        top: 50%;
        right: -20px;
        color: black;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 25px;
        padding: 2px 7px;
        transform: translate(0, -50%);
        z-index: 1; }
    .service-section .image-container .resize {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background: #222222; }
      .service-section .image-container .resize img {
        opacity: 0.8; }
      .service-section .image-container .resize .before-lable {
        position: absolute;
        top: 50%;
        left: -20px;
        color: black;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 25px;
        padding: 2px 7px;
        transform: translate(0, -50%); }
    .service-section .image-container .handle {
      /* Thin line seperator */
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      width: 3px;
      margin-left: -2px;
      background: #ffffff;
      cursor: ew-resize; }
      .service-section .image-container .handle:before {
        content: "";
        height: 40px;
        width: 40px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #e5e5e5;
        border: 10px solid #ffffff;
        border-radius: 100%;
        z-index: 2; }
    .service-section .image-container .back-div {
      height: 120%;
      width: 75%;
      left: 50%;
      top: -10%;
      background: linear-gradient(270deg, #d8c9b6, #e2d7c9, #d3c3ac);
      border-image-slice: 1;
      position: absolute;
      transform: translate(-50%, 0); }

/*=================
    14. Mission
 ==================*/
.mission-section {
  background: url(../images/mission/bg.jpg) scroll top center;
  background-size: cover;
  background-attachment: fixed; }
  .mission-section .title_prime .para2 {
    margin-top: -7px;
    margin-bottom: 0;
    padding-top: 35px; }
  .mission-section .title_prime .btn_dark {
    margin-top: 35px; }
  .mission-section .mission-image > div:nth-last-child(-n + 2) {
    margin-top: 30px; }
  .mission-section .box-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .mission-section .box-container .box-item {
      position: relative;
      backface-visibility: hidden; }
      .mission-section .box-container .box-item .flip-box {
        transform-style: preserve-3d;
        perspective: 1000px; }
        .mission-section .box-container .box-item .flip-box:hover .flip-box-back {
          transform: rotateY(0deg);
          transform-style: preserve-3d; }
        .mission-section .box-container .box-item .flip-box .flip-box-front {
          transform: rotateY(0deg);
          transform-style: preserve-3d;
          border-radius: 8px;
          overflow: hidden; }
        .mission-section .box-container .box-item .flip-box .flip-box-back {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          transform: rotateY(180deg);
          transform-style: preserve-3d; }
          .mission-section .box-container .box-item .flip-box .flip-box-back .inner {
            position: absolute;
            left: 0;
            width: 100%;
            outline: 1px solid transparent;
            perspective: inherit;
            z-index: 2;
            transform: translateY(-50%) translateZ(60px) scale(0.94);
            top: 50%;
            background: rgba(176, 151, 121, 0.8);
            height: 100%;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .mission-section .box-container .box-item .flip-box .flip-box-back .inner h6 {
              font-family: "Prata", serif; }
            .mission-section .box-container .box-item .flip-box .flip-box-back .inner p {
              margin-bottom: 7px; }
            .mission-section .box-container .box-item .flip-box .flip-box-back .inner h6,
            .mission-section .box-container .box-item .flip-box .flip-box-back .inner p,
            .mission-section .box-container .box-item .flip-box .flip-box-back .inner span {
              color: #ffffff; }
    .mission-section .box-container .flip-box-front,
    .mission-section .box-container .flip-box-back {
      transition: transform 0.5s cubic-bezier(0.4, 0.2, 0.2, 1);
      backface-visibility: hidden; }
    .mission-section .box-container .flip-box:hover .flip-box-front {
      transform: rotateY(-180deg);
      transform-style: preserve-3d; }

/*=====================
    15. Feature
=======================*/
.feature-section {
  background: url(../images/cosmetic/bg.jpg) scroll top center;
  background-size: cover;
  background-attachment: fixed; }
  .feature-section .title {
    color: #6a4031; }
    .feature-section .title h5 {
      font-family: "Dancing Script", cursive;
      margin-top: -3px; }
    .feature-section .title p {
      margin-bottom: 0; }
  .feature-section .bottle-image {
    margin-top: 75px;
    margin-bottom: -20px; }
  .feature-section .feature-box > div:nth-child(1) {
    margin-top: 213px; }
  .feature-section .feature-box > div:nth-child(2) {
    margin-top: 93px; }
  .feature-section .feature-box .features-rtl {
    text-align: right; }
  .feature-section .feature-box .features h4 {
    font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #6a4031;
    text-transform: uppercase;
    font-family: "Prata", serif;
    letter-spacing: 0.02em; }

/*=====================
    16. Call-to-action
=======================*/
.call-to-action-section {
  background: linear-gradient(270deg, #978262, #cbbaa6, #978262); }
  .call-to-action-section .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left; }
    .call-to-action-section .contact .media {
      align-items: center; }
      .call-to-action-section .contact .media svg {
        margin-right: 15px;
        fill: #6a4031; }
      .call-to-action-section .contact .media .media-body {
        padding-right: 60px;
        color: #6a4031; }
        .call-to-action-section .contact .media .media-body h6 {
          font-weight: 700; }
        .call-to-action-section .contact .media .media-body p {
          font-size: 13px;
          margin-bottom: 0; }
    .call-to-action-section .contact .newsletter {
      border-left: 2px solid #a58e77;
      padding-left: 60px; }
    .call-to-action-section .contact .news-contain {
      color: #6a4031; }
      .call-to-action-section .contact .news-contain h6 {
        font-weight: 700; }
      .call-to-action-section .contact .news-contain p {
        font-size: 13px;
        margin-bottom: 0; }
  .call-to-action-section .email-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .call-to-action-section .email-container .email-box {
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      overflow: hidden; }
      .call-to-action-section .email-container .email-box ::placeholder {
        color: #6a4031;
        font-family: "Poppins", sans-serif;
        font-size: 12px; }
      .call-to-action-section .email-container .email-box .email {
        display: inline-block;
        background: white;
        border-radius: 25px;
        border: 1px solid transparent;
        color: #6a4031;
        padding: 7px 15px;
        position: relative;
        width: 435px;
        z-index: 4; }
        .call-to-action-section .email-container .email-box .email:focus {
          outline: unset; }
      .call-to-action-section .email-container .email-box .submit-btn {
        position: absolute;
        background: #6a4031;
        color: #ffffff;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border: 1px solid transparent;
        padding: 7px 15px;
        top: 0;
        right: 0;
        z-index: 5;
        cursor: pointer;
        overflow: hidden;
        font-family: "Work Sans", sans-serif;
        font-size: 14px;
        height: 100%; }
        .call-to-action-section .email-container .email-box .submit-btn:after {
          background: #ffffff;
          content: "";
          height: 155px;
          left: -90px;
          opacity: 0.2;
          position: absolute;
          top: -30px;
          transform: rotate(35deg);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          width: 50px;
          z-index: 1; }
        .call-to-action-section .email-container .email-box .submit-btn:hover:after {
          left: 120%;
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }
        .call-to-action-section .email-container .email-box .submit-btn i {
          padding-right: 10px; }

/*=====================
    17. Portfoilio CSS start
==========================*/
.portfolio-padding {
  margin-bottom: -30px; }

.portfolio-section button {
  font-family: "Poppins", sans-serif; }

.portfolio-section .portfolio-grid-option {
  text-align: center; }

.portfolio-section .article-title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: white;
  transition: all 0.5s ease;
  text-align: center;
  float: left;
  font-size: 18px;
  line-height: 24px; }
  .portfolio-section .article-title a {
    color: white; }

.portfolio-section .isotopeSelector {
  float: left;
  position: relative; }
  .portfolio-section .isotopeSelector .overlay {
    position: relative;
    border: 1px solid #dddddd; }
  .portfolio-section .isotopeSelector img {
    width: 100%;
    height: auto; }

.portfolio-section .isotopeSelector {
  margin-bottom: 30px; }
  .portfolio-section .isotopeSelector img {
    transition: all 0.4s ease; }
  .portfolio-section .isotopeSelector .overlay-background {
    transform: scale(0);
    transition: all 0.4s ease; }
    .portfolio-section .isotopeSelector .overlay-background i {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: white;
      padding: 10px;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      color: #6a4031;
      font-size: 22px;
      display: none;
      transition: all 0.4s ease;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center; }
  .portfolio-section .isotopeSelector:hover img {
    transform: scale(1.2) rotate(2deg);
    transition: all 0.4s ease; }
  .portfolio-section .isotopeSelector:hover .overlay-background {
    transform: scale(1);
    border: 3px solid white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(106, 64, 49, 0.3);
    transition: all 0.4s ease; }
    .portfolio-section .isotopeSelector:hover .overlay-background i {
      display: flex;
      transition: all 0.4s ease; }

.portfolio-section .border-portfolio {
  margin: 3px;
  overflow: hidden; }

.portfolio-section #form1 {
  padding-bottom: 20px; }

.portfolio-section .filter-button {
  padding: 0 24px;
  font-size: 20px;
  line-height: 35px;
  border: none;
  background-color: white;
  color: #6a4031; }
  .portfolio-section .filter-button.active {
    background-color: #6a4031;
    color: #ffffff; }

.portfolio-section.fullwidth-portfolio .isotopeSelector {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 14px; }

.portfolio-section.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center; }
  .portfolio-section.metro-section .product-box .product-detail h6 {
    color: #525252;
    padding-top: 0;
    margin-top: -2px; }
  .portfolio-section.metro-section .product-box .product-detail h4 {
    font-weight: 400;
    color: #000000; }

.portfolio-section.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1; }
  .portfolio-section.metro-section .product-box .cart-wrap button {
    border: 1px solid #ffffff;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
    .portfolio-section.metro-section .product-box .cart-wrap button i {
      padding: 7px;
      color: #333333; }
  .portfolio-section.metro-section .product-box .cart-wrap a i {
    border-radius: 100%;
    border: 1px solid #ffffff;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 7px;
    color: #333333;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.portfolio-section.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px; }

.portfolio-section.metro-section .product-box:hover .cart-wrap button {
  animation: none; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInDown 500ms ease-in-out; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInDown 700ms ease-in-out; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInDown 1000ms ease-in-out; }

.filter-section .filter-container {
  text-align: center;
  padding: 0 0 20px; }
  .filter-section .filter-container ul.filter > li {
    display: inline-block;
    padding: 0 25px; }
    .filter-section .filter-container ul.filter > li > a {
      color: #6a4031;
      font-size: 20px;
      line-height: 40px;
      font-family: "Poppins", sans-serif; }
      .filter-section .filter-container ul.filter > li > a:hover, .filter-section .filter-container ul.filter > li > a:focus {
        text-decoration: none;
        color: #6a4031;
        outline: none; }
    .filter-section .filter-container ul.filter > li.active {
      background-color: #6a4031; }
      .filter-section .filter-container ul.filter > li.active a {
        color: #ffffff; }
    .filter-section .filter-container ul.filter > li span {
      color: #ddd;
      font-size: 20px;
      display: inline-block;
      margin: 0 5px; }
  .filter-section .filter-container .filter > li {
    padding: 0;
    margin: 0; }

.filter-section .title1 .title-inner1 {
  margin-top: -5px;
  position: relative;
  text-align: center; }

.filter-section h1 {
  text-align: center; }

.demo-colored-wrap {
  background: #ecf0f1; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  backface-visibility: hidden;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

.mfp-title {
  display: none; }

.offer-box {
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  display: flex;
  left: -415px;
  transition: all 0.5s ease;
  z-index: 9; }
  .offer-box img {
    margin-right: 18px; }
  .offer-box.toggle-cls {
    left: 0;
    transition: all 0.5s ease; }
  .offer-box .heading-right h3 {
    position: absolute;
    top: 50%;
    right: 0;
    width: 230px;
    height: 36px;
    line-height: 36px;
    letter-spacing: 5px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    transform: translate(50%, -50%) rotate(-90deg);
    text-align: center;
    background-color: #6a4031;
    margin-bottom: 0;
    cursor: pointer; }

/*=====================
   18. Add to Cart Panel
=======================*/
.add_to_cart {
  position: fixed;
  background-color: #ffffff;
  z-index: 9999;
  transition: all 0.5s ease; }
  .add_to_cart::-webkit-scrollbar {
    width: 3px;
    height: 7px; }
  .add_to_cart::-webkit-scrollbar-track {
    background: #dddddd; }
  .add_to_cart::-webkit-scrollbar-thumb {
    background: #888888; }
    .add_to_cart::-webkit-scrollbar-thumb:hover {
      background: #555555; }
  .add_to_cart .cart-inner {
    background-color: #ffffff;
    z-index: 9;
    position: relative;
    top: 0; }
    .add_to_cart .cart-inner .cart_media {
      padding: 0 20px; }
      .add_to_cart .cart-inner .cart_media li {
        position: relative;
        padding-bottom: 10px;
        width: 100%; }
        .add_to_cart .cart-inner .cart_media li .media {
          align-items: center;
          height: 100%; }
          .add_to_cart .cart-inner .cart_media li .media img {
            height: 90px; }
          .add_to_cart .cart-inner .cart_media li .media .media-body {
            align-self: center; }
            .add_to_cart .cart-inner .cart_media li .media .media-body h4 {
              color: #6a4031; }
              .add_to_cart .cart-inner .cart_media li .media .media-body h4 span {
                color: #6a4031;
                font-size: 16px; }
            .add_to_cart .cart-inner .cart_media li .media .media-body h6 {
              color: #6a4031;
              text-transform: capitalize;
              margin-bottom: 0; }
        .add_to_cart .cart-inner .cart_media li .total {
          border-top: 1px solid #a58e77;
          border-bottom: 1px solid #a58e77;
          padding-top: 10px;
          padding-bottom: 10px; }
          .add_to_cart .cart-inner .cart_media li .total h5 {
            text-transform: capitalize;
            margin-bottom: 0;
            color: #6a4031;
            font-size: 18px; }
            .add_to_cart .cart-inner .cart_media li .total h5 span {
              float: right;
              padding-top: 4px; }
        .add_to_cart .cart-inner .cart_media li .buttons a {
          font-size: 16px;
          color: #6a4031;
          text-transform: capitalize;
          font-weight: 700;
          transition: all 0.5s ease;
          padding-left: 0;
          padding-right: 0; }
          .add_to_cart .cart-inner .cart_media li .buttons a:hover {
            color: #c7ac85;
            transition: all 0.5s ease; }
        .add_to_cart .cart-inner .cart_media li .buttons .checkout {
          float: right; }
      .add_to_cart .cart-inner .cart_media .close-circle {
        position: absolute;
        top: 0;
        right: 0; }
        .add_to_cart .cart-inner .cart_media .close-circle i {
          color: #6a4031;
          font-size: 16px;
          transition: all 0.5s ease; }
          .add_to_cart .cart-inner .cart_media .close-circle i:hover {
            color: #000000;
            transition: all 0.5s ease; }
    .add_to_cart .cart-inner .cart_top {
      display: flex;
      padding: 20px;
      border-bottom: 1px solid #a58e77;
      margin-bottom: 20px;
      align-items: center; }
      .add_to_cart .cart-inner .cart_top h3 {
        font-size: 18px;
        color: #6a4031;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0; }
      .add_to_cart .cart-inner .cart_top .close-cart {
        margin-left: auto; }
        .add_to_cart .cart-inner .cart_top .close-cart i {
          color: #6a4031;
          font-size: 18px; }
  .add_to_cart .overlay {
    visibility: hidden;
    opacity: 0;
    background-color: #212331;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease; }
  .add_to_cart.open-side {
    transition: all 0.5s ease; }
    .add_to_cart.open-side .overlay {
      opacity: 0.8;
      visibility: visible;
      transition: all 0.5s ease; }
  .add_to_cart.left {
    left: -300px;
    top: 0;
    height: 100vh;
    width: 300px; }
    .add_to_cart.left .cart-inner {
      height: 100vh;
      overflow: auto; }
    .add_to_cart.left.open-side {
      left: 0; }
  .add_to_cart.right {
    right: -300px;
    height: 100vh;
    width: 300px;
    top: 0; }
    .add_to_cart.right .cart-inner {
      height: 100vh;
      overflow: auto; }
    .add_to_cart.right.open-side {
      right: 0; }
  .add_to_cart.top {
    height: 218px;
    width: 100%;
    top: -300px; }
    .add_to_cart.top .cart_media {
      display: flex;
      width: 100%; }
      .add_to_cart.top .cart_media .cart_product {
        display: flex;
        width: 75%;
        overflow-y: auto; }
        .add_to_cart.top .cart_media .cart_product li {
          min-width: 245px;
          max-width: 225px;
          margin-right: 18px;
          padding-bottom: 0; }
      .add_to_cart.top .cart_media .cart_total {
        width: 25%; }
    .add_to_cart.top .cart-inner {
      height: 218px; }
      .add_to_cart.top .cart-inner.top-search {
        display: flex;
        justify-content: center;
        align-items: center; }
        .add_to_cart.top .cart-inner.top-search ::placeholder {
          color: #6a4031;
          font-family: "Poppins", sans-serif;
          font-size: 16px; }
        .add_to_cart.top .cart-inner.top-search span {
          margin-left: 10px;
          background: #6a4031;
          color: #ffffff;
          height: 32px;
          width: 32px;
          justify-content: center;
          align-items: center;
          display: flex; }
        .add_to_cart.top .cart-inner.top-search .searchbox-input {
          width: 70%;
          border: none;
          border-bottom: 2px solid #6a4031;
          color: #6a4031;
          outline: unset; }
          .add_to_cart.top .cart-inner.top-search .searchbox-input:focus {
            outline: none; }
      .add_to_cart.top .cart-inner .cart_top {
        margin-bottom: 0;
        background-color: #fbfbfb;
        padding: 15px 44px;
        border-bottom: none; }
      .add_to_cart.top .cart-inner .cart_media {
        padding: 0; }
        .add_to_cart.top .cart-inner .cart_media .cart_product {
          padding: 30px 44px; }
        .add_to_cart.top .cart-inner .cart_media .cart_total {
          padding: 30px 44px; }
    .add_to_cart.top.open-side {
      top: 0; }
  .add_to_cart.bottom {
    height: 218px;
    width: 100%;
    bottom: -300px; }
    .add_to_cart.bottom .cart_media {
      display: flex;
      width: 100%; }
      .add_to_cart.bottom .cart_media .cart_product {
        display: flex;
        width: 75%;
        overflow-y: auto; }
        .add_to_cart.bottom .cart_media .cart_product li {
          min-width: 245px;
          max-width: 225px;
          margin-right: 18px;
          padding-bottom: 0; }
      .add_to_cart.bottom .cart_media .cart_total {
        width: 25%; }
    .add_to_cart.bottom .cart-inner {
      height: 218px; }
      .add_to_cart.bottom .cart-inner .cart_top {
        margin-bottom: 0;
        background-color: #fbfbfb;
        padding: 15px 44px;
        border-bottom: none; }
      .add_to_cart.bottom .cart-inner .cart_media {
        padding: 0; }
        .add_to_cart.bottom .cart-inner .cart_media .cart_product {
          padding: 30px 44px; }
        .add_to_cart.bottom .cart-inner .cart_media .cart_total {
          padding: 30px 44px; }
    .add_to_cart.bottom.open-side {
      bottom: 0; }

/*=====================
   19. Animation section
=======================*/
.animation-section {
  position: relative; }
  .animation-section .decor-animation .decor1 {
    position: absolute;
    left: 5%;
    bottom: 14%;
    animation: myOrbit 9s linear infinite; }
  .animation-section .decor-animation .decor2 {
    position: absolute;
    right: 48%;
    bottom: 7%;
    animation: myOrbit 9s linear infinite; }
  .animation-section .decor-animation .decor3 {
    position: absolute;
    right: 5%;
    bottom: 36%;
    animation: round-jump 9s linear infinite; }
  .animation-section .decor-animation .decor4 {
    position: absolute;
    right: 5%;
    bottom: 25%;
    animation: star 7s linear infinite; }
  .animation-section .decor-animation .decor5 {
    position: absolute;
    left: 5%;
    bottom: 25%;
    animation: star 7s linear infinite; }
  .animation-section .decor-animation .decor6 {
    position: absolute;
    left: 5%;
    top: 25%;
    animation: star 7s linear infinite; }
  .animation-section .decor-animation .decor7 {
    position: absolute;
    right: 8%;
    top: 7%;
    animation: myOrbit 9s linear infinite; }
  .animation-section .decor-animation .decor8 {
    position: absolute;
    right: 5%;
    bottom: 10%;
    animation: star 7s linear infinite; }
  .animation-section .decor-animation .decor9 {
    position: absolute;
    bottom: 10%;
    animation: run 7s linear infinite; }
  .animation-section .decor-animation .decor10 {
    position: absolute;
    left: -10%;
    bottom: 10%;
    animation: movebounce 70s linear infinite; }
  .animation-section .decor-animation .decor11 {
    position: absolute;
    left: -10%;
    top: 0;
    animation: movebounce 70s linear infinite; }
  .animation-section .decor-animation .decor12 {
    position: absolute;
    left: 12%;
    bottom: 10%;
    animation: star 7s linear infinite; }

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg); }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg); }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg); }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); } }

@keyframes round-jump {
  0% {
    transform: translateY(-30px) translateX(15) rotate(0) scale(1.2);
    opacity: .7; }
  25% {
    transform: translateY(-20px) translateX(5px) rotate(50deg) scale(0.5);
    opacity: .8; }
  50% {
    transform: translateY(15px) translateX(-10px) rotate(20deg) scale(0.4);
    opacity: .9; }
  75% {
    transform: translateY(30px) translateX(10px) rotate(50deg) scale(0.65);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(10px) rotate(0) scale(0.4);
    opacity: .9; } }

@keyframes star {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.5); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes myOrbit {
  from {
    -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg); } }

@-webkit-keyframes movebounce {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes run {
  0% {
    right: 10%; }
  50% {
    right: 11%; }
  100% {
    right: 10%; } }

/*=====================
    20. Footer
======================*/
.footer {
  background-size: cover;
  background-color: #231511; }
  .footer p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #a79995; }
  .footer .common-decor .circel {
    border: 4px solid #31231d; }
  .footer .common-decor .plus:before, .footer .common-decor .plus:after {
    background: linear-gradient(to top, #31231d 0%, #31231d 100%); }
  .footer .portfolio {
    margin: -5px; }
    .footer .portfolio li {
      display: inline-block;
      margin: 5px;
      position: relative; }
      .footer .portfolio li:before {
        content: "";
        opacity: 0;
        transform: scale(3) rotate(90deg);
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(106, 64, 49, 0.4); }
      .footer .portfolio li img {
        height: 80px;
        width: auto; }
  .footer .contact-list i {
    position: absolute;
    left: 0;
    top: 5px; }
  .footer .contact-list li {
    position: relative;
    padding-left: 25px;
    color: #a79995;
    padding-bottom: 10px; }
  .footer .useful-link li {
    position: relative;
    transition: all 0.3s ease;
    padding-left: 15px; }
    .footer .useful-link li:before {
      content: "\f101";
      font-family: FontAwesome;
      position: absolute;
      left: 0;
      color: #a79995;
      transition: all 0.3s ease; }
    .footer .useful-link li:hover {
      padding-left: 20px;
      transition: all 0.3s ease; }
      .footer .useful-link li:hover:before {
        left: 5px;
        transition: all 0.3s ease; }
    .footer .useful-link li + li {
      padding-top: 5px; }
  .footer .social-icon li {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: rgba(178, 159, 133, 0.1);
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.3s ease; }
    .footer .social-icon li:hover {
      background: rgba(178, 159, 133, 0.6);
      transition: all 0.3s ease; }
      .footer .social-icon li:hover a {
        color: #ffffff; }
    .footer .social-icon li + li {
      margin-left: 5px; }
  .footer .footer-title {
    margin-top: -5px; }
    .footer .footer-title h5 {
      font-family: "Work Sans", sans-serif;
      color: #efe7dc;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 15px; }
  .footer .footer-content .mo-number {
    margin-bottom: 24px; }
  .footer .footer-content .jvector-map-height {
    height: 0; }
    .footer .footer-content .jvector-map-height .jvectormap-container {
      position: relative;
      height: 250px; }
      .footer .footer-content .jvector-map-height .jvectormap-container svg {
        position: absolute;
        top: -40px;
        height: 210px; }
  .footer .footer-content li {
    text-transform: capitalize;
    font-family: "Work Sans", sans-serif; }
    .footer .footer-content li a {
      color: #a79995;
      opacity: 0.8; }
  .footer .footer-content path {
    fill: #a68c7d; }

.copyright {
  padding: 20px;
  background-color: #1d120f; }
  .copyright h6 {
    font-weight: 500;
    opacity: 1;
    margin-top: -5px;
    margin-bottom: -2px;
    color: #a68c7d;
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320))); }

.prod-timer-three {
  padding: 20px 0 17px 0;
  display: inline-block;
  font-weight: 400; }
  .prod-timer-three .timer-dots {
    position: relative;
    top: -12px;
    font-weight: bold; }
  .prod-timer-three .timer li {
    display: inline-block;
    text-align: center;
    margin: 0 2px; }
    .prod-timer-three .timer li span {
      font-family: "Work Sans", sans-serif;
      color: #000000;
      display: block;
      font-size: 16px;
      line-height: 1; }
    .prod-timer-three .timer li .timer {
      font-family: "Prata", serif;
      color: #000000;
      display: inline-block;
      font-size: 12px;
      list-style-type: none;
      text-align: center;
      text-transform: capitalize; }

/*================
    21. Loader SCSS
=================*/
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  display: block;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0; }
  .loader-wrapper > div {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    float: none;
    background-color: #6a4031;
    border: 0 solid #6a4031;
    bottom: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    transform: translateY(0) translateX(-50%) rotate(45deg) scale(0);
    animation: fire-diamonds 1.5s infinite linear; }
    .loader-wrapper > div:nth-child(1) {
      animation-delay: -0.85s; }
    .loader-wrapper > div:nth-child(2) {
      animation-delay: -1.85s; }
    .loader-wrapper > div:nth-child(3) {
      animation-delay: -2.85s; }

@-webkit-keyframes fire-diamonds {
  0% {
    -webkit-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0); }
  50% {
    -webkit-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1); }
  100% {
    -webkit-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0); } }

@-moz-keyframes fire-diamonds {
  0% {
    -moz-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0); }
  50% {
    -moz-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1); }
  100% {
    -moz-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0); } }

@-o-keyframes fire-diamonds {
  0% {
    -o-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0); }
  50% {
    -o-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1); }
  100% {
    -o-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0); } }

@keyframes fire-diamonds {
  0% {
    -webkit-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    -moz-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    -o-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0); }
  50% {
    -webkit-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    -moz-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    -o-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1); }
  100% {
    -webkit-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    -moz-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    -o-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0); } }

/*=============
   22. Tap-To-Top
==============*/
.tap-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 8;
  display: none;
  color: #ffffff;
  text-align: center;
  background: #6a4031;
  border: none;
  font-size: 22px;
  padding: 8px;
  cursor: pointer; }

/*=====================
  23. Inner-page
==========================*/
.theme-breadcrumb {
  background: url(../images/Bread-Crumb/Bread-bg.jpg) scroll top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #717171; }
  .theme-breadcrumb h2 {
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 7px; }
  .theme-breadcrumb .breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: transparent;
    font-family: "Work Sans", sans-serif;
    margin-bottom: -5px;
    padding: 5px 0 0 0; }
    .theme-breadcrumb .breadcrumb > .breadcrumb-item + .breadcrumb-item:before {
      color: #ffffff; }
    .theme-breadcrumb .breadcrumb li,
    .theme-breadcrumb .breadcrumb a {
      color: #ffffff;
      text-transform: uppercase; }

.select_input select {
  padding: 15px 20px;
  margin-bottom: 30px; }

.template-password {
  background: url("../images/blog/bg-coming.png");
  background-size: cover;
  height: 100vh; }
  .template-password .contain {
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .template-password .contain > div {
      width: 100%; }
    .template-password .contain .login h2 {
      color: #6a4031;
      font-weight: 400; }
    .template-password .contain .login .theme-form h3 {
      color: #6a4031;
      font-size: 20px; }
    .template-password .contain .login .theme-form input[type="password"] {
      border-color: #c7ac85;
      font-size: 12px;
      margin-bottom: 15px;
      padding: 12px 30px;
      border-radius: 25px; }
    .template-password .contain .login .theme-form input:focus {
      box-shadow: none; }
    .template-password .contain .owner {
      color: #6a4031; }
      .template-password .contain .owner a {
        color: #c7ac85;
        transition: all 0.3s ease-in-out; }
        .template-password .contain .owner a:hover {
          color: #6a4031;
          transition: all 0.3s ease-in-out; }

.review-page .comment-section {
  border-bottom: none !important; }
  .review-page .comment-section li:first-child {
    border-top: none;
    padding-top: 0; }
  .review-page .comment-section li:last-child {
    padding-bottom: 0; }
  .review-page .comment-section li h6 {
    font-size: 14px; }
  .review-page .comment-section li p {
    font-size: 14px; }

.review-page .comnt-sec {
  margin-top: 10px;
  display: flex; }
  .review-page .comnt-sec li {
    margin-right: 15px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-top: none !important; }
    .review-page .comnt-sec li a {
      color: #6a4031;
      font-size: 16px; }
      .review-page .comnt-sec li a i {
        margin-right: 5px; }

.error-section {
  padding: 130px 0;
  text-align: center;
  background-image: url("../images/hair_color/bg.png");
  background-size: cover; }
  .error-section h1 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    color: #6a4031;
    margin-top: -11px; }
  .error-section h2 {
    color: #6a4031;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 40px 0; }

.breadcrumb-section {
  background-color: #f8f8f8;
  padding: 30px 0; }
  .breadcrumb-section .page-title {
    display: flex;
    height: 100%;
    align-items: center; }
    .breadcrumb-section .page-title h2 {
      font-size: 16px;
      margin-bottom: 0;
      text-transform: uppercase; }
  .breadcrumb-section .breadcrumb {
    background-color: transparent;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    color: #555555;
    font-weight: 600;
    margin: 0;
    padding-right: 0; }
    .breadcrumb-section .breadcrumb a {
      color: #555555;
      font-weight: 600; }

.about-head {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  height: 100%; }
  .about-head h2 {
    color: #6a4031;
    margin-bottom: 0; }

.about-page {
  text-align: center; }
  .about-page .banner-section {
    border-radius: 5px;
    overflow: hidden; }
  .about-page p {
    font-size: 16px;
    line-height: 1.6; }
  .about-page h5 {
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 2;
    color: #6a4031; }

.team {
  position: relative; }
  .team h3 {
    font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: #6a4031;
    border-bottom: 1px solid #f4efe7;
    padding-bottom: 5px; }
  .team img {
    margin: 0 auto;
    padding: 0 10px; }
  .team .slick-slide {
    border: 1px solid #f4efe7;
    padding: 10px;
    margin: 10px;
    border-radius: 10px; }
  .team .slick-prev,
  .team .slick-next {
    position: absolute;
    top: -45px; }
    .team .slick-prev:before,
    .team .slick-next:before {
      font: normal normal normal 30px/1 FontAwesome;
      color: #222222;
      opacity: 0.6; }
  .team .slick-prev {
    left: unset;
    right: 20px; }
    .team .slick-prev:before {
      content: "\f104"; }
  .team .slick-next {
    right: 0; }
    .team .slick-next:before {
      content: "\f105"; }
  .team h2 {
    padding-bottom: 15px;
    border-bottom: 1px solid #dddada;
    margin-bottom: 10px;
    color: #6a4031;
    line-height: 30px; }
  .team h4,
  .team h6 {
    text-align: center; }
  .team h4 {
    color: #6a4031;
    font-weight: 700;
    margin-top: 15px;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px; }
  .team h6 {
    margin-bottom: -2px; }

.collection-filter-block {
  margin-bottom: 30px;
  padding: 0 30px 25px 30px;
  border: 1px solid #c7ac85;
  background-color: #ffffff; }
  .collection-filter-block .product-service {
    padding: 30px 0; }
    .collection-filter-block .product-service .media {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #c7ac85; }
      .collection-filter-block .product-service .media svg {
        height: 40px;
        width: 40px; }
        .collection-filter-block .product-service .media svg path {
          fill: #6a4031; }
      .collection-filter-block .product-service .media .media-body {
        padding-left: 10px; }
        .collection-filter-block .product-service .media .media-body h4 {
          font-size: 18px;
          margin-bottom: 5px;
          color: #6a4031;
          font-family: "Work Sans", sans-serif; }
        .collection-filter-block .product-service .media .media-body p {
          font-size: 14px;
          margin-bottom: 0;
          text-transform: capitalize; }
      .collection-filter-block .product-service .media:last-child {
        padding-bottom: 0; }

.collection-collapse-block.open {
  margin-bottom: 12px; }

.collection-collapse-block .collapse-block-title {
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #6a4031;
  font-weight: 600;
  margin: 30px 0 0 0;
  font-family: "Work Sans", sans-serif; }
  .collection-collapse-block .collapse-block-title:after {
    position: absolute;
    display: block;
    top: 0;
    right: -3px;
    content: "\f0d7";
    font-family: FontAwesome;
    color: #6a4031; }

.collection-collapse-block .collection-collapse-block-content .color-selector {
  margin-top: 20px; }

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li {
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 18px; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a {
    padding-bottom: 10px;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    color: #33322d; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:last-child a {
    padding-bottom: 0; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
    margin-top: 30px; }

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
  margin-top: 15px; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:last-child {
    margin-bottom: 0; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    color: #33322d;
    line-height: 1; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:before {
      top: 1px;
      height: 15px;
      width: 15px;
      background: #ffffff;
      border: 1px solid #777777; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:after {
      top: 1px;
      height: 15px;
      width: 15px;
      background-size: 65%; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>"); }

.color-selector ul li {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #444444; }

.color-selector ul .color-1 {
  background-color: #ffffff; }

.color-selector ul .color-2 {
  background-color: #EAB543; }

.color-selector ul .color-3 {
  background-color: #6a4031; }

.color-selector ul .color-4 {
  background-color: #FD7272; }

.color-selector ul .color-5 {
  background-color: #82589F; }

.color-selector ul .color-6 {
  background-color: #c7ac85; }

.color-selector ul .color-7 {
  background-color: #d35400; }

.color-selector ul li.active {
  border: 1px solid black;
  position: relative; }
  .color-selector ul li.active:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
    top: 10px;
    right: 4px;
    height: 15px;
    width: 15px;
    background-size: 70%;
    background-repeat: no-repeat;
    position: absolute; }

.collection-sidebar-banner {
  margin-top: 30px; }
  .collection-sidebar-banner img {
    width: 100%; }

.top-banner-wrapper .top-banner-content h4 {
  font-size: 18px;
  font-weight: 600;
  color: #6a4031;
  margin-bottom: 15px; }

.top-banner-wrapper .top-banner-content h5 {
  font-size: 16px;
  font-weight: 600;
  color: #6a4031;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 10px;
  font-family: "Work Sans", sans-serif; }

.top-banner-wrapper .top-banner-content p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0; }

.collection-filter .theme-card {
  padding: 30px;
  border: 1px solid #c7ac85;
  background-color: white; }
  .collection-filter .theme-card h5.title-border {
    border-bottom: 1px solid #c7ac85;
    font-size: 18px;
    font-family: "Work Sans", sans-serif; }

.product-wrapper-grid.list-view .product_box {
  flex-direction: unset;
  display: flex;
  border: none; }
  .product-wrapper-grid.list-view .product_box .product_image {
    width: 50%; }
  .product-wrapper-grid.list-view .product_box .card-body {
    background-color: transparent;
    display: flex;
    align-items: center; }
    .product-wrapper-grid.list-view .product_box .card-body.product_contain {
      padding: 0 0 0 15px; }
      .product-wrapper-grid.list-view .product_box .card-body.product_contain h6,
      .product-wrapper-grid.list-view .product_box .card-body.product_contain h5,
      .product-wrapper-grid.list-view .product_box .card-body.product_contain p {
        text-align: left !important; }
      .product-wrapper-grid.list-view .product_box .card-body.product_contain h5 {
        font-size: 18px; }
      .product-wrapper-grid.list-view .product_box .card-body.product_contain h6 {
        padding-bottom: 5px; }
      .product-wrapper-grid.list-view .product_box .card-body.product_contain p {
        display: block !important;
        font-size: 14px;
        margin-bottom: 0; }

.collection-product-wrapper .product-top-filter {
  border-bottom: 1px solid #6a4031;
  border-top: 1px solid #6a4031; }
  .collection-product-wrapper .product-top-filter .product-filter-content,
  .collection-product-wrapper .product-top-filter .popup-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
    .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
    .collection-product-wrapper .product-top-filter .popup-filter .search-count,
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
      border-right: 1px solid #6a4031;
      padding: 20px;
      text-transform: capitalize;
      width: 35%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
      .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup h5,
      .collection-product-wrapper .product-top-filter .popup-filter .search-count h5,
      .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup h5 {
        font-size: 16px;
        margin-bottom: 0;
        font-family: "Work Sans", sans-serif;
        color: #6a4031; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
    .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
      padding: 20px;
      width: 10%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li,
      .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li {
        height: 100%;
        vertical-align: middle;
        cursor: pointer;
        color: #6a4031; }
        .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child,
        .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
          margin-right: 14px; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
    .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
      padding: 20px;
      width: 20%;
      border-right: 1px solid #6a4031; }
      .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul,
      .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul {
        display: flex;
        justify-content: flex-end; }
        .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li,
        .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li {
          margin-left: 10px; }
          .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li img,
          .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li img {
            height: 18px;
            cursor: pointer; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      position: relative;
      width: 20%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        border: 0;
        padding: 20px;
        border-right: 1px solid #6a4031;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        color: #6a4031;
        cursor: pointer;
        background: url(../images/dropdown.png) no-repeat 95%; }
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select:focus,
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select:focus,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select:focus,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select:focus {
          outline: unset;
          font-family: "Work Sans", sans-serif; }
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select option,
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select option,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select option,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select option {
          padding: 20px 0; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      width: 15%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        right: 10px; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        border-right: none; }
  .collection-product-wrapper .product-top-filter .popup-filter {
    justify-content: unset; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar {
      width: 3px; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-track {
      background: #dddddd; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb {
      background: #888888; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb:hover {
      background: #555555; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
      position: relative;
      cursor: pointer;
      width: 20%;
      background: url(../images/dropdown.png) no-repeat 95%; }
      .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
        font-size: 16px;
        color: #222222; }
      .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
        background-color: #f8f8f8;
        width: 270px;
        height: 600px;
        overflow-y: scroll; }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card h5.title-border {
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))); }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-filter-block {
          border: none; }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card {
          padding: 0 30px; }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner {
          padding: 0 30px; }
          .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner img {
            width: 100%; }
    .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
      display: none;
      position: absolute;
      z-index: 9;
      top: 75px;
      left: 0;
      border: 1px solid #f1f5f4;
      box-shadow: 0 0 5px #ddd;
      min-width: 330px; }
      .collection-product-wrapper .product-top-filter .popup-filter .open-popup.open {
        display: block; }
      .collection-product-wrapper .product-top-filter .popup-filter .open-popup .collection-filter-block {
        margin-bottom: 0; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      width: 20%; }
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        padding-right: 45px;
        font-size: 16px;
        font-family: "Work Sans", sans-serif; }
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        right: 35px; }
    .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
      padding: 20px; }
    .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
      width: 20%; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
      right: 25px !important; }

.collection-product-wrapper .product-wrapper-grid .product-box {
  margin-top: 50px; }
  .collection-product-wrapper .product-wrapper-grid .product-box .product-detail p {
    display: none;
    color: red; }
  .collection-product-wrapper .product-wrapper-grid .product-box .product-detail h4 {
    font-size: 18px; }

.collection-product-wrapper .product-wrapper-grid .product-five {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center; }
  .collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h6 {
    color: #525252;
    padding-top: 0;
    margin-top: -2px; }
  .collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h4 {
    font-weight: 400;
    color: #000000; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1; }
  .collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .cart-wrap button {
    border: 1px solid #ffffff;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
    .collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .cart-wrap button i {
      padding: 7px;
      color: #333333; }
  .collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .cart-wrap a i {
    border-radius: 100%;
    border: 1px solid #ffffff;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 7px;
    color: #333333;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box:hover .cart-wrap button {
  animation: none; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box:hover .cart-wrap a i {
  opacity: 1; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInDown 500ms ease-in-out; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInDown 700ms ease-in-out; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInDown 1000ms ease-in-out; }

.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer; }
  .filter-main-btn span {
    font-size: 20px;
    background-color: #6a4031;
    padding: 5px 20px;
    color: #ffffff;
    border-radius: 10px; }
    .filter-main-btn span:hover {
      color: #ffffff !important; }

.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid #c7ac85;
  display: none; }
  .collection-mobile-back span {
    text-transform: uppercase;
    font-size: 14px;
    color: #6a4031;
    cursor: pointer; }
    .collection-mobile-back span i {
      font-size: 20px;
      font-weight: bold;
      margin-right: 5px; }

.product-pagination {
  border-bottom: 1px solid #6a4031;
  border-top: 1px solid #6a4031;
  margin: 50px 0; }
  .product-pagination .pagination {
    border-radius: 0; }
    .product-pagination .pagination .page-item {
      border-radius: 0; }
      .product-pagination .pagination .page-item a {
        padding: 18px;
        border-bottom: 0;
        border-top: 0;
        color: #6a4031;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-color: #6a4031;
        border-right-color: #6a4031; }
        .product-pagination .pagination .page-item a:focus {
          box-shadow: none;
          background-color: #e3d8ca; }
      .product-pagination .pagination .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .product-pagination .pagination .page-item .page-link:hover {
        background-color: #e3d8ca; }
    .product-pagination .pagination .page-item.active a {
      color: #6a4031;
      background-color: #ffffff;
      border-color: #6a4031; }
  .product-pagination .product-search-count-bottom {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid #6a4031;
    border-right: 1px solid #6a4031;
    padding-right: 15px; }
    .product-pagination .product-search-count-bottom h5 {
      color: #6a4031;
      font-size: 14px;
      margin-bottom: 0;
      padding: 5px 0;
      font-family: "Work Sans", sans-serif; }

.bundle {
  margin-top: 10px; }
  .bundle .bundle_img {
    display: flex; }
    .bundle .bundle_img .img-box img {
      max-width: 115px;
      border: 1px solid #ddd;
      padding: 2px; }
    .bundle .bundle_img .plus {
      display: flex;
      align-items: center;
      padding: 0 7px;
      font-size: 22px; }
  .bundle .bundle_detail {
    margin-top: 15px; }
    .bundle .bundle_detail .price_product {
      color: #000000;
      padding-left: 3px;
      font-weight: 700; }

.theme_checkbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  text-transform: capitalize;
  color: #6a4031; }
  .theme_checkbox label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .theme_checkbox label input:checked ~ .checkmark:after {
      display: block; }
  .theme_checkbox label .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #dddddd; }
    .theme_checkbox label .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }

.collection .collection-block {
  text-align: center; }
  .collection .collection-block img,
  .collection .collection-block .bg-size {
    margin-bottom: 20px; }
  .collection .collection-block .collection-content {
    text-align: center; }
    .collection .collection-block .collection-content h3 {
      margin-bottom: 10px;
      color: #6a4031;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 0.05em; }
    .collection .collection-block .collection-content h4 {
      color: #6a4031;
      margin-bottom: 10px;
      font-size: 16px; }
    .collection .collection-block .collection-content p {
      line-height: 1.5; }
    .collection .collection-block .collection-content .btn-solid,
    .collection .collection-block .collection-content .btn-outline {
      padding: 10px 25px; }

.faq-section .accordion.theme-accordion .accordion-item {
  margin-top: 10px;
  border-radius: 0;
  overflow: hidden;
  border-bottom: 1px solid #f6f0ea;
  border-color: #f6f0ea; }
  .faq-section .accordion.theme-accordion .accordion-item :not(:first-of-type):not(:last-of-type),
  .faq-section .accordion.theme-accordion .accordion-item .theme-accordion .card:first-of-type {
    border-bottom: 1px solid #f6f0ea; }
  .faq-section .accordion.theme-accordion .accordion-item:first-child {
    margin-top: 0; }
  .faq-section .accordion.theme-accordion .accordion-item .accordion-header {
    padding: 0;
    border-bottom: 0;
    transition: all 0.3s ease;
    background-color: #f6f0ea; }
    .faq-section .accordion.theme-accordion .accordion-item .accordion-header button {
      color: #6a4031;
      text-align: left;
      font-size: 17px;
      padding: 18px;
      font-weight: 600;
      width: 100%;
      letter-spacing: 1px;
      text-decoration: none;
      background-color: #f6f0ea; }
      .faq-section .accordion.theme-accordion .accordion-item .accordion-header button.accordion-button {
        box-shadow: none;
        border-color: 1px solid #f6f0ea; }
        .faq-section .accordion.theme-accordion .accordion-item .accordion-header button.accordion-button:focus {
          box-shadow: none;
          border-color: 1px solid #f6f0ea !important; }
      .faq-section .accordion.theme-accordion .accordion-item .accordion-header button:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 6px solid #6a4031;
        right: 20px;
        top: 25px;
        transition: all 0.3s ease; }
      .faq-section .accordion.theme-accordion .accordion-item .accordion-header button[aria-expanded="true"]:before {
        border-top: 0;
        border-bottom: 6px solid #6a4031;
        transition: all 0.3s ease; }
      .faq-section .accordion.theme-accordion .accordion-item .accordion-header button:hover {
        text-decoration: none;
        color: #6a4031;
        transition: all 0.3s ease; }
  .faq-section .accordion.theme-accordion .accordion-item .accordion-body {
    padding: 15px 18px; }
    .faq-section .accordion.theme-accordion .accordion-item .accordion-body p {
      margin-bottom: 0;
      font-size: 14px;
      color: #6a4031;
      line-height: 1.8; }

.pwd-page {
  text-align: center; }
  .pwd-page h2 {
    margin-bottom: 25px;
    color: #6a4031;
    margin-top: -5px;
    font-family: "Work Sans", sans-serif;
    line-height: -28px; }
  .pwd-page .theme-form input {
    border-color: #c7ac85;
    font-size: 15px;
    padding: 15px 20px;
    margin-bottom: 15px;
    height: inherit;
    text-align: center;
    font-family: "Work Sans", sans-serif;
    border-radius: 100px; }
  .pwd-page .theme-form ::placeholder {
    color: #6a4031; }
  .pwd-page .theme-form .btn-solid,
  .pwd-page .theme-form .btn-outline {
    margin: 0 auto; }

.login-page h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #6a4031;
  font-weight: 700;
  margin-top: -5px; }

.login-page .theme-card {
  padding: 30px;
  border: 1px solid #6a4031;
  line-height: 1; }
  .login-page .theme-card .theme-form label {
    text-transform: capitalize;
    color: #6a4031;
    font-size: 14px;
    font-weight: 600;
    font-family: "Work Sans", sans-serif; }
  .login-page .theme-card .theme-form ::placeholder {
    color: #6a4031; }
  .login-page .theme-card .theme-form input {
    border-color: #c7ac85;
    font-size: 12px;
    padding: 17px 25px;
    margin-bottom: 30px;
    height: inherit;
    font-family: "Work Sans", sans-serif; }

.login-page .authentication-right {
  height: calc(100% - 48px); }
  .login-page .authentication-right h6 {
    font-size: 14px;
    text-transform: uppercase;
    color: #6a4031;
    font-weight: 600;
    margin-bottom: 20px; }
  .login-page .authentication-right p {
    font-size: 14px;
    color: #6a4031;
    line-height: 2;
    margin-bottom: 1rem; }

.auth-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #6a4031;
  font-weight: 700;
  margin-top: -5px;
  font-size: 24px; }

.auth-page .theme-card {
  padding: 30px;
  border: 1px solid #f1f1f1;
  line-height: 1;
  border-radius: 25px;
  background-color: white; }
  .auth-page .theme-card .theme-form .form-row {
    display: flex; }
    .auth-page .theme-card .theme-form .form-row > div:nth-child(n+2) {
      margin-left: 10px; }
  .auth-page .theme-card .theme-form label {
    text-transform: capitalize;
    color: #6a4031;
    font-size: 14px;
    font-weight: 600;
    font-family: "Work Sans", sans-serif;
    margin-bottom: 10px; }
  .auth-page .theme-card .theme-form input, .auth-page .theme-card .theme-form select, .auth-page .theme-card .theme-form textarea {
    border-color: #c7ac85;
    font-size: 15px;
    padding: 12px 30px;
    margin-bottom: 30px;
    height: inherit;
    border-radius: 25px;
    font-family: "Work Sans", sans-serif;
    color: #6a4031; }
  .auth-page .theme-card .theme-form ::placeholder {
    color: #6a4031; }

.search-block {
  padding: 0;
  background-image: url("../images/hair_color/bg.png");
  background-size: cover; }
  .search-block .form-header .input-group input {
    border-radius: 100px 5px 5px 100px;
    padding-left: 30px; }
  .search-block .form-header .input-group i {
    padding-right: 10px; }
  .search-block .form-header .input-group .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 0; }
    .search-block .form-header .input-group .btn.btn_dark:after {
      background: #ffffff;
      content: "";
      height: 155px;
      left: -90px;
      opacity: 0.2;
      position: absolute;
      top: -30px;
      transform: rotate(35deg);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 50px;
      z-index: 1; }
    .search-block .form-header .input-group .btn.btn_dark:hover:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }
  .search-block .form-header .input-group .input-group-text {
    padding: 0;
    background-color: #ffffff; }

.contact-page .map iframe {
  width: 100%;
  height: 100%;
  border: none; }

.contact-page .contact-right {
  padding-left: 10px; }
  .contact-page .contact-right ul li {
    position: relative;
    padding-left: 150px;
    margin-top: 30px;
    display: block; }
    .contact-page .contact-right ul li:first-child {
      margin-top: 0; }
    .contact-page .contact-right ul li .contact-icon {
      position: absolute;
      left: 0;
      text-align: center;
      width: 130px;
      border-right: 1px solid #dddddd; }
      .contact-page .contact-right ul li .contact-icon i {
        font-size: 25px;
        color: #6a4031; }
      .contact-page .contact-right ul li .contact-icon h6 {
        text-transform: uppercase;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 600; }
    .contact-page .contact-right ul li p {
      margin-bottom: 0;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))); }

.contact-page .theme-card .theme-form .form-row {
  display: block; }
  .contact-page .theme-card .theme-form .form-row > div:nth-child(n+2) {
    margin-left: unset; }

.qty-box .input-group {
  justify-content: center; }
  .qty-box .input-group span {
    color: #6a4031; }
    .qty-box .input-group span button {
      background: #ffffff;
      border: 1px solid #c7ac85;
      height: 100%; }
  .qty-box .input-group .form-control {
    text-align: center;
    width: 80px;
    flex: unset;
    border-color: #c7ac85;
    color: #6a4031; }
  .qty-box .input-group button {
    background-color: transparent;
    border: 0;
    color: #6a4031;
    cursor: pointer;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 900;
    line-height: 1; }
    .qty-box .input-group button i {
      font-weight: 900;
      color: #6a4031; }
  .qty-box .input-group .icon {
    padding-right: 0; }

.cart-section .table,
.wishlist-section .table {
  color: #6a4031; }
  .cart-section .table tbody + tbody,
  .wishlist-section .table tbody + tbody {
    border-top: none; }

.cart-section .cart-buttons,
.wishlist-section .cart-buttons {
  padding-top: 35px; }
  .cart-section .cart-buttons > div:last-child,
  .wishlist-section .cart-buttons > div:last-child {
    text-align: right;
    padding-right: 59px; }

.cart-section .wishlist-buttons,
.wishlist-section .wishlist-buttons {
  padding-top: 35px;
  text-align: right; }
  .cart-section .wishlist-buttons:last-child a,
  .wishlist-section .wishlist-buttons:last-child a {
    margin-left: 15px; }

.cart-section .cart-table,
.wishlist-section .cart-table {
  overflow: hidden;
  margin-bottom: 0; }
  .cart-section .cart-table thead th,
  .wishlist-section .cart-table thead th {
    border-bottom: 1px solid rgba(106, 64, 49, 0.2);
    font-weight: 900;
    color: #6a4031;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 0;
    text-align: center;
    padding: 0 0.75rem 0.75rem 0.75rem;
    line-height: 14px; }

.cart-section tbody h5,
.wishlist-section tbody h5 {
  margin-bottom: 0; }

.cart-section tbody tr td,
.wishlist-section tbody tr td {
  min-width: 210px;
  vertical-align: middle;
  color: #6a4031;
  border-top: 0;
  border-bottom: 1px solid rgba(106, 64, 49, 0.2);
  text-align: center; }
  .cart-section tbody tr td a,
  .cart-section tbody tr td p,
  .wishlist-section tbody tr td a,
  .wishlist-section tbody tr td p {
    color: #33322d;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0; }
    .cart-section tbody tr td a img,
    .cart-section tbody tr td p img,
    .wishlist-section tbody tr td a img,
    .wishlist-section tbody tr td p img {
      height: 90px; }
  .cart-section tbody tr td .td-color,
  .wishlist-section tbody tr td .td-color {
    color: #6a4031; }
  .cart-section tbody tr td h2,
  .wishlist-section tbody tr td h2 {
    font-size: 24px;
    color: #222222;
    font-weight: 400; }
  .cart-section tbody tr td .mobile-cart-content,
  .wishlist-section tbody tr td .mobile-cart-content {
    display: none;
    justify-content: center;
    margin-top: 10px; }
    .cart-section tbody tr td .mobile-cart-content .qty-box,
    .wishlist-section tbody tr td .mobile-cart-content .qty-box {
      border-radius: 5px; }
      .cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control,
      .wishlist-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
        width: 48px;
        padding: 6px; }
    .cart-section tbody tr td .mobile-cart-content .col-xs-3,
    .wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
      align-self: center;
      margin-left: 10px;
      margin-right: 10px; }
    .cart-section tbody tr td .mobile-cart-content h2,
    .wishlist-section tbody tr td .mobile-cart-content h2 {
      font-size: 20px; }

.cart-section tfoot tr th,
.wishlist-section tfoot tr th {
  padding-top: 35px;
  text-align: right; }

.cart-section tfoot tr td,
.wishlist-section tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px; }
  .cart-section tfoot tr td h2,
  .wishlist-section tfoot tr td h2 {
    font-size: 24px;
    margin-bottom: 0; }
  .cart-section tfoot tr td:first-child,
  .wishlist-section tfoot tr td:first-child {
    width: 85%;
    font-size: 15px;
    padding-right: 10px;
    text-transform: capitalize;
    font-weight: 700; }

.blog-detail-page .blog-detail p:last-child {
  margin-bottom: 0; }

.blog-detail-page .blog-detail img {
  margin-bottom: 40px; }

.blog-detail-page .blog-detail h3 {
  font-size: 24px;
  color: #6a4031;
  font-weight: 600;
  margin-bottom: 20px; }

.blog-detail-page .blog-detail p {
  font-size: 14px;
  line-height: 1.8; }

.blog-detail-page .blog-detail .post-social {
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid #f4efe7;
  margin-bottom: 15px;
  display: inline-flex;
  width: 100%;
  font-family: "Work Sans", sans-serif; }
  .blog-detail-page .blog-detail .post-social li + li {
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid #6a4031; }

.blog-detail-page .blog-advance > div:last-child p {
  font-size: 14px; }
  .blog-detail-page .blog-advance > div:last-child p:last-child {
    margin-bottom: 0; }

.blog-detail-page .blog-advance img,
.blog-detail-page .blog-advance .bg-size {
  margin-bottom: 25px;
  margin-top: 25px;
  border: 1px solid #f1f1f1; }

.blog-detail-page .blog-advance ul {
  list-style-type: decimal;
  list-style-position: inside;
  line-height: 2.5;
  font-size: 15px; }
  .blog-detail-page .blog-advance ul li {
    display: list-item;
    font-weight: 700;
    font-family: "Work Sans", sans-serif;
    color: #6a4031; }

.blog-detail-page .blog-advance p {
  line-height: 1.8;
  font-family: "Work Sans", sans-serif; }

.blog-detail-page .comment-section {
  border-bottom: 1px solid #f4efe7; }
  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #f4efe7; }
    .blog-detail-page .comment-section li img {
      height: 65px;
      width: 65px;
      margin-right: 20px;
      border-radius: 100%; }
    .blog-detail-page .comment-section li h6 {
      font-size: 16px;
      color: #6a4031;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 5px; }
      .blog-detail-page .comment-section li h6 span {
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        color: #6a4031;
        margin-left: 20px; }
    .blog-detail-page .comment-section li p {
      font-size: 14px;
      line-height: 1.8;
      margin-bottom: 0; }

.blog-detail-page .blog-contact h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 36px;
  margin-bottom: 30px;
  margin-top: -5px; }

.blog-page .blog-sidebar h6 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
  color: #6a4031;
  font-weight: 700;
  font-family: "Work Sans", sans-serif; }

.blog-page .blog-sidebar p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0; }

.blog-page .blog-sidebar h4 {
  font-size: 18px;
  color: #6a4031;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px; }

.blog-page .blog-sidebar .theme-card {
  border: 1px solid #e7dccb;
  padding: 30px;
  background-color: #ffffff; }
  .blog-page .blog-sidebar .theme-card .recent-blog li .media img {
    width: 100px;
    margin-right: 10px; }
  .blog-page .blog-sidebar .theme-card .recent-blog li + li {
    margin-top: 30px; }
  .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
    background-color: #6a4031;
    height: 70px;
    width: 70px;
    margin: 0 15px 0 0;
    padding: 15px; }
    .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date span {
      display: block;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase; }
  .blog-page .blog-sidebar .theme-card .popular-blog li + li {
    margin-top: 30px; }
  .blog-page .blog-sidebar .theme-card .popular-blog li p {
    margin-top: 10px; }
  .blog-page .blog-sidebar .theme-card + .theme-card {
    margin-top: 30px; }

.blog-page .blog-media {
  margin-bottom: 30px; }
  .blog-page .blog-media:last-child {
    margin-bottom: 0; }
  .blog-page .blog-media .blog-right {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex; }
    .blog-page .blog-media .blog-right h6 {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .blog-page .blog-media .blog-right h4 {
      font-size: 18px;
      color: #6a4031;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 15px; }
    .blog-page .blog-media .blog-right h5 {
      color: #6a4031; }
    .blog-page .blog-media .blog-right p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 0; }
    .blog-page .blog-media .blog-right ul {
      margin-bottom: 15px; }
      .blog-page .blog-media .blog-right ul li {
        font-size: 14px;
        line-height: 1.3;
        display: inline-block; }
        .blog-page .blog-media .blog-right ul li i {
          padding-right: 3px; }
        .blog-page .blog-media .blog-right ul li + li {
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px solid #dddddd; }

.modal {
  padding-right: 0 !important; }

.product-slick .slick-prev,
.product-slick .slick-next,
.rtl-product-slick .slick-prev,
.rtl-product-slick .slick-next,
.product-right-slick .slick-prev,
.product-right-slick .slick-next,
.rtl-product-right-slick .slick-prev,
.rtl-product-right-slick .slick-next {
  opacity: 0;
  transform: scale(1);
  transition: all 0.5s ease;
  margin-top: -10px; }
  .product-slick .slick-prev:before,
  .product-slick .slick-next:before,
  .rtl-product-slick .slick-prev:before,
  .rtl-product-slick .slick-next:before,
  .product-right-slick .slick-prev:before,
  .product-right-slick .slick-next:before,
  .rtl-product-right-slick .slick-prev:before,
  .rtl-product-right-slick .slick-next:before {
    font: normal normal normal 14px/1 FontAwesome;
    opacity: 1;
    color: black;
    background-color: white;
    border-radius: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.product-slick .slick-next,
.rtl-product-slick .slick-next,
.product-right-slick .slick-next,
.rtl-product-right-slick .slick-next {
  right: 1px; }
  .product-slick .slick-next:before,
  .rtl-product-slick .slick-next:before,
  .product-right-slick .slick-next:before,
  .rtl-product-right-slick .slick-next:before {
    content: "\f105"; }

.product-slick .slick-prev,
.rtl-product-slick .slick-prev,
.product-right-slick .slick-prev,
.rtl-product-right-slick .slick-prev {
  left: 1px;
  z-index: 1; }
  .product-slick .slick-prev:before,
  .rtl-product-slick .slick-prev:before,
  .product-right-slick .slick-prev:before,
  .rtl-product-right-slick .slick-prev:before {
    content: "\f104"; }

.product-slick .slick-slide > div,
.rtl-product-slick .slick-slide > div,
.product-right-slick .slick-slide > div,
.rtl-product-right-slick .slick-slide > div {
  display: flex;
  margin-bottom: 15px; }

.product-slick:hover .slick-prev,
.product-slick:hover .slick-next,
.rtl-product-slick:hover .slick-prev,
.rtl-product-slick:hover .slick-next,
.product-right-slick:hover .slick-prev,
.product-right-slick:hover .slick-next,
.rtl-product-right-slick:hover .slick-prev,
.rtl-product-right-slick:hover .slick-next {
  opacity: 1;
  transform: scale(1.05);
  transition: all 0.5s ease; }
  .product-slick:hover .slick-prev:before,
  .product-slick:hover .slick-next:before,
  .rtl-product-slick:hover .slick-prev:before,
  .rtl-product-slick:hover .slick-next:before,
  .product-right-slick:hover .slick-prev:before,
  .product-right-slick:hover .slick-next:before,
  .rtl-product-right-slick:hover .slick-prev:before,
  .rtl-product-right-slick:hover .slick-next:before {
    opacity: 1; }

.product-slick:hover .slick-next,
.rtl-product-slick:hover .slick-next,
.product-right-slick:hover .slick-next,
.rtl-product-right-slick:hover .slick-next {
  right: 20px; }

.product-slick:hover .slick-prev,
.rtl-product-slick:hover .slick-prev,
.product-right-slick:hover .slick-prev,
.rtl-product-right-slick:hover .slick-prev {
  left: 20px; }

.slider-nav .slick-slide > div,
.rtl-slider-nav .slick-slide > div,
.slider-right-nav .slick-slide > div,
.rtl-slider-right-nav .slick-slide > div {
  display: flex;
  margin: 15px 15px 0 15px;
  border: 1px solid #dddddd;
  transition: all 0.5s ease; }

.slider-nav .slick-slide.slick-current > div,
.rtl-slider-nav .slick-slide.slick-current > div,
.slider-right-nav .slick-slide.slick-current > div,
.rtl-slider-right-nav .slick-slide.slick-current > div {
  border-color: #aaaaaa;
  transition: all 0.5s ease; }

.slider-nav .slick-slide.slick-active,
.rtl-slider-nav .slick-slide.slick-active,
.slider-right-nav .slick-slide.slick-active,
.rtl-slider-right-nav .slick-slide.slick-active {
  transition: all 0.5s ease; }

.slider-right-nav .slick-slide:first-child > div,
.rtl-slider-right-nav .slick-slide:first-child > div {
  margin-top: 0; }

.pro_sticky_info {
  border: 1px solid #dddddd;
  padding: 20px; }

.is_stuck {
  margin-top: 80px; }

.image-scroll div > div {
  padding-bottom: 20px; }
  .image-scroll div > div:last-child {
    padding: 0; }

.product-right .btn_dark {
  padding: 10px 25px; }

.product-right p {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.5em; }

.product-right .product-title {
  color: #6a4031;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px; }
  .product-right .product-title a {
    color: #6a4031; }

.product-right .border-product {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #c7ac85; }

.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em;
  font-weight: 700;
  color: #6a4031; }

.product-right h3 {
  font-size: 26px;
  margin-bottom: 15px;
  color: #6a4031; }

.product-right h4 {
  font-size: 16px;
  margin-bottom: 7px;
  color: #6a4031; }
  .product-right h4 del {
    color: #6a4031;
    font-size: 14px; }
  .product-right h4 span {
    padding-left: 5px;
    color: #6a4031; }

.product-right .color-variant {
  margin-bottom: 10px; }
  .product-right .color-variant li {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: inline-block;
    position: relative; }
    .product-right .color-variant li.active {
      border: 1px solid #6a4031; }
      .product-right .color-variant li.active:before {
        content: "\f00c";
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 80%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        font-family: FontAwesome; }

.product-right .product-buttons {
  margin-bottom: 20px;
  display: flex; }
  .product-right .product-buttons .btn-solid,
  .product-right .product-buttons .btn-outline {
    padding: 7px 25px; }
  .product-right .product-buttons a:last-child {
    margin-left: 10px; }

.product-right .product-description h6 span {
  float: right; }

.product-right .product-description .qty-box {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .product-right .product-description .qty-box .input-group {
    justify-content: unset;
    width: unset; }
    .product-right .product-description .qty-box .input-group .form-control {
      border-right: none;
      font-weight: 500; }
      .product-right .product-description .qty-box .input-group .form-control:focus {
        box-shadow: none; }

.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px; }
  .product-right .size-box ul li {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #f7f7f7;
    text-align: center;
    display: inline-block; }
    .product-right .size-box ul li a {
      color: #6a4031;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
    .product-right .size-box ul li.active {
      background-color: rgba(238, 228, 216, 0.4); }

.product-right .product-icon {
  display: flex; }
  .product-right .product-icon .product-social {
    margin-top: 5px; }
    .product-right .product-icon .product-social li {
      padding-right: 30px; }
      .product-right .product-icon .product-social li a {
        color: #6a4031;
        transition: all 0.3s ease; }
        .product-right .product-icon .product-social li a i {
          font-size: 18px; }
        .product-right .product-icon .product-social li a:hover {
          color: #6a4031; }
      .product-right .product-icon .product-social li:last-child {
        padding-right: 0; }
  .product-right .product-icon .wishlist-btn {
    background-color: transparent;
    border: none;
    color: #6a4031;
    font-family: "Prata", serif; }
    .product-right .product-icon .wishlist-btn i {
      border-left: 1px solid #6a4031;
      font-size: 18px;
      padding-left: 10px;
      margin-left: 5px;
      transition: all 0.5s ease;
      color: #6a4031; }
    .product-right .product-icon .wishlist-btn span {
      padding-left: 10px;
      font-size: 18px; }
    .product-right .product-icon .wishlist-btn:hover i {
      color: #6a4031;
      transition: all 0.5s ease; }

.product-right .payment-card-bottom {
  margin-top: 10px; }
  .product-right .payment-card-bottom ul li {
    padding-right: 10px; }

.product-right .timer {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  margin-top: 10px;
  background-color: rgba(238, 228, 216, 0.4);
  display: inline-block; }
  .product-right .timer p {
    font-size: 18px;
    color: #000000;
    margin-bottom: 0; }
  .product-right .timer span {
    width: 65px;
    display: inline-block;
    color: #6a4031; }
    .product-right .timer span .timer-cal {
      font-size: 12px;
      color: #6a4031; }
    .product-right .timer span .padding-l {
      padding-left: 22px;
      display: inline; }

.product-right.product-form-box {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 20px; }
  .product-right.product-form-box .product-description .qty-box {
    margin-bottom: 5px; }
    .product-right.product-form-box .product-description .qty-box .input-group {
      justify-content: center;
      width: 100%; }
  .product-right.product-form-box .product-buttons {
    margin-bottom: 0; }
  .product-right.product-form-box .timer {
    margin-bottom: 10px;
    text-align: left; }

.single-product-tables {
  display: flex;
  margin-top: 20px;
  color: #6a4031; }
  .single-product-tables table {
    width: 20%; }
    .single-product-tables table tr {
      height: 35px; }
      .single-product-tables table tr td:first-child {
        font-weight: 600; }
  .single-product-tables.detail-section {
    margin-top: 0; }
    .single-product-tables.detail-section table {
      width: 55%; }

.product-accordion .btn-link {
  color: #222222; }
  .product-accordion .btn-link:hover {
    text-decoration: none; }

.product-accordion .card {
  border: none; }

.product-accordion .card-body {
  width: 100%;
  height: 300px; }

.product-accordion .card-header {
  padding: 5px 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
  background-color: rgba(127, 127, 127, 0.03); }

.product-related h2 {
  color: #6a4031;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 20px;
  font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
  margin-top: 20px; }

.rating {
  margin-top: 0; }
  .rating i {
    padding-right: 5px; }
    .rating i:nth-child(-n + 4) {
      color: #6a4031; }
    .rating i:last-child {
      color: #dddddd; }
  .rating .three-star {
    padding-bottom: 5px; }
    .rating .three-star i {
      color: #acacac; }
      .rating .three-star i:nth-child(-n + 3) {
        color: #6a4031; }

.tab-border {
  border: 1px solid #dddddd;
  margin: 0;
  padding: 8px 0; }
  .tab-border .nav-border {
    border-bottom: 0;
    border-right: 1px solid #dddddd; }

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  padding: 15px 20px;
  color: #6a4031; }

.tab-product,
.product-full-tab {
  padding-top: 30px; }
  .tab-product .nav-material.nav-tabs,
  .product-full-tab .nav-material.nav-tabs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #c7ac85; }
    .tab-product .nav-material.nav-tabs .nav-item,
    .product-full-tab .nav-material.nav-tabs .nav-item {
      font-size: 14px; }
      .tab-product .nav-material.nav-tabs .nav-item .nav-link,
      .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
        color: #212121;
        text-align: center;
        padding: 0 15px 20px 15px;
        text-transform: uppercase;
        border: 0; }
      .tab-product .nav-material.nav-tabs .nav-item .material-border,
      .product-full-tab .nav-material.nav-tabs .nav-item .material-border {
        border-bottom: 2px solid #6a4031;
        opacity: 0; }
    .tab-product .nav-material.nav-tabs .nav-link.active,
    .product-full-tab .nav-material.nav-tabs .nav-link.active {
      color: #6a4031; }
      .tab-product .nav-material.nav-tabs .nav-link.active ~ .material-border,
      .product-full-tab .nav-material.nav-tabs .nav-link.active ~ .material-border {
        transition: all 0.3s ease;
        opacity: 1; }
  .tab-product .theme-form input,
  .product-full-tab .theme-form input {
    border-color: #c7ac85;
    font-size: 15px;
    padding: 15px 25px;
    margin-bottom: 15px;
    height: inherit;
    text-align: left;
    color: #6a4031; }
  .tab-product .theme-form label,
  .product-full-tab .theme-form label {
    border-color: #c7ac85;
    color: #6a4031;
    font-family: "Prata", serif; }
  .tab-product .theme-form ::placeholder,
  .product-full-tab .theme-form ::placeholder {
    color: #6a4031;
    font-family: "Work Sans", sans-serif; }
  .tab-product .theme-form textarea,
  .product-full-tab .theme-form textarea {
    border-color: #c7ac85;
    font-size: 15px;
    padding: 17px 25px;
    margin-bottom: 15px;
    height: inherit;
    color: #6a4031;
    font-family: "Work Sans", sans-serif; }
  .tab-product .tab-content.nav-material p,
  .product-full-tab .tab-content.nav-material p {
    font-size: 14px;
    padding: 20px 20px 0;
    margin-bottom: -8px;
    line-height: 2;
    letter-spacing: 0.05em; }
  .tab-product .tab-content.nav-material .media,
  .product-full-tab .tab-content.nav-material .media {
    margin-top: 20px; }
  .tab-product .title,
  .product-full-tab .title {
    padding-right: 45px;
    color: #6a4031;
    padding-bottom: 20px; }
  .tab-product .theme-slider .slick-arrow,
  .product-full-tab .theme-slider .slick-arrow {
    top: -45px;
    height: auto; }
    .tab-product .theme-slider .slick-arrow :before,
    .product-full-tab .theme-slider .slick-arrow :before {
      color: #000000;
      font-size: 18px; }
  .tab-product .product-box,
  .product-full-tab .product-box {
    position: relative;
    margin: 5px; }
    .tab-product .product-box:hover,
    .product-full-tab .product-box:hover {
      box-shadow: 0 0 12px 0 #dddddd; }
      .tab-product .product-box:hover .lbl-1,
      .product-full-tab .product-box:hover .lbl-1 {
        opacity: 1;
        transition: all 0.3s ease; }
      .tab-product .product-box:hover .lbl-2,
      .product-full-tab .product-box:hover .lbl-2 {
        opacity: 1;
        animation: flipInY 1000ms ease-in-out; }
      .tab-product .product-box:hover .color-variant li,
      .product-full-tab .product-box:hover .color-variant li {
        opacity: 1 !important;
        animation: fadeInUp 500ms ease-in-out; }
    .tab-product .product-box .img-block,
    .product-full-tab .product-box .img-block {
      min-height: unset; }
    .tab-product .product-box .cart-info,
    .product-full-tab .product-box .cart-info {
      position: absolute;
      padding: 10px 0;
      top: 25%;
      right: 15px;
      width: 40px;
      margin-right: 0; }
      .tab-product .product-box .cart-info i,
      .product-full-tab .product-box .cart-info i {
        padding-right: 0; }
      .tab-product .product-box .cart-info a,
      .tab-product .product-box .cart-info button,
      .product-full-tab .product-box .cart-info a,
      .product-full-tab .product-box .cart-info button {
        color: #333333;
        transition: all 0.3s ease;
        background-color: #ffffff;
        height: 35px;
        width: 35px;
        margin: 7px 0;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: 0 0 12px 0 #dddddd; }
        .tab-product .product-box .cart-info a :hover,
        .tab-product .product-box .cart-info button :hover,
        .product-full-tab .product-box .cart-info a :hover,
        .product-full-tab .product-box .cart-info button :hover {
          transition: all 0.3s ease;
          color: #6a4031; }
    .tab-product .product-box .lbl-1,
    .product-full-tab .product-box .lbl-1 {
      background-color: #6a4031;
      padding: 2px 20px 2px 10px;
      display: inline-block;
      text-align: center;
      color: #ffffff;
      position: absolute;
      left: 0;
      top: 15px;
      font-size: 14px;
      line-height: 1.5;
      opacity: 0; }
      .tab-product .product-box .lbl-1:before,
      .product-full-tab .product-box .lbl-1:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 12px solid #6a4031;
        border-bottom: 13px solid #6a4031;
        border-right: 7px solid #ffffff; }
    .tab-product .product-box .lbl-2,
    .product-full-tab .product-box .lbl-2 {
      font-size: 14px;
      top: 15px;
      position: absolute;
      right: 10px;
      color: #333333;
      font-weight: 600;
      text-transform: capitalize;
      opacity: 0; }
    .tab-product .product-box a,
    .product-full-tab .product-box a {
      color: #0072bb;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1px; }
    .tab-product .product-box .color-variant,
    .product-full-tab .product-box .color-variant {
      position: absolute;
      top: -35px;
      width: 100%; }
    .tab-product .product-box .slick-slide img,
    .product-full-tab .product-box .slick-slide img {
      display: block; }
    .tab-product .product-box .product-details,
    .product-full-tab .product-box .product-details {
      position: relative; }
      .tab-product .product-box .product-details .color-variant,
      .product-full-tab .product-box .product-details .color-variant {
        position: absolute;
        top: -35px;
        width: 100%; }
        .tab-product .product-box .product-details .color-variant li,
        .product-full-tab .product-box .product-details .color-variant li {
          opacity: 0;
          display: inline-block;
          height: 15px;
          width: 15px;
          border-radius: 10%;
          margin: 0 3px;
          transition: all 0.3s ease;
          cursor: pointer; }
      .tab-product .product-box .product-details h6,
      .product-full-tab .product-box .product-details h6 {
        color: #333333;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: capitalize; }
      .tab-product .product-box .product-details .price,
      .product-full-tab .product-box .product-details .price {
        padding-bottom: 10px;
        font-size: 16px;
        color: #6a4031; }

.product-full-tab {
  padding-top: 70px; }

.product-load-more .col-grid-box {
  display: none; }

.load-more-sec {
  margin-top: 40px;
  text-align: center; }
  .load-more-sec a {
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    width: 100%;
    color: #6a4031; }

.product_image_4 > div:nth-last-child(-n + 2) {
  margin-top: 25px; }

.common-card {
  padding: 30px;
  border: 1px solid #f1f1f1;
  line-height: 1;
  border-radius: 25px;
  background-color: #ffffff;
  height: 100%; }

.theme-card {
  position: relative; }
  .theme-card.center-align {
    display: flex;
    height: 100%;
    align-items: center; }
    .theme-card.center-align .offer-slider .product-box2 .media .media-body {
      margin-top: 0; }
  .theme-card.card-border {
    border: 1px solid #dddddd; }
    .theme-card.card-border h5 {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      margin-top: 0; }
    .theme-card.card-border .slick-prev,
    .theme-card.card-border .slick-next {
      top: -45px; }
    .theme-card.card-border .slick-next {
      right: 30px; }
    .theme-card.card-border .slick-prev {
      right: 50px; }
    .theme-card.card-border .offer-slider {
      padding-top: 0;
      padding-bottom: 10px; }
      .theme-card.card-border .offer-slider img {
        padding: 15px 15px 15px 30px; }
      .theme-card.card-border .offer-slider .media .media-body a h6 {
        margin-right: 25px; }
  .theme-card h5 {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-top: -5px;
    color: #6a4031; }
    .theme-card h5.title-border {
      border-bottom: 1px solid #6a4031; }
  .theme-card .slick-prev,
  .theme-card .slick-next {
    top: -35px; }
    .theme-card .slick-prev:before,
    .theme-card .slick-next:before {
      font: normal normal normal 30px/1 FontAwesome;
      color: #222222;
      opacity: 0.6; }
  .theme-card .slick-next {
    right: 0; }
    .theme-card .slick-next:before {
      content: "\f105";
      color: #6a4031; }
  .theme-card .slick-prev {
    left: unset;
    right: 25px; }
    .theme-card .slick-prev:before {
      content: "\f104";
      color: #6a4031; }
  .theme-card .offer-slider img {
    max-height: 130px;
    padding: 15px 15px 15px 0; }
  .theme-card .offer-slider > div .media:last-child .media-body {
    margin-top: 15px; }
  .theme-card .offer-slider > div .media:last-child img {
    margin-bottom: -15px; }
  .theme-card .offer-slider .media .media-body .rating {
    margin-top: 0; }
    .theme-card .offer-slider .media .media-body .rating i {
      padding-right: 5px; }
      .theme-card .offer-slider .media .media-body .rating i:nth-child(-n + 4) {
        color: #6a4031; }
      .theme-card .offer-slider .media .media-body .rating i:last-child {
        color: #dddddd; }
  .theme-card .offer-slider .media .media-body a h6 {
    font-size: 14px;
    margin-right: 61px;
    line-height: 17px;
    margin-top: 5px;
    margin-bottom: 0;
    color: #33322d; }
  .theme-card .offer-slider .media .media-body h4 {
    font-size: 18px;
    margin-top: 10px;
    font-weight: 700;
    color: #6a4031;
    margin-bottom: 0; }

.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative; }

.quick-view-modal .modal-body .btn-close {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 5px; }
  .quick-view-modal .modal-body .btn-close span {
    font-size: 22px; }
  .quick-view-modal .modal-body .btn-close:focus {
    box-shadow: none; }

.checkout-page .checkout-title {
  margin-bottom: 25px; }
  .checkout-page .checkout-title h3 {
    font-size: 24px;
    color: #6a4031;
    font-weight: 700; }

.checkout-page .checkout-form .check-out ::placeholder {
  color: #6a4031; }

.checkout-page .checkout-form .check-out .form-group:last-child {
  margin-bottom: -5px; }
  .checkout-page .checkout-form .check-out .form-group:last-child label {
    margin-bottom: -5px; }

.checkout-page .checkout-form .form-group {
  position: relative;
  margin-bottom: 25px; }
  .checkout-page .checkout-form .form-group h3 {
    color: #6a4031;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px; }
  .checkout-page .checkout-form .form-group .field-label {
    line-height: 24px;
    text-transform: capitalize;
    color: #6a4031;
    margin-bottom: 10px;
    font-weight: 700;
    font-family: "Work Sans", sans-serif; }
    .checkout-page .checkout-form .form-group .field-label span {
      font-size: 16px;
      color: #6a4031;
      font-weight: 600; }
  .checkout-page .checkout-form .form-group label {
    color: #6a4031; }

.checkout-page .checkout-form select {
  color: #6a4031;
  cursor: pointer;
  background: url(../images/dropdown.png) no-repeat 95%;
  border-radius: 25px; }

.checkout-page .checkout-form input[type="text"], .checkout-page .checkout-form input[type="email"], .checkout-page .checkout-form input[type="password"], .checkout-page .checkout-form input[type="tel"], .checkout-page .checkout-form input[type="number"], .checkout-page .checkout-form input[type="url"] {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #c7ac85;
  border-radius: 25px; }

.checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #c7ac85;
  -webkit-appearance: none; }

.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px; }
  .checkout-page .check-box label {
    position: relative;
    top: -1px;
    font-weight: normal;
    padding: 0;
    font-size: 16px;
    cursor: pointer; }

.checkout-page .lower-content {
  margin-top: 30px; }
  .checkout-page .lower-content .order-column {
    margin-bottom: 40px; }

.order-box {
  position: relative;
  margin-bottom: 50px; }
  .order-box .title-box {
    position: relative;
    padding-bottom: 25px;
    color: #6a4031;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid #f7f3ee;
    margin-bottom: 20px; }
    .order-box .title-box span {
      font-size: 22px;
      position: relative;
      width: 35%;
      float: right;
      line-height: 1.2em; }
  .order-box .qty {
    position: relative;
    border-bottom: 1px solid #f7f3ee;
    margin-bottom: 30px; }
    .order-box .qty li {
      position: relative;
      display: block;
      font-size: 15px;
      font-family: "Work Sans", sans-serif;
      line-height: 20px;
      margin-bottom: 20px; }
      .order-box .qty li span {
        float: right;
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        width: 35%; }
  .order-box .sub-total {
    position: relative;
    border-bottom: 1px solid #f7f3ee;
    margin-bottom: 30px; }
    .order-box .sub-total li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 20px;
      font-family: "Work Sans", sans-serif;
      width: 100%; }
      .order-box .sub-total li .count {
        font-size: 18px; }
    .order-box .sub-total .shopping-option label {
      position: relative;
      font-size: 16px;
      line-height: 32px;
      padding-left: 10px; }
    .order-box .sub-total .shipping {
      width: 35%;
      float: right; }
  .order-box .total {
    position: relative;
    margin-bottom: 40px; }
    .order-box .total li {
      position: relative;
      display: block;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: "Work Sans", sans-serif; }
      .order-box .total li .count {
        position: relative;
        font-size: 20px;
        line-height: 20px;
        color: #6a4031;
        font-weight: 400; }
      .order-box .total li span {
        float: right;
        font-size: 15px;
        line-height: 20px;
        color: #6a4031;
        font-weight: 400;
        width: 35%;
        display: block; }

.payment-box {
  position: relative; }
  .payment-box .upper-box {
    position: relative; }
  .payment-box .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px; }
    .payment-box .payment-options li {
      display: flex;
      margin-bottom: 15px; }
      .payment-box .payment-options li .radio-option {
        position: relative; }
        .payment-box .payment-options li .radio-option label {
          position: relative;
          padding-left: 30px;
          text-transform: capitalize;
          font-family: "Work Sans", sans-serif;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0; }
        .payment-box .payment-options li .radio-option input[type="radio"] {
          position: absolute;
          left: 0;
          top: 5px; }
        .payment-box .payment-options li .radio-option label .small-text {
          position: relative;
          display: none;
          font-size: 15px;
          line-height: 25px;
          font-weight: 300;
          color: #6a4031;
          margin-top: 10px; }
        .payment-box .payment-options li .radio-option label img {
          position: relative;
          display: block;
          max-width: 100%;
          margin-left: -30px;
          margin-top: 5px; }

.dashboard-left .block-title h2 {
  font-size: 24px;
  padding-bottom: 20px; }

.dashboard-left .block-content ul li {
  display: flex;
  transition: all 0.5s ease;
  margin-bottom: 10px; }
  .dashboard-left .block-content ul li:last-child {
    margin-bottom: 0; }
  .dashboard-left .block-content ul li.active {
    color: #6a4031; }
    .dashboard-left .block-content ul li.active a {
      color: #6a4031;
      font-family: "Prata", serif; }
    .dashboard-left .block-content ul li.active:before {
      color: #6a4031; }
  .dashboard-left .block-content ul li:before {
    content: "\f105";
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    line-height: 1;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #6a4031; }
  .dashboard-left .block-content ul li a {
    color: #6a4031; }
  .dashboard-left .block-content ul li:hover {
    padding-left: 10px;
    color: #6a4031;
    transition: all 0.5s ease; }
    .dashboard-left .block-content ul li:hover:before {
      color: #6a4031; }
    .dashboard-left .block-content ul li:hover a {
      color: #6a4031;
      transition: all 0.5s ease; }

.padding-cls {
  padding-left: 50px;
  padding-right: 50px; }

.dashboard-right p {
  font-size: 14px;
  color: #6a4031;
  line-height: 20px; }

.dashboard-right .dashboard .page-title h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #6a4031;
  font-weight: 700;
  font-family: "Work Sans", sans-serif; }

.dashboard-right .dashboard .welcome-msg p {
  margin-bottom: 0; }

.dashboard .box-account > div + div {
  margin-top: 20px; }

.dashboard .box-head h2 {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
  color: #6a4031; }

.dashboard .box a {
  color: #6a4031; }

.dashboard .box h6 {
  margin-bottom: 0; }

.dashboard .box .box-title {
  border-bottom: 1px solid #f2ece3;
  display: inline-block;
  margin-bottom: 5px;
  padding: 12px 0;
  position: relative;
  width: 100%; }
  .dashboard .box .box-title h3 {
    font-size: 22px;
    margin: 0;
    text-transform: capitalize;
    color: #6a4031; }
  .dashboard .box .box-title > a {
    position: absolute;
    right: 0;
    top: 12px;
    color: #6a4031; }

.dashboard .box address {
  margin-bottom: 0;
  color: #6a4031;
  line-height: 2;
  margin-top: 10px; }

.box-content h6 {
  font-size: 14px;
  line-height: 25px !important;
  margin-bottom: 0;
  color: #6a4031; }

.box-content a {
  color: #6a4031; }

.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: #6a4031;
  color: #ffffff;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px; }

.light-layout {
  background-color: #f9f9f9; }

.success-text {
  text-align: center; }
  .success-text i {
    font-size: 50px;
    color: #4ead4e; }
  .success-text h2 {
    margin-top: 10px;
    margin-bottom: 15px; }
  .success-text p {
    font-size: 18px;
    text-transform: capitalize; }
  .success-text.order-fail i {
    color: #6a4031; }

.order-success-sec h4 {
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #6a4031; }

.order-success-sec .order-detail li {
  display: flex;
  text-transform: capitalize;
  line-height: 2;
  font-size: 16px;
  color: #33322d;
  font-family: "Work Sans", sans-serif; }

.order-success-sec .payment-mode p {
  font-size: 16px;
  margin-bottom: 0; }

.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: rgba(238, 228, 216, 0.4); }
  .delivery-sec h2,
  .delivery-sec h3 {
    color: #6a4031;
    text-transform: capitalize; }
  .delivery-sec h3 {
    font-size: 24px;
    margin-top: -5px; }
  .delivery-sec h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: -10px; }

.product-order h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #6a4031;
  line-height: 23px; }

.product-order .product-order-detail {
  margin-top: 15px; }
  .product-order .product-order-detail img {
    max-height: 130px; }
  .product-order .product-order-detail .order_detail {
    display: flex;
    align-items: center; }
    .product-order .product-order-detail .order_detail h4 {
      font-size: 18px;
      margin-bottom: 5px;
      text-transform: capitalize;
      font-weight: 700;
      color: #6a4031; }
    .product-order .product-order-detail .order_detail h5 {
      font-size: 16px;
      text-transform: capitalize;
      margin-bottom: 0;
      margin-top: 15px; }

.product-order .total-sec {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 15px 0;
  padding: 15px 0; }
  .product-order .total-sec ul li {
    text-transform: capitalize;
    line-height: 2;
    font-family: "Work Sans", sans-serif; }
    .product-order .total-sec ul li span {
      float: right;
      color: #6a4031; }

.product-order .final-total h3 {
  display: block;
  margin-bottom: 0; }
  .product-order .final-total h3 span {
    float: right; }

.compare-section .compare-part {
  position: relative; }
  .compare-section .compare-part .close-btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
    position: absolute;
    top: 0;
    right: 0; }
  .compare-section .compare-part .img-secton {
    padding: 25px 15px 15px; }
    .compare-section .compare-part .img-secton img,
    .compare-section .compare-part .img-secton .bg-size {
      width: 50%;
      margin: 0 auto; }
    .compare-section .compare-part .img-secton a h5 {
      margin-bottom: 0;
      text-transform: capitalize;
      margin-top: 10px; }
    .compare-section .compare-part .img-secton h5 {
      margin-bottom: 0;
      line-height: 1.2; }
  .compare-section .compare-part .detail-part .title-detail {
    background-color: #f7f8fa;
    text-align: center;
    padding: 8px 0;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb; }
    .compare-section .compare-part .detail-part .title-detail h5 {
      margin-bottom: 0;
      text-transform: uppercase; }
  .compare-section .compare-part .detail-part .inner-detail {
    padding: 15px; }
    .compare-section .compare-part .detail-part .inner-detail p {
      margin-bottom: 0;
      line-height: 1.2;
      letter-spacing: 0.05em; }
  .compare-section .compare-part .btn-part {
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ddd; }
    .compare-section .compare-part .btn-part .btn-solid,
    .compare-section .compare-part .btn-part .btn-outline {
      padding: 5px 10px; }

.compare-section .slick-slide > div {
  border: 1px solid #ddd;
  border-left: none; }

.compare-section .slick-slide:first-child {
  border-left: 1px solid #ddd; }

.compare-padding {
  margin-bottom: -20px; }
  .compare-padding h2 {
    margin-bottom: 20px; }

.compare-lable {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1; }

.compare-page .table-wrapper {
  padding-bottom: 0; }
  .compare-page .table-wrapper .table {
    border: 1px solid #c7ac85;
    text-transform: capitalize;
    color: #6a4031; }
    .compare-page .table-wrapper .table .product-name {
      width: 15%; }
    .compare-page .table-wrapper .table .featured-image {
      width: 185px; }
    .compare-page .table-wrapper .table td {
      border-top: none; }
    .compare-page .table-wrapper .table thead .th-compare td {
      font-size: 14px;
      font-weight: 700;
      background: #f7f3ee;
      border-right: 1px solid #c7ac85; }
    .compare-page .table-wrapper .table thead .th-compare th {
      text-align: left;
      border-top: 1px solid #c7ac85;
      border-bottom: 1px solid #c7ac85;
      border-right: 1px solid #c7ac85; }
      .compare-page .table-wrapper .table thead .th-compare th button {
        color: #6a4031;
        cursor: pointer; }
      .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
        float: left;
        border: none;
        background: transparent;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        font-family: "Work Sans", sans-serif; }
    .compare-page .table-wrapper .table tbody tr th {
      background: #f7f3ee;
      font-family: "Work Sans", sans-serif; }
    .compare-page .table-wrapper .table tbody tr td {
      border-right: 1px solid #c7ac85;
      position: relative;
      font-family: "Work Sans", sans-serif; }
    .compare-page .table-wrapper .table tbody tr th,
    .compare-page .table-wrapper .table tbody tr td {
      padding: 15px;
      vertical-align: top;
      border-bottom: 1px solid #c7ac85;
      border-top: 1px solid #c7ac85;
      border-right: 1px solid #c7ac85;
      font-family: "Work Sans", sans-serif; }
    .compare-page .table-wrapper .table tbody tr .grid-link__title {
      font-size: 15px;
      font-family: "Work Sans", sans-serif; }
    .compare-page .table-wrapper .table tbody tr .product_price {
      margin: 15px 0; }
      .compare-page .table-wrapper .table tbody tr .product_price span.money {
        padding-left: 5px; }
    .compare-page .table-wrapper .table tbody tr p {
      font-size: 13px;
      line-height: 20px;
      margin: 0;
      font-family: "Work Sans", sans-serif; }
    .compare-page .table-wrapper .table tbody tr .add-to-cart,
    .compare-page .table-wrapper .table tbody tr .select-option-cls {
      padding: 10px 18px; }

.typography_section .typography-box {
  margin-bottom: 30px; }
  .typography_section .typography-box p {
    line-height: 22px; }
  .typography_section .typography-box .headings {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
    background-color: rgba(247, 247, 247, 0.5);
    padding: 8px 12px; }
    .typography_section .typography-box .headings h3 {
      text-transform: capitalize;
      color: #2d2d2d;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 20px; }
    .typography_section .typography-box .headings span {
      color: #e5e5e5; }
      .typography_section .typography-box .headings span code {
        color: #e5e5e5; }
  .typography_section .typography-box .typo-content.heading_content h1,
  .typography_section .typography-box .typo-content.heading_content h2,
  .typography_section .typography-box .typo-content.heading_content h3,
  .typography_section .typography-box .typo-content.heading_content h4,
  .typography_section .typography-box .typo-content.heading_content h5,
  .typography_section .typography-box .typo-content.heading_content h6 {
    font-weight: 400;
    color: black;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px; }
  .typography_section .typography-box .typo-content .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
    color: #222222;
    text-transform: capitalize; }
  .typography_section .typography-box .typo-content.product-pagination {
    border: none;
    margin: 0; }
    .typography_section .typography-box .typo-content.product-pagination .pagination .page-item {
      border-top: 1px solid #c7ac85;
      border-bottom: 1px solid #c7ac85; }
      .typography_section .typography-box .typo-content.product-pagination .pagination .page-item a {
        padding: 10px 14px; }
  .typography_section .typography-box .typo-content.input_button input {
    margin-bottom: 10px; }
  .typography_section .typography-box .typo-content.input_button label {
    font-size: 16px;
    text-transform: capitalize;
    padding-left: 5px; }
  .typography_section .typography-box .typo-content.loader-typo {
    width: 100%;
    justify-content: center;
    display: flex; }
    .typography_section .typography-box .typo-content.loader-typo .pre-loader {
      position: relative;
      width: 50px;
      height: 50px;
      background-color: #6a4031;
      border-radius: 50%; }
      .typography_section .typography-box .typo-content.loader-typo .pre-loader:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        border: 0 solid white;
        transform: translate(-50%, -50%);
        animation: loader-wrapper 1000ms ease-out forwards infinite;
        transition: all 0.3s ease; }
  .typography_section .typography-box .typo-content .footer-social {
    margin-top: 0;
    justify-content: center; }
  .typography_section .typography-box:last-child {
    margin-bottom: 0; }

.typography_section code {
  color: rgba(106, 64, 49, 0.6);
  background-color: #f7f7f7;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px; }

.profile-section {
  background-color: #f5f5f5;
  border: 1px solid #dddddd;
  padding: 15px; }
  .profile-section .profile-detail .profile-name {
    font-weight: 700; }
  .profile-section .profile-detail p {
    margin-bottom: 8px; }

.btn-theme {
  background-color: #6a4031; }

/*=====================
    24. Menu css start
==========================*/
.sm, .sm ul, .sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left; }

.sm {
  box-sizing: border-box;
  position: relative;
  z-index: 9999; }
  .sm ul {
    display: none; }
  .sm li {
    position: relative; }
  .sm > li > h1 {
    margin: 0;
    padding: 0; }
  .sm > li > h2 {
    margin: 0;
    padding: 0; }
  .sm > li > h3 {
    margin: 0;
    padding: 0; }
  .sm > li > h4 {
    margin: 0;
    padding: 0; }
  .sm > li > h5 {
    margin: 0;
    padding: 0; }
  .sm > li > h6 {
    margin: 0;
    padding: 0; }
  .sm a {
    position: relative;
    display: block; }
    .sm a.disabled {
      cursor: default; }
  .sm * {
    box-sizing: inherit; }
  .sm *::before {
    box-sizing: inherit; }
  .sm *::after {
    box-sizing: inherit; }

.sm::after {
  content: "";
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  overflow: hidden; }

.pixelstrap a {
  font-size: 16px; }
  .pixelstrap a, .pixelstrap a:hover, .pixelstrap a:focus, .pixelstrap a:active {
    padding-right: 45px;
    color: #6a4031;
    text-decoration: none; }
  .pixelstrap a.disabled {
    color: #ffd8d4; }
  .pixelstrap a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px; }
  .pixelstrap a .sub-arrow::before {
    content: '+'; }
  .pixelstrap a.highlighted .sub-arrow::before {
    content: '-'; }

.pixelstrap > li:last-child > a, .pixelstrap > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
  border-radius: 0 0 8px 8px; }

.pixelstrap > li:last-child > a.highlighted, .pixelstrap > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
  border-radius: 0; }

.pixelstrap ul {
  background: #fff; }
  .pixelstrap ul ul {
    background: rgba(102, 102, 102, 0.1); }
  .pixelstrap ul a, .pixelstrap ul a:hover, .pixelstrap ul a:focus, .pixelstrap ul a:active {
    background: transparent;
    color: #6a4031;
    font-size: 16px;
    text-shadow: none;
    border-left: 8px solid transparent; }
  .pixelstrap ul a.current {
    background: #c33a32;
    color: #fff; }
  .pixelstrap ul a.disabled {
    color: #b3b3b3; }
  .pixelstrap ul ul a,
  .pixelstrap ul ul a:hover,
  .pixelstrap ul ul a:focus,
  .pixelstrap ul ul a:active {
    border-left: 16px solid transparent; }
  .pixelstrap ul ul ul a,
  .pixelstrap ul ul ul a:hover,
  .pixelstrap ul ul ul a:focus,
  .pixelstrap ul ul ul a:active {
    border-left: 24px solid transparent; }
  .pixelstrap ul ul ul ul a,
  .pixelstrap ul ul ul ul a:hover,
  .pixelstrap ul ul ul ul a:focus,
  .pixelstrap ul ul ul ul a:active {
    border-left: 32px solid transparent; }
  .pixelstrap ul ul ul ul ul a,
  .pixelstrap ul ul ul ul ul a:hover,
  .pixelstrap ul ul ul ul ul a:focus,
  .pixelstrap ul ul ul ul ul a:active {
    border-left: 40px solid transparent; }
  .pixelstrap ul li {
    border-top: none; }
    .pixelstrap ul li:first-child {
      border-top: 0; }

@media (min-width: 1200px) {
  /* Switch to desktop layout  */
  .pixelstrap ul {
    position: absolute; }
  .pixelstrap li {
    float: left; }
  .pixelstrap.sm-rtl li {
    float: right; }
  .pixelstrap ul li, .pixelstrap.sm-rtl ul li, .pixelstrap.sm-vertical li {
    float: none; }
  .pixelstrap a {
    white-space: nowrap; }
  .pixelstrap ul a, .pixelstrap.sm-vertical a {
    white-space: normal; }
  .pixelstrap .sm-nowrap > li > a, .pixelstrap .sm-nowrap > li > :not(ul) a {
    white-space: nowrap;
    font-size: 14px;
    text-transform: capitalize; }
  /* ...end */
  .pixelstrap > li > a {
    font-weight: 600; }
  .pixelstrap a .sub-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
    left: auto;
    right: 20px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px; }
    .pixelstrap a .sub-arrow:after {
      content: "\f107";
      font-family: FontAwesome;
      position: absolute; }
  .pixelstrap a .sub-arrow::before {
    display: none; }
  .pixelstrap ul {
    border: 1px solid rgba(151, 130, 98, 0.3);
    padding: 7px 0;
    background: #fff;
    border-radius: 0 !important;
    min-width: 12em !important; }
    .pixelstrap ul ul {
      border-radius: 4px !important;
      background: #fff; }
    .pixelstrap ul a, .pixelstrap ul a:hover, .pixelstrap ul a:focus, .pixelstrap ul a:active, .pixelstrap ul a.highlighted {
      border: 0 !important;
      padding: 9px 23px;
      color: #6a4031;
      border-radius: 0 !important; }
    .pixelstrap ul a:hover, .pixelstrap ul a:focus, .pixelstrap ul a:active, .pixelstrap ul a.highlighted {
      background-color: transparent;
      margin-left: 8px; }
      .pixelstrap ul a:hover:after, .pixelstrap ul a:focus:after, .pixelstrap ul a:active:after, .pixelstrap ul a.highlighted:after {
        content: ">";
        position: absolute;
        left: 10px; }
    .pixelstrap ul a.current {
      background: #c33a32;
      color: #fff; }
    .pixelstrap ul a.disabled {
      background: #fff;
      color: #b3b3b3; }
    .pixelstrap ul a .sub-arrow {
      position: absolute;
      top: 50%;
      margin-top: -11px;
      left: auto;
      right: 15px;
      width: 34px;
      height: 34px;
      overflow: hidden;
      font: bold 14px/34px monospace !important;
      text-align: center;
      text-shadow: none;
      border-radius: 4px;
      transform: rotate(-90deg); }
    .pixelstrap ul li {
      border: 0; }
    .pixelstrap ul.full-mega-menu ul {
      min-width: auto !important; }
    .pixelstrap ul.full-mega-menu a:hover:after, .pixelstrap ul.full-mega-menu a:focus:after, .pixelstrap ul.full-mega-menu a:active:after, .pixelstrap ul.full-mega-menu a.highlighted:after {
      content: ">";
      position: absolute;
      left: -10px; }
  .pixelstrap .scroll-up,
  .pixelstrap .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #fff;
    height: 20px; }
  .pixelstrap .scroll-up-arrow,
  .pixelstrap .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 8px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #ff3522 transparent; }
  .pixelstrap .scroll-down-arrow {
    top: 6px;
    border-style: solid dashed dashed dashed;
    border-color: #ff3522 transparent transparent transparent; }
  .pixelstrap.sm-rtl.sm-vertical a .sub-arrow {
    right: auto;
    left: 10px;
    border-style: dashed solid dashed dashed;
    border-color: transparent #6a4031 transparent transparent; }
  .pixelstrap.sm-rtl > li:first-child {
    border-left: 1px solid #ff3522; }
  .pixelstrap.sm-rtl > li:last-child {
    border-left: 0; }
  .pixelstrap.sm-rtl ul a .sub-arrow {
    right: auto;
    left: 10px;
    border-style: dashed solid dashed dashed;
    border-color: transparent #6a4031 transparent transparent; }
  .pixelstrap.sm-vertical {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    .pixelstrap.sm-vertical a {
      padding: 9px 23px; }
      .pixelstrap.sm-vertical a .sub-arrow {
        top: 50%;
        margin-top: -#6a4031;
        bottom: auto;
        left: auto;
        margin-left: 0;
        right: 10px;
        border-style: dashed dashed dashed solid;
        border-color: transparent transparent transparent #6a4031; }
    .pixelstrap.sm-vertical > li {
      border-left: 0 !important; } }

/*==================
   25. Responsive css
===================*/
@media (min-width: 1470px) {
  .container {
    max-width: 1440px; } }

@media (max-width: 1470px) {
  .menu .menu-content .menu-right .full-mega-menu {
    min-width: 950px !important; } }

@media (max-width: 1440px) {
  .animation-section .decor-animation .decor10 {
    width: 18%;
    bottom: 18%; }
  .animation-section.main_section .decor-animation .decor9 {
    width: 10%;
    bottom: 19%; }
  .animation-section.main_section .decor-animation .decor11 {
    width: 20%;
    top: 4%; }
  .product_box .product_image .product_icon i {
    padding: 7px; }
  .title_prime h2 {
    line-height: 50px;
    padding-top: 40px; }
  .title_prime p {
    margin-top: -16px;
    padding-top: 40px; }
  .title_second h5 {
    margin-top: -3px; }
  .btn_dark {
    padding: 13px 30px;
    letter-spacing: 2px; }
  .btn_lite {
    padding: 13px 30px;
    letter-spacing: 2px; }
  .menu .menu-content .navbar .nav-link {
    padding-right: 15px;
    padding-left: 15px; }
  .menu .menu-content .navbar .navbar-brand img {
    width: 99px; }
  .home-slider .main_section {
    height: 85vh; }
    .home-slider .main_section .main_section_contain {
      height: 85vh; }
      .home-slider .main_section .main_section_contain h1 {
        line-height: 75px; }
      .home-slider .main_section .main_section_contain p {
        letter-spacing: 0.11em;
        padding: 40px 0; }
      .home-slider .main_section .main_section_contain .new {
        margin-bottom: 20px;
        margin-left: -5px;
        margin-top: 32px; }
  .advertise_section .bottle img {
    width: 85%;
    bottom: -50px; }
  .testimonial_section .testimonial .comment_box {
    margin-left: 50px; }
  .testimonial_section .nav_image {
    margin-bottom: -25px;
    padding-top: 0; }
  .footer .footer-title {
    margin-top: -4px; }
  .footer .links {
    padding: 0; }
  .footer .footer-content .mo-number {
    margin-bottom: 0; }
  .footer .footer-content .jvector-map-height .jvectormap-container {
    height: 230px; }
    .footer .footer-content .jvector-map-height .jvectormap-container svg {
      top: -50px;
      height: 230px; }
  .theme-breadcrumb p {
    margin-bottom: -6px; }
  .theme-breadcrumb h2 {
    margin-top: 6px; }
  .about-page p {
    margin-bottom: -7px; }
  .about-page h4 {
    margin-top: -7px; }
  .team h6 {
    margin-bottom: -3px; }
  .call-to-action-section .contact .newsletter {
    padding-left: 35px; }
  .call-to-action-section .contact .media .media-body {
    padding-right: 35px; }
  .call-to-action-section .email-box .email {
    width: 400px; }
  .feature-section .bottle-image {
    margin-top: 75px; }
  .feature-section .feature-box > div:nth-child(1) {
    margin-top: 200px; }
  .feature-section .feature-box > div:nth-child(2) {
    margin-top: 80px; }
  .mission-section .title_prime .para2 {
    margin-top: -5px;
    padding-top: 30px; }
  .mission-section .title_prime .btn_dark {
    margin-top: 40px; }
  .advertise_section {
    padding: 100px 0;
    margin-top: 116px; }
    .advertise_section .adv_contain {
      margin-top: -3px; }
      .advertise_section .adv_contain p {
        padding: 25px 0 0 0; }
  .service-section .title_prime .btn {
    margin-top: 30px; }
  .service-section .image-container {
    margin: 40px 0; }
  .about-us-section .main-image h4 {
    top: 59px;
    right: -28.5%; }
  .about-us-section .main-image .sub-image {
    width: 235px; }
  .about-us-section .main-contain h2 {
    line-height: 50px;
    margin-top: -8px; }
  .about-us-section .main-contain .para1 {
    padding-top: 35px; }
  .about-us-section .main-contain .para2 {
    padding-top: 15px; }
  .about-us-section .main-contain .btn_lite {
    margin-top: 20px; }
  .product_box .product_image .timer-box .timer .padding-l {
    padding-left: 15px; }
  .product_box .product_image .timer-box .timer span {
    width: 55px; }
    .product_box .product_image .timer-box .timer span .timer-cal {
      padding-left: 7px; }
  .hair_color_section .hair_color .main-box .main-color {
    padding: 10px; }
    .hair_color_section .hair_color .main-box .main-color:before {
      height: 22px;
      width: 22px;
      border: 6px solid white; }
    .hair_color_section .hair_color .main-box .main-color li {
      padding: 10px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #6a4031;
    border-bottom: 1px solid #6a4031;
    text-align: center; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #6a4031; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
      display: none; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #6a4031;
    width: 30%; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%; }
  .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    width: 10%; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    width: 25%; }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 38px; }
  .cart-section tbody tr td {
    min-width: 175px; }
    .cart-section tbody tr td .qty-box .input-group .form-control {
      width: 75px; }
  .cart-section tfoot tr td {
    padding-right: 41px; }
  .product-right .product-icon .product-social li {
    padding-right: 10px; }
  .product-form-box .timer {
    padding-left: 17px; }
    .product-form-box .timer span {
      width: 55px; }
  .color-selector ul li.active:after {
    top: 9px;
    right: 0; } }

@media (max-width: 1367px) {
  .advertise_section {
    padding: 100px 0;
    margin-top: 122px; }
  .home-slider .main_section {
    height: 80vh; }
    .home-slider .main_section .main_section_contain {
      height: 80vh; }
      .home-slider .main_section .main_section_contain h1 {
        line-height: 70px;
        letter-spacing: 0.025em; }
      .home-slider .main_section .main_section_contain p {
        letter-spacing: 0.075em;
        padding: 35px 0; }
      .home-slider .main_section .main_section_contain .new {
        margin-bottom: 20px;
        margin-top: 28px; }
  .about-page h4 {
    margin-top: -6px; } }

@media (max-width: 1199px) {
  section {
    padding: 75px 0; }
  .footer .container .row div + div + div {
    margin-top: 30px; }
  .footer p {
    margin-bottom: 0; }
  .footer .links {
    padding-left: 15px;
    padding-right: 15px; }
  .collection-filter-block {
    padding: 0 20px; }
    .collection-filter-block .collapse-block-title {
      margin: 25px 0 0 0; }
  .collection-filter .theme-card {
    padding: 20px; }
  .theme-card .slick-next {
    right: -10px; }
  .theme-card .slick-prev {
    right: 10px; }
  .section-t-space {
    padding-top: 75px; }
  .section-b-space {
    padding-bottom: 75px; }
  .title_prime h2 {
    max-width: 335px;
    line-height: 45px;
    padding-top: 35px; }
  .title_prime p {
    padding-top: 35px; }
  .title_second {
    margin-bottom: 37px; }
    .title_second h5 {
      margin-top: -2px; }
  .prodcut_left_margin > div:nth-last-child(1), .prodcut_left_margin > div:nth-last-child(2), .prodcut_left_margin > div:nth-last-child(3) {
    margin-top: 30px; }
  .btn_dark {
    padding: 12px 24px;
    letter-spacing: 1px; }
  .btn_lite {
    padding: 12px 24px;
    letter-spacing: 1px; }
  .menu.active .according-menu {
    font: normal normal normal 14px/1 FontAwesome; }
    .menu.active .according-menu:before {
      content: "+";
      position: absolute;
      right: 2px;
      top: 2px; }
  .menu .according-menu {
    font: normal normal normal 14px/1 FontAwesome; }
    .menu .according-menu:before {
      content: "+";
      position: absolute;
      right: 2px;
      top: 2px; }
  .menu .menu-content .menu-right .full-mega-menu {
    right: 0 !important;
    min-width: 100% !important;
    margin-left: 0 !important;
    padding: 0 40px 0 24px !important;
    margin-top: 5px; }
    .menu .menu-content .menu-right .full-mega-menu h5 {
      margin: 10px 0 !important;
      background: transparent;
      color: #6a4031;
      font-size: 16px;
      text-shadow: none;
      font-weight: 400;
      text-transform: capitalize; }
    .menu .menu-content .menu-right .full-mega-menu .mega-box.col {
      flex-basis: unset !important; }
  .menu .menu-content .menu-left .brand-logo a {
    padding: 0; }
  .menu .menu-content a {
    padding: 10px 15px; }
  .menu .menu-content .toggle-nav {
    display: block;
    margin-right: 25px; }
  .menu .menu-content .sm-horizontal {
    position: fixed;
    width: 300px;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    background: #ffffff;
    overflow: scroll; }
    .menu .menu-content .sm-horizontal .mobile-back {
      padding: 20px;
      font-size: 18px;
      color: #6a4031;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 1px solid #efefef;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
    .menu .menu-content .sm-horizontal ul li a {
      text-transform: capitalize;
      font-weight: 400; }
  .menu .menu-content .navbar .nav-link {
    padding-right: 8px;
    padding-left: 8px; }
  .menu .menu-content .navbar .navbar-brand img {
    width: 90px; }
  .main-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    background: white; }
  .home-slider .main_section {
    height: 75vh; }
    .home-slider .main_section .main_section_contain {
      height: 75vh; }
      .home-slider .main_section .main_section_contain h1 {
        line-height: 65px; }
      .home-slider .main_section .main_section_contain p {
        letter-spacing: 0.055em;
        padding: 30px 0; }
      .home-slider .main_section .main_section_contain .new {
        margin-bottom: 15px;
        margin-left: -5px;
        margin-top: 25px; }
  .call-to-action-section .contact .media .media-body {
    padding-right: 0; }
  .call-to-action-section .contact .newsletter {
    padding-left: 15px; }
  .call-to-action-section .email-box .email {
    width: 375px; }
  .feature-section .bottle-image {
    margin-top: 65px; }
  .feature-section .feature-box > div:nth-child(1) {
    margin-top: 175px; }
  .feature-section .feature-box > div:nth-child(2) {
    margin-top: 75px; }
  .mission-section .box-container .box-item .flip-box .flip-box-back .inner h6,
  .mission-section .box-container .box-item .flip-box .flip-box-back .inner p {
    margin-bottom: 5px; }
  .mission-section .title_prime .para2 {
    margin-top: -7px;
    padding-top: 25px; }
  .mission-section .title_prime .btn_dark {
    margin-top: 33px; }
  .service-section .image-container {
    margin: 33px 0; }
  .service-section .title_prime .btn {
    margin-top: 23px; }
  .about-us-section .main-image h4 {
    top: 53px;
    right: -32%; }
  .about-us-section .main-image .sub-image {
    width: 200px; }
  .about-us-section .main-contain h2 {
    line-height: 40px;
    margin-top: -5px; }
  .about-us-section .main-contain .para1 {
    padding-top: 25px; }
  .about-us-section .main-contain .para2 {
    padding-top: 10px; }
  .about-us-section .main-contain .btn_lite {
    margin-top: 15px; }
  .blog_section .blog .card .card-body h6 {
    padding: 5px 0; }
  .hair_color_section .hair_color .main-box .main-color {
    padding: 7px; }
    .hair_color_section .hair_color .main-box .main-color:before {
      height: 20px;
      width: 20px;
      border: 5px solid white; }
    .hair_color_section .hair_color .main-box .main-color li {
      padding: 7px; }
  .footer .footer-content .mo-number {
    margin-bottom: 10px; }
  .footer .footer-content .jvector-map-height {
    height: 230px;
    margin-bottom: -4px; }
    .footer .footer-content .jvector-map-height .jvectormap-container {
      height: 230px; }
      .footer .footer-content .jvector-map-height .jvectormap-container svg {
        top: 0;
        height: 230px; }
  .product_box .product_image .product_icon i {
    padding: 5px; }
  .product_contain h5 {
    padding-bottom: unset; }
  .advertise_section {
    padding: 68px 0;
    margin-top: 32px; }
    .advertise_section .bottle img {
      bottom: -50px; }
    .advertise_section .adv_contain {
      margin-top: -2px; }
      .advertise_section .adv_contain h3 {
        margin-bottom: 0; }
  .brand_section .logo img {
    width: 90%; }
  .testimonial_section .testimonial .space .test_image img {
    width: 125px; }
  .testimonial_section .testimonial .space .test_image .chat_icon {
    width: 60px; }
  .testimonial_section .testimonial .space .comment_box {
    margin-left: 40px;
    margin-top: 15px; }
  .testimonial_section .nav_img {
    padding: 35px 13%; }
  .cart-section tbody tr td,
  .wishlist-section tbody tr td {
    min-width: 100%; }
  .search-block .form-header {
    padding-top: 15px; }
  .blog-detail-page .comment-section li {
    padding-top: 45px;
    padding-bottom: 45px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #6a4031;
    border-bottom: 1px solid #6a4031; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #6a4031; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
      display: none; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 30%; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
    margin-top: 15px; }
  .collection-filter-block .product-service .media .media-body h4 {
    font-size: 16px; }
  .product-right h2 {
    font-size: 20px; }
  .testimonial .testimonial-slider .media .media-body {
    padding: 30px; }
    .testimonial .testimonial-slider .media .media-body p {
      line-height: 1.5; }
  .blog-page .blog-media {
    margin-bottom: 20px; }
    .blog-page .blog-media .blog-right {
      display: block;
      margin-top: 15px; }
      .blog-page .blog-media .blog-right p {
        line-height: 1.3; }
  .tab-product .tab-content.nav-material .single-product-tables table {
    width: 50%; }
  .product-description-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px; }
  .product-description-box .product-icon .product-social li {
    padding-right: 4px; }
  .product-description-box .product-icon .wishlist-btn span {
    padding-left: 4px; }
  .product-form-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px; }
  .product-form-box .timer {
    padding-left: 17px; }
    .product-form-box .timer span {
      width: 55px; }
  .product-form-box .product-buttons .btn_dark,
  .product-form-box .product-buttons .btn-outline {
    padding: 7px 13px; }
  .product-accordion .single-product-tables table {
    width: 70%; }
  .tab-product .flex-column {
    flex-direction: unset !important;
    justify-content: center; }
  .tab-product .nav-material.nav-tabs {
    border-bottom: 1px solid #dddddd;
    border-right: 0; }
    .tab-product .nav-material.nav-tabs .nav-item .nav-link.active {
      background-color: unset; }
  .tab-border {
    padding-top: 0; }
  .color-selector ul li.active:after {
    right: -1px;
    width: 13px;
    height: 13px; }
  .working-hourse-main .working-hourse-icon > div:nth-child(n+4) .logo {
    padding-top: 30px; }
  .working-hourse-main .working-hourse-icon > div .logo {
    padding-bottom: 30px; }
    .working-hourse-main .working-hourse-icon > div .logo img {
      width: 40px;
      height: auto; }
  .working-hourse-main .working-hourse-block > div:nth-child(2) {
    padding-bottom: 30px; } }

@media (max-width: 1024px) {
  .about-us-section .main-image h4 {
    top: 49px;
    right: -21%; }
  .advertise_section {
    padding: 99px 0;
    margin-top: 34px; }
  .slick-prev {
    left: 0;
    z-index: 7; }
  .slick-next {
    right: 0;
    z-index: 7; } }

@media (max-width: 991px) {
  section {
    padding: 60px 0; }
  .section-t-space {
    padding-top: 60px; }
  .section-b-space {
    padding-bottom: 60px; }
  .alert {
    max-width: 45% !important; }
  .map {
    order: 1;
    margin-top: 1.5rem; }
  .checkout-page .common-card {
    margin-top: 30px;
    padding-bottom: 0; }
  .quick-view-modal .product-right .product-buttons {
    justify-content: center; }
  .abjust-space {
    margin-top: 20px;
    height: auto; }
  .search-block .form-header {
    padding: 15px 20% 0 20%; }
  .decor-animation {
    opacity: 0.6; }
  .pwd-page .theme-form input {
    margin: 0 auto; }
  .pwd-page .forget-btn {
    margin-top: 25px; }
  .btn_dark {
    padding: 10px 20px; }
    .btn_dark:after {
      left: -85px; }
  .btn_lite {
    padding: 10px 20px; }
    .btn_lite:after {
      left: -85px; }
  .home-slider .main_section {
    height: 65vh; }
    .home-slider .main_section .main_section_contain {
      height: 65vh; }
      .home-slider .main_section .main_section_contain h1 {
        line-height: 60px; }
      .home-slider .main_section .main_section_contain p {
        letter-spacing: 0;
        padding: 30px 0; }
      .home-slider .main_section .main_section_contain .new {
        margin-bottom: 5px;
        margin-left: -10px;
        margin-top: 18px; }
  .menu .menu-content {
    padding: 5px 0; }
    .menu .menu-content .navbar {
      background: transparent !important; }
      .menu .menu-content .navbar .forground {
        opacity: 0.8;
        visibility: visible;
        z-index: 10; }
      .menu .menu-content .navbar .back {
        display: block;
        text-align: right;
        background: transparent;
        border: unset;
        color: #6a4031;
        font-weight: bold;
        font-family: "Work Sans", sans-serif;
        font-size: 16px;
        cursor: pointer;
        padding: 15px;
        border-bottom: 1px solid #6a4031;
        width: 100%; }
        .menu .menu-content .navbar .back:after {
          content: "\f054";
          font: normal normal normal 14px/1 FontAwesome; }
      .menu .menu-content .navbar .navbar-collapse {
        position: fixed;
        transform: translateX(300px);
        top: 0;
        right: 0;
        width: 300px;
        background: #ffffff;
        height: 100vh;
        transition: all 0.5s; }
        .menu .menu-content .navbar .navbar-collapse.show {
          transform: translateX(0);
          height: 100vh;
          z-index: 9; }
        .menu .menu-content .navbar .navbar-collapse .navbar-nav {
          height: 100vh;
          text-align: left;
          padding-left: 15px; }
      .menu .menu-content .navbar .navbar-toggler {
        color: #6a4031; }
      .menu .menu-content .navbar .nav-item .nav-link:hover {
        background: #ffffff;
        color: #6a4031; }
  .sidenav-overly {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0;
    visibility: hidden;
    z-index: 9; }
  .mission-section .mission-image > div {
    margin-top: 30px; }
  .mission-section .box-container .box-item .flip-box .flip-box-back .inner h6,
  .mission-section .box-container .box-item .flip-box .flip-box-back .inner p {
    margin-bottom: 5px; }
  .mission-section .title_prime {
    text-align: center; }
    .mission-section .title_prime .para2 {
      margin-top: -10px; }
    .mission-section .title_prime h2 {
      max-width: 100%; }
    .mission-section .title_prime .icon-image {
      margin-left: 0; }
    .mission-section .title_prime .btn_dark {
      margin-top: 25px; }
  .tap-top {
    width: 40px;
    height: 40px;
    font-size: 22px;
    padding: 3px; }
  .theme-breadcrumb h2 {
    margin-top: 5px; }
  .about-page h4 {
    margin-top: -5px; }
  .call-to-action-section .contact {
    float: unset;
    margin: 0 0 5px auto;
    text-align: center; }
    .call-to-action-section .contact .media .media-body {
      padding-right: 15px; }
  .call-to-action-section .email-container {
    display: flex;
    justify-content: center;
    margin: 5px 0; }
    .call-to-action-section .email-container .email-box {
      float: unset; }
      .call-to-action-section .email-container .email-box .email {
        width: 432px;
        padding: 5px 20px; }
      .call-to-action-section .email-container .email-box .submit-btn {
        padding: 5px 20px; }
        .call-to-action-section .email-container .email-box .submit-btn i {
          padding-right: 5px; }
  .feature-section .title h5 {
    margin-top: 0; }
  .feature-section .bottle-image {
    margin-top: 50px;
    margin-bottom: -13px; }
  .feature-section .feature-box > div:nth-child(1) {
    margin-top: 100px; }
  .feature-section .feature-box > div:nth-child(2) {
    margin-top: 50px; }
  .mission-section .title_prime .para2 {
    padding-top: 20px; }
  .about-us-section .btn_dark, .service-section .btn_dark {
    margin-top: 25px; }
  .title_prime h2 {
    line-height: 40px;
    padding-top: 30px;
    max-width: 305px;
    margin-top: -10px; }
  .title_prime p {
    margin-top: -15px;
    padding-top: 30px; }
  .title_prime h5 {
    padding-top: 18px; }
    .title_prime h5:before {
      top: 28px; }
  .title_second {
    margin-bottom: 35px; }
    .title_second h5 {
      margin-top: 0;
      line-height: 13px; }
    .title_second h2 {
      padding-top: 10px; }
  .service-section .title_prime .btn {
    margin-top: 19px; }
  .service-section .image-container {
    margin: 40px 0; }
    .service-section .image-container .handle:before {
      height: 25px;
      width: 25px;
      border: 5px solid #ffffff; }
    .service-section .image-container .back-div {
      height: 101%; }
  .about-us-section .main-image {
    margin-bottom: 0; }
    .about-us-section .main-image h4 {
      top: 48px;
      right: -32%; }
    .about-us-section .main-image .sub-image {
      width: 145px; }
  .about-us-section .main-contain h2 {
    line-height: 35px;
    margin-top: -2px;
    padding-bottom: 25px; }
  .about-us-section .main-contain .para1 {
    padding-top: 20px; }
  .about-us-section .main-contain .para2 {
    padding-top: unset; }
  .about-us-section .main-contain .btn_lite {
    margin-top: 5px; }
  .hair_color_section .hair_color .main-box .main-color:before {
    height: 18px;
    width: 18px;
    border: 5px solid white; }
  .product_contain {
    padding: 10px 0 0 0; }
  .product_section .tab-content .product-row .rank {
    order: 1; }
  .advertise_section {
    padding: 35px 0;
    margin-top: 64px; }
    .advertise_section .bottle img {
      bottom: -6px;
      height: 304px;
      width: 100%; }
    .advertise_section .adv_contain .btn_dark {
      font-size: 14px;
      padding: 7px 15px;
      margin-top: 25px; }
  .brand_section .logo img {
    width: 80%; }
  .brand_section .brand > div:nth-child(1) {
    border-bottom: unset;
    border-image: linear-gradient(-90deg, #cab79a, #ffffff);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(2) {
    border-right: unset;
    border-left: 2px solid;
    border-bottom: unset;
    border-image: linear-gradient(0deg, #cab79a, #ffffff);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(3) {
    border-bottom: 2px solid;
    border-top: 2px solid;
    border-image: linear-gradient(90deg, #ffffff, #cab79a);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(5) {
    border-right: unset;
    border-left: unset; }
  .brand_section .brand > div:nth-child(4) {
    border-top: 2px solid;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg, #cab79a, #ffffff);
    border-image-slice: 1; }
  .brand_section .brand > div:nth-child(6) {
    border-left: 2px solid;
    border-image: linear-gradient(-180deg, #cab79a, #ffffff);
    border-image-slice: 1; }
  .testimonial_section .testimonial .space {
    padding: unset; }
    .testimonial_section .testimonial .space .test_image img {
      width: 115px; }
    .testimonial_section .testimonial .space .test_image .chat_icon {
      width: 55px; }
    .testimonial_section .testimonial .space .comment_box {
      text-align: center;
      margin-top: unset;
      margin-left: unset; }
      .testimonial_section .testimonial .space .comment_box .space {
        margin: 25px 25px 0; }
  .testimonial_section .rating {
    justify-content: center; }
  .testimonial_section .nav_image {
    padding-top: 0; }
    .testimonial_section .nav_image .slick-current img {
      transform: scale(1.5); }
  .testimonial_section .slick-slider .slick-prev {
    z-index: 5;
    top: 75%; }
  .testimonial_section .slick-slider .slick-next {
    top: 75%;
    z-index: 5; }
  .footer .footer-content .jvector-map-height {
    height: 165px;
    margin-bottom: -2px; }
    .footer .footer-content .jvector-map-height .jvectormap-container {
      height: 165px; }
      .footer .footer-content .jvector-map-height .jvectormap-container svg {
        height: 165px; }
  .collection-filter-block .product-service {
    padding: 0; }
  .collection-collapse-block {
    padding-bottom: 0; }
  .login-page .authentication-right {
    height: auto; }
  .color-selector ul li.active:after {
    right: 3px;
    width: 15px;
    height: 15px; }
  .testimonial .testimonial-slider .media .media-body {
    padding: 0 30px;
    height: 100%;
    align-self: center; }
    .testimonial .testimonial-slider .media .media-body p {
      margin-bottom: 0; }
  .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-left: none;
    padding-left: 0; }
  .blog-detail-page .blog-advance ul {
    margin-bottom: 20px; }
  .blog-detail-page .blog-advance p:last-child {
    margin-bottom: 0; }
  .top-banner-content h4 {
    font-size: 20px; }
  .collection-product-wrapper .product-top-filter {
    border-top: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
      border-top: 1px solid #6a4031; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
      display: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
      display: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
      width: 50%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
        right: 15px !important; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
        border-left: 1px solid #6a4031;
        padding: 21px 34px 21px 34px;
        border-top: none;
        border-bottom: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
      border-right: none !important; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      border-top: 1px solid #dddddd; }
  .filter-main-btn {
    display: block; }
  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    width: 320px;
    background-color: #ffffff;
    z-index: 99;
    overflow-y: scroll;
    padding: 15px 0 15px 15px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px #ddd; }
    .collection-filter .collection-sidebar-banner {
      text-align: center; }
    .collection-filter .theme-card {
      padding-left: 30px;
      padding-right: 30px;
      border: 0; }
    .collection-filter .theme-card .offer-slider .media .media-body a h6 {
      margin-right: 20px; }
    .collection-filter .theme-card .offer-slider .media .media-body .rating i {
      padding-right: 3px; }
    .collection-filter .theme-card.card-border .offer-slider {
      padding-top: 0; }
    .collection-filter .collection-sidebar-banner {
      padding: 0 30px; }
  .collection-filter-block {
    border: none; }
  .collection-mobile-back {
    display: block; }
  .collection .section-t-space {
    padding-top: 30px; }
  .collection .partition-collection > div:nth-last-child(1) {
    margin-top: 30px; }
  .collection .partition-collection > div:nth-last-child(2) {
    margin-top: 30px; }
  .right-login {
    margin-top: 30px; }
  .contact-page .map iframe {
    height: 350px; }
  .contact-page .contact-right {
    padding-bottom: 0; }
    .contact-page .contact-right ul li {
      padding-left: 0;
      border-bottom: 1px solid #dddddd;
      text-align: center;
      padding-bottom: 10px;
      margin-top: 15px; }
      .contact-page .contact-right ul li:last-child {
        border-bottom: 0; }
      .contact-page .contact-right ul li .contact-icon {
        position: relative;
        margin: 0 auto;
        border-right: 0; }
      .contact-page .contact-right ul li p {
        margin-bottom: 10px; }
  .cart-section .cart-table thead th:last-child {
    display: none; }
  .cart-section tbody tr td {
    min-width: 115px; }
    .cart-section tbody tr td:last-child {
      display: none; }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 15px; }
  .cart-section tfoot tr td {
    padding-right: 0; }
  .wishlist-section tbody tr td {
    min-width: 106px; }
  .product-right h2 {
    margin-top: 15px; }
  .product-right .product-buttons {
    justify-content: center; }
  .product-right {
    text-align: center;
    margin: 20px 0 10px 0; }
    .product-right .detail-section,
    .product-right .product-icon {
      justify-content: center; }
    .product-right .product-description .qty-box {
      justify-content: center; }
    .product-right .size-text {
      text-align: left; }
    .product-right .timer {
      text-align: left; }
    .product-right .product-icon .product-social li {
      padding-right: 20px; }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 55%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 20px;
    background: none #6a4031; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .open-popup {
      text-align: left; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup > a {
      color: #ffffff; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
      height: 100vh;
      left: -350px;
      transition: all 0.5s ease; }
  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    width: 100%;
    padding: 10px 0;
    text-align: center; }
  .collection-product-wrapper .product-top-filter .popup-filter .collection-view,
  .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    display: none; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 50%; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
      padding-top: 10px;
      padding-bottom: 10px; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
      top: 13px; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    left: unset;
    right: 35px; }
  .tab-product {
    padding-top: 0; }
  .tab-product .nav-material.nav-tabs {
    justify-content: center; }
  .pro_sticky_info {
    border: none;
    padding: 10px 0; }
  .product-form-box {
    margin-bottom: 10px; }
  .dashboard-left {
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 25px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 #dddddd; }
    .dashboard-left .block-title h2 {
      display: none; }
    .dashboard-left .block-content {
      border: none;
      padding: 0;
      margin-top: 20px; }
      .dashboard-left .block-content ul li:hover {
        padding-left: 0; }
  .account-sidebar {
    display: block; }
    .account-sidebar .popup-btn {
      color: #ffffff; }
  .typography_section .row > div:first-child .typography-box:last-child {
    margin-bottom: 30px; }
  .product-slick .slick-prev,
  .product-slick .slick-next,
  .rtl-product-slick .slick-prev,
  .rtl-product-slick .slick-next,
  .product-right-slick .slick-prev,
  .product-right-slick .slick-next,
  .rtl-product-right-slick .slick-prev,
  .rtl-product-right-slick .slick-next {
    opacity: 1; }
  .product-slick .slick-prev,
  .rtl-product-slick .slick-prev,
  .product-right-slick .slick-prev,
  .rtl-product-right-slick .slick-prev {
    left: 20px; }
  .product-slick .slick-next,
  .rtl-product-slick .slick-next,
  .product-right-slick .slick-next,
  .rtl-product-right-slick .slick-next {
    right: 20px; }
  .order-up {
    order: -1; }
  .bundle_img {
    justify-content: center; }
  .bundle_detail .theme_checkbox {
    padding: 0 20%; } }

@media (max-width: 768px) {
  .home-slider.slick-slider {
    display: block; }
  .delivery-sec h2 {
    margin-bottom: -5px; }
  .footer .footer-content .media i {
    font-size: 16px; }
  .pwd-page .theme-form input {
    width: 100%; }
  .service-section .image-container .ba-slider .before-lable {
    left: -10px; }
  .service-section .image-container .ba-slider .after-lable {
    right: -10px; } }

@media (max-width: 767px) {
  section {
    padding: 50px 0; }
  .section-t-space {
    padding-top: 50px; }
  .section-b-space {
    padding-bottom: 50px; }
  .about-us-section .main-contain h2 {
    margin-top: 15px; }
  .about-us-section .btn_dark, .service-section .btn_dark {
    margin-top: 20px; }
  .menu .menu-content {
    padding: 0; }
  .btn_lite {
    padding: 8px 10px; }
  .animation-section .decor-animation {
    display: none; }
  .prodcut_left_margin > div:nth-last-child(1), .prodcut_left_margin > div:nth-last-child(4), .prodcut_left_margin > div:nth-last-child(3) {
    margin-top: 30px; }
  .blog-page .order-sec {
    order: -1; }
  .blog-page .blog-media {
    margin-bottom: 0; }
    .blog-page .blog-media .blog-right {
      margin-bottom: 30px; }
      .blog-page .blog-media .blog-right.blog-padding {
        margin-bottom: -0px; }
  .blog-detail-page .blog-detail p {
    line-height: 1.3; }
  .home-slider.slick-slider:hover .slick-prev {
    left: 30px; }
  .home-slider.slick-slider:hover .slick-next {
    right: 30px; }
  .home-slider .main_section {
    height: 55vh; }
    .home-slider .main_section .main_section_contain {
      height: 55vh; }
      .home-slider .main_section .main_section_contain h1 {
        line-height: 40px;
        letter-spacing: 0.01em;
        font-size: 30px; }
      .home-slider .main_section .main_section_contain p {
        padding-top: 70px;
        padding-bottom: 60px;
        line-height: 20px; }
      .home-slider .main_section .main_section_contain .new {
        margin-bottom: 0;
        margin-left: -15px;
        margin-top: 8px; }
  .call-to-action-section .email-container .email-box .email {
    width: 375px; }
  .footer .links {
    padding: 0 15px; }
  .footer .footer-title h5 {
    margin-bottom: 10px; }
  .footer .footer-content .jvector-map-height {
    height: 145px;
    margin-bottom: -13px; }
    .footer .footer-content .jvector-map-height .jvectormap-container {
      height: 145px; }
      .footer .footer-content .jvector-map-height .jvectormap-container svg {
        height: 145px; }
  .footer .footer-content .media i {
    font-size: 16px; }
  .footer .footer-content .media .media-body {
    max-width: 80%; }
  .feature-section .bottle-image {
    display: none; }
  .feature-section .box {
    margin-top: 40px; }
  .feature-section .feature-box .last-para {
    margin-bottom: -3px; }
  .feature-section .feature-box > div:nth-child(1) {
    margin-top: 0; }
  .feature-section .feature-box > div:nth-child(2) {
    margin-top: 0; }
  .feature-section .feature-box .features-rtl {
    text-align: unset; }
    .feature-section .feature-box .features-rtl img {
      position: absolute;
      left: 0; }
    .feature-section .feature-box .features-rtl .media-body {
      margin-left: 67px; }
  .title_prime {
    text-align: center; }
    .title_prime .icon-image {
      margin-left: unset; }
    .title_prime h2 {
      padding-top: 20px;
      max-width: unset; }
    .title_prime h5 {
      padding-top: 15px; }
      .title_prime h5:before {
        top: 25px; }
  .title_second {
    margin-bottom: 27px; }
    .title_second h5 {
      margin-top: 0;
      line-height: 16px; }
    .title_second h2 {
      padding-top: 10px; }
  .mission-section .mission-image > div {
    margin-top: 30px; }
  .mission-section .box-container .box-item .flip-box .flip-box-back .inner h6,
  .mission-section .box-container .box-item .flip-box .flip-box-back .inner p {
    margin-bottom: 5px; }
  .mission-section .title_prime .para2 {
    margin-top: -10px; }
  .mission-section .title_prime .btn_dark {
    margin-top: 20px; }
  .service-section .title_prime .btn {
    margin-bottom: 20px;
    padding-left: 0;
    margin-top: 17px; }
    .service-section .title_prime .btn:before {
      width: 50px; }
  .service-section .image-container {
    margin: 75px 0 50px 0; }
    .service-section .image-container .back-div {
      height: 120%; }
  .about-us-section .main-image {
    width: 350px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 5px; }
    .about-us-section .main-image h4 {
      top: 42px;
      right: -22%; }
    .about-us-section .main-image .sub-image {
      width: 145px; }
  .about-us-section .main-contain {
    text-align: center; }
    .about-us-section .main-contain .para1:before {
      left: 50%;
      transform: translate(-50%, 0); }
  .hair_color_section .hair_color .main-box .main-color {
    transform: translateY(5px); }
  .product_box .product_image .timer-box .timer .padding-l {
    padding-left: 10px; }
  .product_box .product_image .timer-box .timer span {
    width: 45px; }
    .product_box .product_image .timer-box .timer span .timer-cal {
      padding-left: 5px; }
  .product_box .product_image ul > li {
    z-index: 5; }
    .product_box .product_image ul > li:nth-child(2) {
      opacity: 1;
      transform: translateY(0); }
    .product_box .product_image ul > li:nth-child(3) {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s; }
    .product_box .product_image ul > li:nth-child(4) {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.4s; }
  .product_box .product_image .product_icon i {
    padding: 5px; }
  .product_section ul.tabs li {
    padding: 5px 15px; }
  .advertise_section {
    padding: 30px 0;
    margin-top: 28px; }
    .advertise_section .bottle img {
      bottom: 15px;
      height: 228px; }
    .advertise_section .adv_contain p {
      padding-top: 15px; }
    .advertise_section .adv_contain .btn_dark {
      margin-top: 20px; }
  .testimonial_section .testimonial .space .test_image img {
    width: 100px; }
  .testimonial_section .testimonial .space .test_image .chat_icon {
    width: 50px; }
  .testimonial_section .nav_image {
    margin-bottom: 0; }
  .testimonial_section .nav_img {
    padding: 10px 13%; }
  .testimonial_section .slick-slider .slick-prev, .testimonial_section .slick-slider .slick-next {
    display: none; }
  .cart-section tbody tr td .icon,
  .wishlist-section tbody tr td .icon {
    margin-right: 0 !important; }
  .cart-section tbody tr td a,
  .wishlist-section tbody tr td a {
    justify-content: center;
    display: flex;
    align-items: center; }
  .cart-section .cart-table thead th:nth-last-child(-n + 4) {
    display: none; }
  .cart-section tbody tr td:nth-last-child(-n + 4) {
    display: none; }
  .cart-section tbody tr td .mobile-cart-content {
    display: flex; }
  .product-pagination {
    margin: 20px 0 50px 0; }
  .cart-section tbody tr td a,
  .wishlist-section tbody tr td a {
    justify-content: center;
    display: flex;
    align-items: center; }
  .cart-section .cart-table thead th:nth-last-child(-n + 4) {
    display: none; }
  .cart-section tbody tr td:nth-last-child(-n + 4) {
    display: none; }
  .search-product > div:nth-child(11) {
    margin-top: 30px; }
  .search-product > div:nth-child(12) {
    margin-top: 30px; }
  .wishlist-section .cart-table thead th:nth-last-child(-n + 3) {
    display: none; }
  .wishlist-section tbody tr td:nth-last-child(-n + 3) {
    display: none; }
  .wishlist-section tbody tr td .mobile-cart-content {
    display: flex; }
  .product-order .product-order-detail img {
    height: auto; }
  .collection-product-wrapper .product-pagination .pagination {
    justify-content: center;
    border-left: 1px solid #6a4031; }
    .collection-product-wrapper .product-pagination .pagination .page-item a {
      padding: 15px 18px; }
  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    justify-content: center;
    padding-right: 0; }
    .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
      padding: 10px 0; }
  .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: 1px solid #6a4031;
    border-bottom: 1px solid #6a4031; }
  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important; }
  .faq-section .theme-accordion .card .card-header button {
    font-size: 14px !important;
    white-space: normal;
    width: 100%;
    padding: 16px 30px 16px 18px !important;
    text-transform: uppercase; }
  .auth-page .theme-card .theme-form .form-row {
    display: block; }
    .auth-page .theme-card .theme-form .form-row > div:nth-child(n+2) {
      margin-left: auto; } }

@media (max-width: 577px) {
  .alert {
    right: 0 !important;
    left: 0;
    max-width: 90% !important; }
  .portfolio-section .filter-button {
    font-size: 16px;
    padding: 0 10px;
    line-height: 30px; }
  .filter-section .filter-container ul.filter > li {
    padding: 5px 10px; }
    .filter-section .filter-container ul.filter > li > a {
      font-size: 16px;
      line-height: 13px; }
  .home-slider .main_section .main_section_contain h1 {
    font-size: 25px;
    line-height: 35px; }
  .advertise_section .bottle img {
    height: 242px; }
  .search-block .form-header {
    padding: 15px 0 0 0; }
  .pwd-page .theme-form input {
    width: 100%; }
  .pwd-page .forget-btn {
    margin-top: 25px; }
  .blog-detail-page .blog-detail .post-social {
    display: block; } }

@media (max-width: 576px) {
  section {
    padding: 40px 0; }
  .section-t-space {
    padding-top: 40px; }
  .footer .portfolio {
    overflow: hidden; }
  .footer .row > div + div {
    margin-top: 10px !important; }
  .footer .row > div:first-child .footer-title {
    padding-top: 0; }
  .order_detail {
    padding: 0; }
  .section-b-space {
    padding-bottom: 40px; }
  .small-section {
    padding-top: 30px;
    padding-bottom: 30px; }
  .common-card {
    padding: 20px; }
  .dashboard .box-account > div + div {
    margin-top: 0px; }
  .portfolio-section .isotopeSelector {
    margin-bottom: 15px; }
  .checkout-page .checkout-form .form-group .field-label {
    margin-bottom: 5px; }
  .dashboard .box .box-title > a {
    position: relative;
    padding-bottom: 10px;
    display: block; }
  .product_contain .card-title {
    margin-bottom: 5px; }
  .prodcut_left_margin > div {
    margin-top: 30px; }
    .prodcut_left_margin > div:first-child {
      margin-top: 0; }
  .home-slider .main_section .main_section_contain .new {
    margin-top: 5px; }
  .service-section .image-container {
    margin: 70px 0 40px 0; }
  .top_header {
    display: none; }
  .menu {
    top: 0;
    padding: 5px; }
    .menu .menu-content .main_menu_right {
      z-index: 9;
      position: fixed;
      bottom: 0;
      background: linear-gradient(270deg, #978262, #cbbaa6, #978262);
      height: unset !important;
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center; }
      .menu .menu-content .main_menu_right > button a {
        color: #6a4031; }
      .menu .menu-content .main_menu_right > button + button {
        margin-left: unset; }
      .menu .menu-content .main_menu_right .setting {
        display: block; }
      .menu .menu-content .main_menu_right button {
        width: 25%;
        color: #6a4031;
        border: unset;
        background: unset;
        padding: 10px; }
        .menu .menu-content .main_menu_right button span {
          font-size: 20px; }
        .menu .menu-content .main_menu_right button:hover {
          background-color: transparent; }
    .menu .menu-content .toggle-nav {
      margin-right: 0;
      padding-top: 20px;
      padding-bottom: 20px; }
    .menu .menu-content .brand-logo img {
      width: 70%; }
  .main_section {
    height: 45vh; }
    .main_section .main_section_contain {
      height: 45vh; }
      .main_section .main_section_contain h1 {
        line-height: 35px; }
      .main_section .main_section_contain .new {
        margin-bottom: -1px;
        margin-left: -20px;
        margin-top: 19px; }
  .title_prime .icon-image {
    width: 45px; }
  .title_prime h2 {
    padding-top: 15px; }
  .title_prime p {
    margin-top: -16px; }
  .title_prime h5 {
    padding-top: 10px; }
    .title_prime h5:before {
      top: 20px; }
  .title_second {
    margin-bottom: 27px; }
    .title_second h5 {
      margin-top: -2px; }
  .service-section .image-container .ba-slider .after-lable {
    display: none; }
  .service-section .image-container .resize .before-lable {
    display: none; }
  .feature-section .box {
    margin-top: 35px; }
  .feature-section .title h5 {
    margin-top: -1px; }
  .feature-section .feature-box .last-para {
    margin-bottom: -2px; }
  .footer .links {
    padding-left: 15px;
    padding-right: 15px; }
  .footer .footer-title {
    display: block; }
  .footer .footer-content {
    padding: 10px 0; }
  .footer .footer-title {
    padding: 10px 0;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative; }
    .footer .footer-title h5 {
      margin-bottom: 0;
      cursor: pointer; }
    .footer .footer-title.active .according-menu {
      font: normal normal normal 15px/1 FontAwesome; }
      .footer .footer-title.active .according-menu:before {
        content: "\f106";
        position: absolute;
        right: 2px;
        bottom: 10px; }
    .footer .footer-title .according-menu {
      font: normal normal normal 15px/1 FontAwesome; }
      .footer .footer-title .according-menu:before {
        content: "\f107";
        position: absolute;
        right: 2px;
        bottom: 10px; }
  .footer .footer-title .according-menu:before {
    bottom: 18px; }
  .call-to-action-section .contact .media .media-body {
    padding-right: 10px; }
  .call-to-action-section .contact .newsletter {
    padding-left: 10px; }
  .call-to-action-section .email-container .email-box .email {
    width: 350px; }
  .about-us-section .main-image h4 {
    position: relative;
    top: 0;
    right: 0;
    transform: rotate(0);
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0; }
  .about-us-section .main-contain h2 {
    line-height: 30px;
    margin-top: 0; }
  .product_section .tab-content .product-row > div:nth-child(n) {
    margin-top: 30px; }
  .product_section ul.tabs {
    margin-top: 5px; }
  .advertise_section {
    margin-top: 0; }
    .advertise_section .bottle {
      display: none; }
      .advertise_section .bottle img {
        height: 242px; }
    .advertise_section .adv_contain {
      text-align: center; }
      .advertise_section .adv_contain p {
        padding-top: 20px; }
  .brand_section .logo {
    padding: 30px 0; }
    .brand_section .logo img {
      width: 75%; }
  .brand_section .brand > div + div:nth-child(n) {
    border-top: 2px solid;
    border-image-slice: 1;
    border-image: radial-gradient(#cab79a, #ffffff);
    background-repeat: no-repeat; }
  .brand_section .brand > div + div:nth-child(2), .brand_section .brand > div + div:nth-child(3) {
    border-bottom: unset;
    border-top: 2px solid;
    border-image: radial-gradient(#cab79a, #ffffff);
    border-image-slice: 1; }
  .brand_section .brand > div + div:nth-child(5) {
    border-right: unset;
    border-left: unset; }
  .brand_section .brand > div + div:nth-child(4) {
    border-top: 2px solid;
    border-image: radial-gradient(#cab79a, #ffffff);
    border-image-slice: 1;
    border-left: unset;
    border-bottom: 2px solid; }
  .brand_section .brand > div + div:nth-child(6) {
    border-bottom: unset;
    border-image: radial-gradient(#cab79a, #ffffff);
    border-image-slice: 1;
    border-left: unset; }
  .brand_section .brand > div:first-child .logo {
    padding-top: 0; }
  .brand_section .brand > div:last-child .logo {
    padding-bottom: 0; }
  .testimonial_section .testimonial .space .test_image img {
    width: 90px; }
  .testimonial_section .testimonial .space .test_image .chat_icon {
    width: 45px; }
  .testimonial_section .nav_image {
    margin-bottom: 0;
    padding-top: 0; }
  .testimonial_section .slick-slider .slick-prev {
    display: none !important; }
  .testimonial_section .slick-slider .slick-next {
    display: none !important; }
  .testimonial_section .slick-slider .slick-prev:before {
    font-size: 25px; }
  .testimonial_section .slick-slider .slick-next:before {
    color: #6a4031;
    font-size: 25px; }
  .checkout-page .checkout-form .form-group {
    margin-bottom: 20px; }
  .checkout-page .checkout-form input[type="text"], .checkout-page .checkout-form input[type="email"], .checkout-page .checkout-form input[type="password"], .checkout-page .checkout-form input[type="tel"], .checkout-page .checkout-form input[type="number"], .checkout-page .checkout-form input[type="url"] {
    height: 38px; }
  .template-password #container #login {
    margin-bottom: 0; }
  .login-page .theme-card .theme-form input {
    padding: 10px 16px; }
  .error-section {
    padding: 100px 0; }
    .error-section h1 {
      font-size: 100px;
      line-height: 1.4; }
    .error-section h2 {
      margin: 20px 0;
      font-size: 18px; }
    .error-section .btn_dark {
      padding: 10px 25px;
      font-size: 14px; }
  .about-page .about-head {
    display: inline-block;
    width: 100%;
    text-align: center; }
    .about-page .about-head .theme-breadcrumb .breadcrumb {
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 0; }
  .search-block .form-header {
    padding-top: 10px; }
  .blog-detail-page .blog-detail .post-social li {
    display: block; }
    .blog-detail-page .blog-detail .post-social li + li {
      padding-left: 0;
      margin-left: 0;
      border-left: none;
      display: block; }
  .collection .collection-block .collection-content p {
    margin-bottom: 10px; }
  .collection .collection-block .collection-content h4,
  .collection .collection-block .collection-content h3 {
    margin-bottom: 5px; }
  .collection-product-wrapper .product-wrapper-grid .product-box {
    margin-top: 30px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    padding: 10px 20px 10px 20px; }
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
      font-size: 14px;
      line-height: 20px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 100%; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
      padding: 10px 20px 10px 20px; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
      top: 11px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-right: 1px solid #6a4031 !important;
    border-bottom: 1px solid #6a4031; }
  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    padding-top: 10px;
    padding-bottom: 10px; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-bottom: 1px solid #6a4031;
    width: 100%; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
      border-right: none;
      padding-top: 10px;
      padding-bottom: 10px; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
      top: 14px; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 100%;
    border-top: none; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
      padding-top: 10px;
      padding-bottom: 10px; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
      left: unset;
      right: 35px !important;
      top: 14px; }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 15px; }
  .cart-section .cart-buttons .btn_dark {
    padding: 7px 8px; }
  .wishlist-section .btn_dark {
    padding: 7px 6px; }
  .collection-wrapper .order-up {
    order: -1; }
  .checkout-page .checkout-form .btn_dark {
    padding: 10px 25px;
    font-size: 14px; }
  .search-product > div:nth-child(10) {
    margin-top: 30px; }
  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 12px;
    padding: 13px 8px; }
  .product-box .img-block .lable-wrapper .lable1,
  .product-box .img-block .lable-wrapper .lable2 {
    padding: 6px 9px 8px 15px;
    font-size: 12px; }
  .tab-product .tab-content.nav-material p,
  .product-full-tab .tab-content.nav-material p {
    padding: 15px 0; }
  .tab-product .tab-content.nav-material p {
    line-height: 1.3; }
  .slider-right-nav .slick-slide:first-child > div {
    margin-top: 15px; }
  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 90px;
    top: -70px; }
    .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
      line-height: 16px; }
  .layout7-product .product-box .details-product {
    padding: 5px; }
  .layout7-product .product-box:hover .details-product {
    animation: none; }
  .padding-cls {
    padding-left: 15px;
    padding-right: 15px; }
  .success-text i {
    font-size: 40px; }
  .success-text p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.2; }
  .success-text h2 {
    margin-bottom: 10px; }
  .order-success-sec > div {
    margin-top: 15px; }
  .delivery-sec {
    padding: 15px;
    margin-top: 15px; }
    .delivery-sec h3,
    .delivery-sec h2 {
      font-size: 20px; }
  .product-order h3 {
    font-size: 20px; }
  .product-order .product-order-detail .order_detail h4,
  .product-order .product-order-detail .order_detail h5 {
    font-size: 14px;
    line-height: 1.2; }
  .product-order .total-sec ul li {
    font-size: 16px; }
  .product-order .final-total h3 {
    font-size: 16px; }
  .beauty-about .service .service-block1 {
    margin-bottom: 20px; }
    .beauty-about .service .service-block1:last-child {
      margin-bottom: 0; }
  .main-menu .menu-left .navbar i {
    font-size: 24px; }
  .typography_section .typography-box .typo-content.typo-buttons a:last-child {
    margin-top: 0; }
  .typography_section .typography-box .typo-content.typo-buttons .btn_dark.btn-sm {
    padding: 3px 12px; } }

@media (max-width: 575px) {
  .working-hourse-main .working-hourse-icon > div .logo {
    padding-bottom: 20px; }
  .working-hourse-main .working-hourse-icon > div:nth-child(n+4) .logo {
    padding-top: 0; }
  .working-hourse-main .working-hourse-icon > div:nth-child(1), .working-hourse-main .working-hourse-icon > div:nth-child(2), .working-hourse-main .working-hourse-icon > div:nth-child(3), .working-hourse-main .working-hourse-icon > div:nth-child(4), .working-hourse-main .working-hourse-icon > div:nth-child(5), .working-hourse-main .working-hourse-icon > div:nth-child(6) {
    border: 0; }
  .working-hourse-main .working-hourse-block > div:nth-child(2) {
    padding-bottom: 0; }
  .working-hourse-main .working-hourse-contain ul li {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (max-width: 480px) {
  section {
    padding: 30px 0; }
  .section-t-space {
    padding-top: 30px; }
  .section-b-space {
    padding-bottom: 30px; }
  .copyright {
    padding: 10px; }
  .checkout-form {
    margin-bottom: 20px; }
  .pwd-page .forget-btn {
    margin-top: 15px; }
  .home-slider .main_section {
    height: 40vh; }
    .home-slider .main_section .main_section_contain {
      height: 40vh; }
      .home-slider .main_section .main_section_contain .new {
        margin-bottom: -5px;
        margin-left: -20px;
        margin-top: 2px; }
      .home-slider .main_section .main_section_contain p {
        line-height: 20px; }
  .call-to-action-section .contact {
    display: unset;
    justify-content: unset;
    align-items: unset;
    float: unset;
    text-align: center; }
    .call-to-action-section .contact .media {
      display: block; }
      .call-to-action-section .contact .media .media-body {
        padding-right: unset;
        padding-bottom: 10px; }
      .call-to-action-section .contact .media svg {
        margin-right: unset; }
    .call-to-action-section .contact .newsletter {
      padding-left: unset;
      border-left: unset;
      border-top: 2px solid #a58e77;
      padding-top: 10px; }
    .call-to-action-section .contact .media-body {
      padding-right: unset; }
  .feature-section .box {
    margin-top: 30px; }
  .title_prime .icon-image {
    width: 40px; }
  .title_prime p {
    margin-top: -10px;
    padding-top: 20px; }
  .title_second {
    margin-bottom: 17px; }
  .mission-section .title_prime .btn_dark {
    margin-top: 20px; }
  .service-section .image-container {
    margin: 50px 0 30px 0; }
  .service-section .title_prime .btn {
    padding-left: 0;
    margin-top: 12px;
    margin-bottom: 15px; }
    .service-section .title_prime .btn:before {
      width: 40px; }
  .about-us-section .main-image .sub-image {
    display: none; }
  .product_box .product_image .product_icon i {
    padding: 10px; }
  .testimonial_section .testimonial .comment_box .rating {
    display: unset;
    align-items: center;
    justify-content: center; }
    .testimonial_section .testimonial .comment_box .rating h6 {
      padding-left: 0 !important;
      padding-top: 5px; }
    .testimonial_section .testimonial .comment_box .rating p {
      padding-top: 5px; }
    .testimonial_section .testimonial .comment_box .rating .reg {
      padding-left: 0 !important; }
  .testimonial_section .testimonial .slick-slider .slick-prev:before {
    font-size: 25px; }
  .testimonial_section .testimonial .slick-slider .slick-next:before {
    color: #6a4031;
    font-size: 25px; }
  .theme-breadcrumb h2 {
    margin-top: 3px; }
  .review-page .comnt-sec {
    justify-content: center; }
  .select_input select {
    padding: 12px 15px;
    margin-bottom: 18px; }
  .about-page h4 {
    margin-top: -4px; }
  .about-page p {
    line-height: 1.5;
    margin-bottom: -3px; }
  .testimonial .testimonial-slider .media {
    display: inline-block; }
    .testimonial .testimonial-slider .media img {
      margin: 0 auto;
      height: 100px;
      width: 100px; }
    .testimonial .testimonial-slider .media .media-body {
      padding: 0;
      text-align: center; }
  .blog-detail-page .comment-section li img {
    margin-right: unset; }
  .blog-detail-page .blog-contact h2 {
    font-size: 30px;
    margin-bottom: 25px; }
  .blog-detail-page .blog-contact .theme-form input,
  .blog-detail-page .blog-contact .theme-form textarea {
    padding: 12px 20px;
    margin-bottom: 20px; }
  .blog-detail-page .blog-detail h3 {
    font-size: 16px;
    margin-bottom: 10px; }
  .blog-detail-page .blog-detail img {
    margin-bottom: 25px; }
  .blog-detail-page .blog-advance ul {
    line-height: 1.5; }
  .blog-detail-page .blog-advance img {
    margin-bottom: 20px;
    margin-top: 10px; }
  .blog-detail-page .blog-advance p {
    line-height: 1.5; }
  .blog-detail-page .comment-section li {
    padding-top: 30px;
    padding-bottom: 30px; }
    .blog-detail-page .comment-section li p {
      line-height: 1.5; }
    .blog-detail-page .comment-section li h6 {
      margin-top: 10px;
      margin-bottom: 10px; }
      .blog-detail-page .comment-section li h6 span {
        display: inherit;
        margin-left: 0; }
  .blog-detail-page .comment-section .media {
    display: inline-block;
    text-align: center; }
  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important; }
  .faq-section .theme-accordion .card .card-header button {
    padding: 12px 35px 12px 12px !important; }
  .faq-section .accordion.theme-accordion .card .card-header button:before {
    top: 20px; }
  .pwd-page {
    padding: 70px 0; }
    .pwd-page h2 {
      font-size: 20px;
      margin-bottom: 15px; }
  .login-page .authentication-right h6 {
    margin-bottom: 10px; }
  .login-page .theme-card {
    padding: 25px; }
  .auth-page .theme-card {
    padding: 18px; }
    .auth-page .theme-card .theme-form input {
      margin-bottom: 20px;
      padding: 12px 15px; }
  .contact-page .map iframe {
    height: 275px; }
  .contact-page .theme-form input {
    padding: 12px 15px;
    margin-bottom: 18px; }
  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 0; }
  .blog-page .blog-media .blog-right p {
    line-height: 1.3; }
  .blog-page .blog-media .blog-right h4 {
    line-height: 1.2; }
  .blog-page .blog-media .blog-right ul {
    margin-bottom: 5px; }
    .blog-page .blog-media .blog-right ul li {
      display: block; }
      .blog-page .blog-media .blog-right ul li + li {
        padding-left: 0;
        margin-left: 0;
        border-left: none; }
  .account-sidebar {
    width: 45%; }
  .success-text h2 {
    font-size: 20px; }
  .success-text p {
    font-size: 14px; }
  .delivery-sec h3,
  .delivery-sec h2 {
    font-size: 18px; }
  .typography_section .typography-box .typo-content.typo-buttons a {
    margin: 5px 0; } }

@media (max-width: 420px) {
  .home-slider .main_section .main_section_contain .new {
    margin-top: 0; }
  .product-right .product-buttons .btn-solid,
  .product-right .product-buttons .btn-outline {
    padding: 7px 13px; }
  .product-right.product-form-box .timer {
    padding-left: 29px; }
    .product-right.product-form-box .timer span {
      width: 45px; }
  .product-right .timer {
    padding-left: 35px; }
    .product-right .timer span {
      width: 50px; }
  .product-right .product-icon .product-social {
    margin-top: 0; }
    .product-right .product-icon .product-social li {
      padding-right: 5px; }
      .product-right .product-icon .product-social li a i {
        font-size: 14px; }
  .product-right .product-icon .wishlist-btn i {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 5px; }
  .product-right .product-icon .wishlist-btn span {
    font-size: 14px; }
  .tab-product .nav-material.nav-tabs {
    display: block; }
    .tab-product .nav-material.nav-tabs .nav-item {
      width: 100%; }
      .tab-product .nav-material.nav-tabs .nav-item .nav-link {
        padding: 10px 20px; }
  .order-box .sub-total .shipping {
    width: unset;
    float: unset;
    display: flex; }
    .order-box .sub-total .shipping .shopping-option:last-child {
      padding-left: 20px; }
  .top-banner-wrapper .top-banner-content p {
    line-height: 1.4; } }

@media (max-width: 360px) {
  .quick-view-modal .product-right .product-buttons a:last-child {
    margin-left: 0; }
  .quick-view-modal .product-right .product-buttons .btn_dark {
    padding: 8px;
    font-size: 12px; }
  .wishlist-buttons .btn {
    width: 100%; }
    .wishlist-buttons .btn + .btn {
      margin-top: 10px; }
  .search-block .form-header .input-group .btn {
    padding: 10px 5px; }
  .search-block .form-header .input-group input {
    padding: 10px 12px; }
  .home-slider .main_section {
    height: 35vh; }
    .home-slider .main_section .main_section_contain {
      height: 35vh; }
      .home-slider .main_section .main_section_contain .new {
        margin-top: 0;
        margin-bottom: -6px;
        margin-left: 0px;
        font-size: 18px; }
      .home-slider .main_section .main_section_contain h1 {
        line-height: 20px;
        font-size: 16px; }
      .home-slider .main_section .main_section_contain p {
        padding-bottom: 55px;
        padding-top: 75px;
        line-height: 17px; }
      .home-slider .main_section .main_section_contain .btn_dark {
        padding: 8px 7px; }
  .about-us-section .main-image {
    width: auto; }
  .about-us-section .main-contain h2 {
    line-height: 25px; }
  .about-us-section .main-contain .para1 {
    padding-top: 20px; }
  .about-us-section .main-contain .btn_lite {
    margin-top: unset; }
  .product_box .product_image .product_icon i {
    padding: 5px; }
  .call-to-action-section .email-container .email-box .email {
    width: 300px; }
  .product_section ul.tabs li {
    padding: 5px 12px; }
  .title_prime .icon-image {
    width: 30px; }
  .title_prime p {
    padding-top: 15px; }
  .title_prime h2 {
    padding-top: 10px; }
  .title_second h2 {
    padding-top: 5px; }
  .testimonial_section .nav_image {
    padding-top: 0; }
  .testimonial_section .slick-slider .slick-prev:before {
    font-size: 20px; }
  .testimonial_section .slick-slider .slick-next:before {
    color: #6a4031;
    font-size: 20px; }
  .feature-section .title h5 {
    margin-top: -3px; }
  .product-right .product-buttons .btn_dark {
    padding: 8px 10px;
    font-size: 14px; }
  .search-block .form-header .form-control {
    font-size: 14px; }
  .cart-section .wishlist-buttons:last-child a,
  .wishlist-section .wishlist-buttons:last-child a {
    margin-left: 0; }
  .cart-section tbody tr td,
  .wishlist-section tbody tr td {
    min-width: 110px; }
    .cart-section tbody tr td .mobile-cart-content .col-xs-3,
    .wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
      margin-left: 5px;
      margin-right: 5px; }
    .cart-section tbody tr td a img,
    .wishlist-section tbody tr td a img {
      height: 80px; }
  .cart-section tfoot tr td,
  .wishlist-section tfoot tr td {
    padding-right: 27px; }
    .cart-section tfoot tr td h2,
    .wishlist-section tfoot tr td h2 {
      font-size: 21px; }
  .blog-detail-page .blog-contact h2 {
    font-size: 20px;
    margin-bottom: 20px; }
  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 10px; }
  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 10px; }
    .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
      line-height: 15px; }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 80%; }
  .tap-top {
    width: 30px;
    height: 30px;
    font-size: 15px;
    padding: 3px; } }

@media (max-width: 320px) {
  section {
    padding: 30px 0; }
  .title_prime h2 {
    line-height: 30px;
    letter-spacing: 0.01em; }
  .title_prime p {
    margin-top: -16px; }
  .title_second {
    margin-bottom: 15px; }
  .home-slider .main_section .main_section_contain .new {
    margin-bottom: -6px;
    margin-left: -25px; }
  .service-section .title_prime .btn {
    margin-top: 5px; }
  .testimonial_section .slick-slider .slick-prev:before {
    font-size: 15px; }
  .testimonial_section .slick-slider .slick-next:before {
    color: #6a4031;
    font-size: 15px; }
  .product-right .product-buttons {
    display: flex; }
  .call-to-action-section .email-container .email-box .email {
    width: 275px; } }
