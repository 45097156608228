/*-----------------------------------------------------------------------------------

    Template Name:Salon and Barber
    Template URI: 
    Description: This is E-commerce website
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

----------------------------------------------------------------------------------- */
/* 1.0. General
   1.1. Button
   1.2. Title */
/* 2. Header */
/* 3. Home slider  */
/* 4. Instagram */
/* 5. Brand Logo */
/* 6. Testimonial */
/* 7. Blog */
/* 8. Advertise */
/* 9. Product_section */
/* 10. New Arrivals*/
/* 11. hair_color*/
/* 12. About-us*/
/* 13. Service*/
/* 14. Mission*/
/* 15. Feature*/
/* 16. Call-to-action */
/* 17. Portfolio*/
/* 18. Add to cart*/
/* 19. Animation*/
/* 20. Footer*/
/* 21. Loader*/
/* 22. Tap to top*/
/* 23.Inner-page */
/* 24. Menu */
/* 25. Responsive */

/*=====================
    1.0.General
=======================*/

.container, .container-fluid{
  --bs-gutter-x : 15px !important;
}
.row {
  --bs-gutter-x : 30px !important;
}


section {
  padding: 100px 0;
}
.section-t-space {
  padding-top: 100px;
}
.section-b-space {
  padding-bottom: 100px;
}
body {
  font-family: $font_0, $font_1, $font_2;
  position: relative;
  background: $white;
  font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));

  // display: none;
}
h1 {
  font-style: $font_1;
  font-size: calc(22px + (80 - 22) * ((100vw - 320px) / (1920 - 320)));
  color: $black;
  font-weight: 700;
  text-transform: uppercase;
}
h2 {
  font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
  text-transform: uppercase;
  font-weight: 500;
  font-family: $font_0;
  color: $theme-primary;
}
h3 {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  font-family: $font_1;
}
h4 {
  font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  font-family: $font_1;
}
h5 {
  font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
}
h6 {
  font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  font-family: $font_1;
  color: $theme-primary;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
}
li {
  display: block;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}
p {
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.6;
  color: $grey-para;
  font-family: $font_1;
  font-weight: 400;
}
span {
  font-size: 14px;
}
a {
  text-decoration:none;
  transition: 0.5s ease;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
  }

  &:focus {
    outline: none;
  }
}
.mt-cls {
  margin-top: 30px;
}
button, .btn {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
:focus{
  outline: none;
  box-shadow: none;
}
.bg-light0 {
  background-color: $gray;
}
.bg-light1 {
  background-color: $pink;
}
.bg-light2 {
  background-color: $blue;
}
.small-section {
  padding-top: 35px;
  padding-bottom: 35px;
}
.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px;
}
.btn_dark:hover {
  color: white;
}
.slick-slide {
  line-height: 0;
}
.slick-prev {
  left: -20px;
}
.slick-next {
  right: -20px;
}
.light-grey-bg {
  background-color: rgba($brown6, 0.4);
}
.alert {
  font-size: 14px;
  .progress {
    height: 7px;
  }
}
.common-bg{
  background-image: url("../images/common_image/common_bg.png");
  background-attachment: fixed;
  position: relative;
}
.common-decor{
  .circel{
    position: absolute;
    height: 25px;
    width: 25px;
    border: 4px solid $brown6;
    border-radius: 100%;
    left: 30px;
    animation: myOrbit 9s linear infinite;
    &:nth-child(2){
      right: 30px;
      left: unset;
      bottom: 50%;
      animation: myOrbit 9s linear infinite reverse;
    }
    &:nth-child(3){
      right: 50%;
      left: unset;
      bottom: 50px;
      animation: myOrbit 9s linear infinite reverse;
    }

  }
  .plus{
    position: absolute;
    bottom: 50px;
    left: 50px;
    animation: star 7s linear infinite;
    &:before{
      content: "";
      height: 20px;
      position: absolute;
      width: 6px;
      background: linear-gradient(to top, $brown2 0%, $brown3 100%);
      border-radius: 5px;
      transform: rotate(-85deg);
    }
    &:after{
      content: "";
      background-color: #fe7d0b;
      height: 20px;
      position: absolute;
      width: 6px;
      background: linear-gradient(to top, $brown2 0%, $brown3 100%);
      border-radius: 5px;
    }
    & +.plus{
      left: unset;
      right: 30px;
      animation: star 15s linear infinite;
    }
  }
}

.media{
  display: flex; 
}


/*==================
     1.1. Button
 ===================*/
.btn_dark {
  border: 1px solid $theme-primary;
  background: $theme-primary;
  color: $white;
  padding: 15px 40px;
  border-radius: 100px;
  font-family: $font_1;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
  overflow: hidden;

  &:after {
    background: $white;
    content: "";
    height: 155px;
    left: -80px;
    opacity: 0.2;
    position: absolute;
    top: -30px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 1;
  }
  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}
.btn_lite {
  border: 1px solid $theme-primary-light;
  background: $theme-primary-light;
  color: $white;
  padding: 15px 40px;
  border-radius: 100px;
  font-family: $font_1;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1;
  overflow: hidden;
  position: relative;
  &:after {
    background: $white;
    content: "";
    height: 155px;
    left: -80px;
    opacity: 0.2;
    position: absolute;
    top: -30px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 1;
  }
  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}
.btn-close{
  &:focus{
    box-shadow:none;
  }
}


/*======================
     1.2. Title
=======================*/
.title_prime {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  height: 100%;

  .icon-image {
    width: 50px;
    fill: $theme-primary;
    margin-left: 37px;
  }

  h5 {
    font-size: calc(14px + (28 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 23px;
    padding-left: 20px;
    position: relative;
    color: $theme-primary;
    font-family: $font_2;
    text-transform: capitalize;
    display: inline-block;
    letter-spacing: 0.02em;
    line-height: 26px;

    &:before {
      content: "";
      height: 3px;
      width: 14px;
      position: absolute;
      left: 7px;
      top: 70%;
      background: $theme-primary;
      transform: translate(-50%, 0);
    }
  }

  h2 {
    letter-spacing: 0.02em;
    line-height: 60px;
    padding-top: 30px;
    max-width: 340px;
    margin-top: -12px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p {
    margin-top: 0;
    padding-top: 20px;
    margin-bottom: 0;
  }
}
.title_second {
  margin-bottom: 50px;
  h5 {
    color: $theme-primary-light;
    font-family: $font_2;
    text-transform: capitalize;
    margin-top: -3px;
    font-size: calc(14px + (28 - 14) * ((100vw - 320px) / (1920 - 320)));
  }
  h2 {
    padding-top: 20px;
    margin-bottom: -8px;
  }
}
.title1 {
  text-align: center;
  h4 {
    color: $theme-primary;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-top: -3px;
  }
  .title-inner1 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    &:after {
      position: absolute;
      height: 5px;
      width: 70px;
      background-color: $theme-primary;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
    }
  }
  &.title-gradient {
    .title-inner1 {
      &:after {
        background: $theme-primary;
        background: linear-gradient(180deg, $gradient1 0%, $gradient2 99%);
        background-color: $theme-primary;
      }
    }
  }
  &.title5 {
    .title-inner1 {
      padding-bottom: 0;
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
    hr[role="tournament6"] {
      border: 0 solid;
      height: 1px;
      background-image: linear-gradient(
                      to right,
                      rgba(0, 0, 0, 0),
                      rgba($theme-primary, 0.75),
                      rgba(0, 0, 0, 0)
      );
      display: block;
      width: 25%;
      margin: 15px auto 30px auto;
    }
    hr[role="tournament6"]::before {
      position: absolute;
      background-color: #efefef;
      border: 1px solid;
      border-color: $theme-primary;
      padding: 5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 50%;
      margin: -6px 0px 0px 0;
      content: "";
    }
  }
}
.title2 {
  text-align: center;
  h4 {
    color: $theme-primary;
    padding-bottom: 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-top: -3px;
  }
  .title-inner2 {
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    padding: 0 25px;
    &:after {
      position: absolute;
      height: 5px;
      width: 100%;
      background-color: lighten($theme-primary, 10%);
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 3px;
      z-index: -1;
    }
  }
}
.title3 {
  text-align: center;
  h4 {
    color: $theme-primary;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-top: -3px;
  }
  .title-inner3 {
    margin-bottom: 20px;
    position: relative;
    margin-top: -5px;
  }
  .line {
    position: relative;
    height: 5px;
    width: 45px;
    background-color: $theme-primary;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    margin-bottom: 50px;
    &:after,
    &:before {
      position: absolute;
      height: 5px;
      width: 10px;
      content: "";
      background-color: $theme-primary;
    }
    &:after {
      right: 50px;
    }
    &:before {
      left: 50px;
    }
  }
}
.title4 {
  text-align: center;
  .title-inner4 {
    padding-bottom: 25px;
    margin-bottom: 0;
    position: relative;
    margin-top: -5px;
  }
  .line {
    position: relative;
    height: 1px;
    width: 180px;
    background-color: $theme-primary;
    margin: 0 auto;
    margin-bottom: 55px;
    bottom: 0;
    top: 0;
    &:after,
    &:before {
      position: absolute;
      height: 10px;
      width: 10px;
      content: "";
      background-color: $theme-primary;
      top: -4px;
    }
    &:after {
      left: 78px;
    }
    &:before {
      left: 64px;
    }
    span {
      &:after,
      &:before {
        position: absolute;
        height: 10px;
        width: 10px;
        content: "";
        background-color: $theme-primary;
        top: -4px;
      }
      &:after {
        right: 78px;
      }
      &:before {
        right: 64px;
      }
    }
  }
}
.title6 {
  text-align: center;
  h2 {
    margin-top: -5px;
  }
}
.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px;
}


/*==================
     2. Header
====================*/
.top_header {
  font-family: $font_3;
  padding: 5px 0;
  background: linear-gradient(270deg, $brown7, $brown8, $brown7);
  .greeting {
    display: flex;
    align-items: center;
    height: 100%;
    float: left;
  }
  ul {
    &.list-inline {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
    }
  }
  h6 {
    color: $theme-primary;
    font-size: 14px;
    line-height: 30px;
    font-family:$font_3;
  }

  .dropdown {
    .dropdown-menu {
      background: white;
      border-radius: 0;
      .dropdown-item {
        color: $theme-primary;
        &:hover {
          color: $theme-primary;
          font-weight: 700;
        }
        &:active {
          background-color: white;
        }
      }
    }

    .btn {
      background: transparent;
      color: $theme-primary;
      border-radius: unset;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;

      &:focus {
        box-shadow: unset;
      }
    }
  }

  ul {
    > li {
      &:nth-child(2) {
        border-right: 1px solid rgba($theme-primary, 0.4);
        padding: 0 15px 0 0;
        margin-right: 1.2rem !important;
      }
    }
  }
  i {
    color: $theme-primary;
    font-size: 14px;
  }
}
.toggle-nav {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;
}
.menu {
  background: $white;
  a {
    padding-top: 40px;
    padding-bottom: 40px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .menu-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .sm-horizontal {
      .mobile-back {
        display: none;
      }
    }
    .toggle-nav {
      i {
        font-size: 24px;
        color: $theme-primary;
      }
      display: none;
    }
    .menu-right {
      display: flex;
      nav {
        align-items: center;
        display: flex;
      }
      .main-menu {
        .mobile-back {
          display: none;
        }
      }
      .full-mega-menu {
        min-width: 1100px !important;
        padding: 20px !important;
        left: unset !important;
        right: 0;
        h5 {
          font-size: 14px;
          color: $theme-primary;
          text-transform: uppercase;
          line-height: 14px;
          font-weight: 700;
          font-family: Poppins;
        }
        ul {
          display: block;
          position: relative;
          box-shadow: none;
          border: none;
          background-color: transparent;

          a {
            padding:9px 0;
            font-size: 14px;
          }
        }
      }
    }
    .navbar {
      .back {
        display: none;
      }
      .nav-link {
        color: $theme-primary;
        padding-right: 24px;
        padding-left: 24px;
      }
      .navbar-toggler {
        color: $theme-primary;
        border: unset;
      }
    }

    a {
      font-family: $font_3;
      line-height: 14px;
    }
    ul.navbar-nav {
      .nav-link {
        transition: all 0.5s ease-in-out;
        &:hover {
          background: $theme-primary;
          color: $white;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .main_menu_right {
      display: flex;
      align-items: center;

      > button {
        a {
          color: $white;
          line-height: 0;
        }
        + button {
          margin-left: 10px;
        }
      }

      .setting {
        display: none;
      }

      button {
        cursor: pointer;
        color: $white;
        border: 1px solid $theme-primary;
        background: $theme-primary;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        span {
          font-size: 16px;
        }
        &:hover {
          background-color: white;
          color: $theme-primary;
          transition: all 0.5s ease;
        }
      }
    }
  }
}
//inner header
.inner_header {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;
  background: #b6a48b;
  margin: 0 auto;
  border-top: 1px solid $theme-primary;
  padding: 25px 0;
  box-sizing: border-box;

  .mid_header_right {
    display: flex;
    padding: 20px 0;

    .location {
      margin-left: auto;
      max-width: 38%;
    }
  }

  .media {
    span {
      color: $theme-primary;
    }

    .media-body {
      p {
        color: $theme-primary;
        font-weight: medium;
        font-size: 12px;
        font-family: $font_1;
      }
    }
  }
}


/*======================
     3. Home Slider
=======================*/
.home-slider {
  &.slick-slider {
    .slick-prev {
      left: 10px;
      z-index: 1;
      opacity: 0;

      &:before {
        content: "\f053";
        font: normal normal normal 20px/1 FontAwesome;
        border: 1px solid transparent;
        border-radius: 100%;
        padding: 5px;
        background: transparent;
        color: $theme-primary;
      }
    }

    .slick-next {
      right: 10px;
      z-index: 1;
      opacity: 0;

      &:before {
        content: "\f054";
        font: normal normal normal 20px/1 FontAwesome;
        border: 1px solid transparent;
        border-radius: 100%;
        padding: 5px;
        background: transparent;
        color: $theme-primary;
      }
    }

    &:hover {
      .slick-prev {
        left: 50px;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        transform: scale(1);
      }
      .slick-next {
        right: 50px;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        transform: scale(1);
      }
    }
  }

  .main_section {
    height: 86vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    @for $i from 1 through 20 {
      &.home#{$i} {
        background-image:url("../images/home/#{$i}.jpg");
      }
    }

    .main_section_contain {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: 86vh;
      width: 100%;
      h1 {
        line-height: 110px;
        letter-spacing: 0.039em;
        margin-bottom: -47px;
      }

      .new {
        font-weight: 300;
        font-family: $font_2;
        color: $theme-primary;
        text-transform: capitalize;
        font-size: calc(25px + (150 - 25) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 20px;
        margin-top: 47px;
        line-height: 26px;
        letter-spacing: 0.019em;
      }

      p {
        font-weight: 500;
        color: $black;
        line-height: 110px;
        letter-spacing: 0.179em;
        font-size: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)));
        margin-top: -74px;
        padding-top: 37px;
        margin-bottom: -47px;
        padding-bottom: 50px;
      }
    }
  }
}


/*====================
     4. Instagram
======================*/
.silk_insta_slide {
  line-height: 0;

  .instabox {
    position: relative;
    overflow: hidden;

    .overlay {
      i {
        font-size: 25px;
        color: $white;
      }

      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $hoverColor;
      transition: all 0.5s ease;

      &:hover {
        opacity: 1;
        transform: scale(3);
        transition: all 0.5s ease;
        cursor: pointer;
      }
    }
  }
}


/*======================
     5. Brand
========================*/
.brand_section {
  background: url(../images/hair_color/bg.png) scroll top center;
  background-repeat: no-repeat;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
  }

  .brand {
    > div {
      &:nth-child(1) {
        border-bottom: 2px solid;
        border-image: linear-gradient(-90deg, $gradient, $white);
        border-image-slice: 1;
      }

      &:nth-child(2) {
        border-right: 2px solid;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-image: linear-gradient(0deg, $gradient, $white);
        border-image-slice: 1;
      }

      &:nth-child(3) {
        border-bottom: 2px solid;
        border-image: linear-gradient(-90deg, $white, $gradient);
        border-image-slice: 1;
      }

      &:nth-child(5) {
        border-right: 2px solid;
        border-left: 2px solid;
        border-image: linear-gradient(0deg, $white, $gradient);
        border-image-slice: 1;
      }
    }
  }
}


/*======================
     6. Testimonial
========================*/
.testimonial_section {
  background: url(../images/testimonial/bg1.jpg) scroll top center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .testimonial {
    .test_image {
      position: relative;
      display: inline-block;

      img {
        border-radius: 100px;
        z-index: 2;
        width: 140px;
      }

      .chat_icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -2;
        width: 63px;
        transform: translate(65%);
      }
    }

    .comment_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-left: 30px;

      .comment {
        position: relative;
        display: inline-block;

        &:before {
          content: "\f10d";
          font: normal normal normal 20px/1 FontAwesome;
          position: absolute;
          top: -20px;
          left: -20px;
        }

        &:after {
          content: "\f10e";
          font: normal normal normal 20px/1 FontAwesome;
          position: absolute;
          bottom: -20px;
          right: 0;
        }
      }

      .rating {
        display: flex;
        align-items: center;

        > i {
          &:nth-child(n) {
            color: $theme-primary;
          }

          &:nth-child(5) {
            color: $white;
          }
        }

        p {
          color: $theme-primary-light2;
        }
      }

      h6 {
        font-weight: 600;
        color: $theme-primary;
      }

      .reg {
        position: relative;
        display: inline-block;
        font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));

        &:before {
          content: "";
          position: absolute;
          background: $theme-primary;
          height: 2px;
          width: 10px;
          top: 50%;
          margin: 0 -16px;
          transform: translate(-20%, -50%);
        }
      }
    }
  }

  .nav_image {
    margin-bottom: -20px;
    padding-top: 0;

    .slick-current {
      img {
        transform: scale(1.5);
      }
    }
  }

  .nav_img {
    border-radius: 100%;
    padding: 35px 13%;
    width: 100%;
    cursor: pointer;
    position: relative;
    &:after {
      position: absolute;
      content: " ";
      height: 100%;
      width: 100%;
      border-radius: 100%;
      left: 0;
      top: -20px;
      background: $black;
      background: linear-gradient($white, $black);
      opacity: 0.7;
    }
  }

  .theme-arrow {
    &.slick-slider {
      .slick-prev:before {
        content: "\f177";
        color: $theme-primary;
        font-size: 35px;
        font: normal normal normal 20px/1 FontAwesome;
      }

      .slick-next:before {
        content: "\f178";
        color: $theme-primary;
        font-size: 35px;
        font: normal normal normal 20px/1 FontAwesome;
      }
    }
  }
}


/*====================
    7. Blog
======================*/
.blog_section {
  background: url(../images/photo-gallery/bg.png) scroll top center;
  background-repeat: no-repeat;

  .slick-slide {
    margin: 0 10px;
  }

  .blog {
    .card {
      border: none;
      &:hover {
        img {
          transform: scale(1.09);
          transition: all 0.5s ease-in-out;
        }
      }

      .card_image {
        overflow: hidden;

        .card-img-top {
          transition: all 0.5s ease-in-out;
        }
      }

      .card-body {
        padding: 15px 0 0 0;
        h5 {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-family: $font_0;
          letter-spacing: 0.022em;
          font-weight: bold;
          position: relative;
          color: $theme-primary;
          display: inline-block;
          margin-bottom: 6px;
        }
        p {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }

      h6 {
        padding-top: 15px;
        padding-bottom: 5px;
        font-family: $font_3;
        font-weight: 500;
        color: $theme-primary;
        text-align: left;
        margin-bottom: 0;
        font-size: 12px;
        .country {
          padding-right: 5px;
        }
        .date {
          border-left: 1px solid rgba($theme-primary, 0.1);
          padding: 0 5px;
        }
      }

      .btn {
        border-radius: unset;

        &:focus {
          box-shadow: unset;
        }
      }

      a {
        font-size: 12px;
        font-family: $font_3;
        color: $theme-primary;
        padding: 0;
        font-weight: 600;
        letter-spacing: 0.09em;
        margin-bottom: -5px;
      }
    }
  }
}


/*===================
   8. Advertise
=====================*/
.advertise_section {
  background: url(../images/Hording/bg_banner.jpg) scroll top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 192px 0;
  margin-top: 118px;
  position: relative;

  .bottle {
    img {
      position: absolute;
      bottom: -99px;
    }
  }

  .adv_contain {
    margin-top: -4px;
    h3 {
      color: $theme-primary;
      font-family: $font_0;
      margin-bottom: 0;
    }

    p {
      margin-bottom: -2px;
      padding: 25px 49px 0 0;
      margin-top: -6px;
    }
    .btn_dark {
      margin-top: 35px;
    }
  }
}

/*======================
  working days section
========================*/
.working-hourse-main {
  .working-hourse-contain {
    ul {
      li {
        border-bottom:1px solid $theme-primary-light ;
        padding-bottom: 15px;
        padding-top: 15px;
        text-transform: capitalize;
        color: $theme-primary;
        letter-spacing: 0.08em;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        &:first-child {
          padding-top: 0;
        } 
        span {
        float: right;
      }
      }
    }
  }
  .working-hourse-icon {
    >div {
      &:nth-child(1) {
        border-bottom: 2px solid;
        border-image: linear-gradient(-90deg, $gradient, $white);
        border-image-slice: 1;
      }
      &:nth-child(2) {
        border-right: 2px solid;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-image: linear-gradient(0deg, $gradient, $white);
        border-image-slice: 1;
      }
      &:nth-child(3) {
        border-bottom: 2px solid;
        border-image: linear-gradient(-90deg, $white, $gradient);
        border-image-slice: 1;
      }
      &:nth-child(5) {
        border-right: 2px solid;
        border-left: 2px solid;
        border-image: linear-gradient(0deg, $white, $gradient);
        border-image-slice: 1;
      }
      .logo {
        padding-bottom:40px; 
        p {
          margin-bottom: 0 ;
          padding-top: 10px;
          text-transform: capitalize;
          line-height: 1 ;
          color: $theme-primary;
        }
      }
      &:nth-child(n+4) {
      .logo {
        padding-top: 40px;
    }
    }
    }
  }
  .title_second {
     h2 {
      margin-bottom: 0 ;
     }
  }
}


/*======================
   9. Product_section
========================*/
.cattagory-margin {
  margin-bottom: -50px;
}
.product_box {
  &.product-margin {
    margin-bottom: 30px;
  }
  &.catagory_box {
    margin-top: 25px;
  }
  position: relative;
  overflow: hidden;
  &:hover {
    img {
      transform: scale(1.09);
      transition: all 0.5s ease-in-out;
    }

    .product_image {
      ul {
        > li {
          &:nth-child(2) {
            opacity: 1;
            transform: translateY(0);
          }

          &:nth-child(3) {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.2s;
          }

          &:nth-child(4) {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.4s;
          }
        }
      }
    }
  }

  .tringle-topleft {
    position: absolute;
    top: 0;
    left: 0;

    &:before {
      content: "";
      position: absolute;
      border-top: 70px solid $theme-primary;
      border-right: 70px solid transparent;
    }

    h6 {
      color: $white;
      font-family: $font_3;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.02em;
      position: absolute;
      left: 3px;
      top: 15px;
      transform: rotate(-45deg);
    }
  }

  .product_image {
    overflow: hidden;
    position: relative;

    .timer-box {
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%;

      .timer {
        background: $brown5;
        padding: 10px 0;

        p {
          color: $white;
          margin-bottom: 0;
        }

        span {
          width: 70px;
          display: inline-block;

          .timer-cal {
            color: $white;
            text-align: left;
            padding-left: 10px;
          }
        }
        .sec {
          padding-right: 30px;
        }

        .padding-l {
          padding-left: 22px;
          display: inline;
        }
      }
    }

    .product_icon {
      position: absolute;
      top: 10px;
      right: 10px;

      i {
        color: $theme-primary;
        border: none;
        border-radius: 100%;
        padding: 8px;
        background: rgba(white, 0.6);
      }
    }

    ul {
      > li {
        + li {
          padding-top: 5px;
        }
      }
    }
  }

  ul {
    > li {
      transition: all 0.5s ease-in-out;

      &:nth-child(2) {
        opacity: 0;
        transform: translateY(-50px);
      }

      &:nth-child(3) {
        opacity: 0;
        transform: translateY(-50px);
      }

      &:nth-child(4) {
        opacity: 0;
        transform: translateY(-50px);
      }
    }
  }
}
.card-img-top {
  transition: all 0.5s ease-in-out;
  border-radius: 0;
}
.img-lable {
  position: absolute;
  top: 10px;
  left: 10px;

  h6 {
    color: $white;
    font-family: $font_3;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.02em;
    border: 1px solid $theme-primary;
    padding: 2px 9px;
    background: $theme-primary;
  }
}
.product_contain {
  background: white;
  padding: 15px 0 0 0;

  .card-title {
    margin-bottom: 5px;
    font-weight: 600;
    color: $theme-primary;
  }

  h6 {
    font-weight: 600;
    margin-bottom: -2px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.022em;
    color: $black;

    del {
      font-weight: 400;
    }
  }
}
.product_section {
  background: url(../images/photo-gallery/bg.png) scroll top center;
  background-size: cover;

  ul.tabs {
    list-style: none;
    text-align: center;
    margin-top: -15px;

    li {
      background: transparent;
      color: $theme-primary;
      display: inline-block;
      padding: 6px 15px;
      cursor: pointer;
      font-family: $font_3;
      font-weight: 500;
      letter-spacing: 0.02em;
      &.current {
        background: $theme-primary;
        color: white;
        border-radius: 50px;
      }
    }
  }

  .tab-content {
    display: none;
    &.current {
      display: inherit;
      .product_box {
        animation: zoomIn 300ms ease-in-out;
      }
    }

    .product-row {
      > div {
        &:nth-child(n) {
          margin-top: 35px;
        }
      }
    }
  }
}


/*====================
   10. New Arrivals_section
=====================*/
.new_arrival_section {
  background: url(../images/photo-gallery/bg.png) scroll top center;
  background-size: cover;

  .slick-slide {
    margin: 0 10px;
  }

  .slick-slider {
    .slick-prev {
      &:before {
        content: "\f053";
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        top: 50%;
        left: 0;
        color: $theme-primary;
        border: 1px solid $brown6;
        padding: 5px;
        background: $brown6;
      }
    }

    .slick-next {
      &:before {
        content: "\f054";
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        top: 50%;
        right: 0;
        color: $theme-primary;
        border: 1px solid $brown6;
        padding: 5px;
        background: $brown6;
      }
    }
  }
}


/*=====================
    11. hair_color
=======================*/
.hair_color_section {
  background: url(../images/hair_color/bg.png) scroll top center;
  background-size: cover;

  .slick-slide {
    margin: 0 10px;
  }

  .hair_color {
    .main-box {
      position: relative;

      &:hover {
        .main-color {
          transform: translateY(5px);
          transition: all 300ms ease-in-out;
        }
      }

      .main-color {
        position: absolute;
        width: 100%;
        bottom: 5px;
        left: 0;
        background: $brown2;
        display: flex;
        justify-content: center;
        padding: 15px;
        transform: translateY(90%);
        transition: all 300ms ease-in-out;

        &:before {
          content: "";
          position: absolute;
          height: 25px;
          width: 25px;
          background: $theme-primary;
          border: 7px solid white;
          border-radius: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 0;
        }
      }

      li {
        padding: 15px 10px;
        cursor: pointer;
      }
    }
  }
}


/*=====================
    12. About-us
=======================*/
.about-us-section {
  background: url(../images/About-us/4.png) scroll top center;
  background-size: cover;

  .main-image {
    position: relative;
    display: inline-block;
    margin-bottom: 0;

    h4 {
      position: absolute;
      top: 71px;
      color: $theme-primary-light;
      font-family: $font_0;
      right: -23%;
      transform: rotate(-90deg) translateY(0);
      letter-spacing: 0.019em;
      line-height: 25.99px;
    }
  }

  .main-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    h2 {
      color: $theme-primary;
      text-transform: capitalize;
      line-height: 70px;
      margin-top: -14px;
      margin-bottom: -11px;
      letter-spacing: 0.02em;
      padding-bottom: 37px;

      .experience-text {
        font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        display: block;
      }
    }

    .para1 {
      position: relative;
      padding-top: 45px;

      &:before {
        content: "";
        height: 5px;
        width: 125px;
        background: linear-gradient(90deg, $theme-primary, $white);
        border-image-slice: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .para2 {
      padding-top: 0;
      margin-bottom: -2px;
    }

    .btn_lite {
      margin-top: 32px;
    }
  }

  .btn_dark {
    margin-top: 35px;
  }
}


/*==================
    13. Service
===================*/
.service-section {
  background: url(../images/service/bg.jpg) scroll top center;
  background-size: cover;
  background-attachment: fixed;

  .title_prime {
    .btn {
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 30px;
      letter-spacing: 0.02em;
      font-weight: 500;
      padding: 0;
      position: relative;
      border-radius: unset;
      font-family: $font_3;
      color: $theme-primary;
      margin-bottom: -10px;
      margin-top: 39px;
      transition: all 0.5s ease-in-out;
      &:focus {
        box-shadow: unset;
      }
    }
  }

  .btn_dark {
    margin-top: 35px;
  }

  .image-container {
    margin: 50px 0;
    position: relative;
    .ba-slider {
      position: relative;
      z-index: 2;

      img {
        width: 100%;
        display: block;
      }
      .after-lable {
        position: absolute;
        top: 50%;
        right: -20px;
        color: black;
        background: $white;
        border: 1px solid $white;
        border-radius: 25px;
        padding: 2px 7px;
        transform: translate(0, -50%);
        z-index: 1;
      }
    }

    .resize {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background: $dark-font;

      img {
        opacity: 0.8;
      }

      .before-lable {
        position: absolute;
        top: 50%;
        left: -20px;
        color: black;
        background: $white;
        border: 1px solid $white;
        border-radius: 25px;
        padding: 2px 7px;
        transform: translate(0, -50%);
      }
    }

    .handle {
      /* Thin line seperator */
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      width: 3px;
      margin-left: -2px;
      background: $white;
      cursor: ew-resize;

      &:before {
        content: "";
        height: 40px;
        width: 40px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $grey;
        border: 10px solid $white;
        border-radius: 100%;
        z-index: 2;
      }
    }

    .back-div {
      height: 120%;
      width: 75%;
      left: 50%;
      top: -10%;
      background: linear-gradient(270deg, #d8c9b6, #e2d7c9, #d3c3ac);
      border-image-slice: 1;
      position: absolute;
      transform: translate(-50%, 0);
    }
  }
}


/*=================
    14. Mission
 ==================*/
.mission-section {
  background: url(../images/mission/bg.jpg) scroll top center;
  background-size: cover;
  background-attachment: fixed;

  .title_prime {
    .para2 {
      margin-top: -7px;
      margin-bottom: 0;
      padding-top: 35px;
    }

    .btn_dark {
      margin-top: 35px;
    }
  }
  .mission-image {
    > div {
      &:nth-last-child(-n + 2) {
        margin-top: 30px;
      }
    }
  }

  .box-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .box-item {
      position: relative;
      backface-visibility: hidden;

      .flip-box {
        transform-style: preserve-3d;
        perspective: 1000px;

        &:hover {
          .flip-box-back {
            transform: rotateY(0deg);
            transform-style: preserve-3d;
          }
        }

        .flip-box-front {
          transform: rotateY(0deg);
          transform-style: preserve-3d;
          border-radius: 8px;
          overflow: hidden;
        }

        .flip-box-back {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          transform: rotateY(180deg);
          transform-style: preserve-3d;

          .inner {
            position: absolute;
            left: 0;
            width: 100%;
            outline: 1px solid transparent;
            perspective: inherit;
            z-index: 2;
            transform: translateY(-50%) translateZ(60px) scale(0.94);
            top: 50%;
            background: rgba(176, 151, 121, 0.80);
            height: 100%;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
              font-family: $font_0;
            }

            p {
              margin-bottom: 7px;
            }

            h6,
            p,
            span {
              color: $white;
            }
          }
        }
      }
    }

    .flip-box-front,
    .flip-box-back {
      transition: transform 0.5s cubic-bezier(0.4, 0.2, 0.2, 1);
      backface-visibility: hidden;
    }

    .flip-box:hover .flip-box-front {
      transform: rotateY(-180deg);
      transform-style: preserve-3d;
    }
  }
}


/*=====================
    15. Feature
=======================*/
.feature-section {
  background: url(../images/cosmetic/bg.jpg) scroll top center;
  background-size: cover;
  background-attachment: fixed;

  .title {
    color: $theme-primary;

    h5 {
      font-family:$font_2;
      margin-top: -3px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .bottle-image{
    margin-top: 75px;
    margin-bottom: -20px;
  }

  .feature-box {
    > div {
      &:nth-child(1) {
        margin-top: 213px;
      }

      &:nth-child(2) {
        margin-top: 93px;
      }
    }

    .features-rtl {
      text-align: right;
    }

    .features {
      h4 {
        font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-primary;
        text-transform: uppercase;
        font-family: $font_0;
        letter-spacing: 0.02em;
      }
    }
  }
}


/*=====================
    16. Call-to-action
=======================*/
.call-to-action-section {
  background: linear-gradient(270deg, $brown7, $brown8, $brown7);

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    .media {
      align-items: center;
      svg {
        margin-right: 15px;
        fill: $theme-primary;
      }
      .media-body {
        padding-right: 60px;
        color: $theme-primary;
        h6 {
          font-weight: 700;
        }

        p {
          font-size: 13px;
          margin-bottom: 0;
        }
      }
    }

    .newsletter {
      border-left: 2px solid $brown13;
      padding-left: 60px;
    }
    .news-contain {
      color: $theme-primary;

      h6 {
        font-weight: 700;
      }

      p {
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }
  .email-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .email-box {
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      overflow: hidden;

      ::placeholder {
        color: $theme-primary;
        font-family: $font_3;
        font-size: 12px;
      }

      .email {
        display: inline-block;
        background: white;
        border-radius: 25px;
        border: 1px solid transparent;
        color: $theme-primary;
        padding: 7px 15px;
        position: relative;
        width: 435px;
        z-index: 4;

        &:focus {
          outline: unset;
        }
      }

      .submit-btn {
        position: absolute;
        background: $theme-primary;
        color: $white;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border: 1px solid transparent;
        padding: 7px 15px;
        top: 0;
        right: 0;
        z-index: 5;
        cursor: pointer;
        overflow: hidden;
        font-family: $font_1;
        font-size: 14px;
        height: 100%;
        &:after {
          background: $white;
          content: "";
          height: 155px;
          left: -90px;
          opacity: 0.2;
          position: absolute;
          top: -30px;
          transform: rotate(35deg);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          width: 50px;
          z-index: 1;
        }
        &:hover {
          &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
        }

        i {
          padding-right: 10px;
        }
      }
    }
  }
}


/*=====================
    17. Portfoilio CSS start
==========================*/
.portfolio-padding {
  margin-bottom: -30px;
}
.portfolio-section {
  button {
    font-family: $font_3;
  }
  .portfolio-grid-option {
    text-align: center;
  }
  .article-title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: white;
    transition: all 0.5s ease;
    text-align: center;
    float: left;
    font-size: 18px;
    line-height: 24px;

    a {
      color: white;
    }
  }

  .isotopeSelector {
    float: left;
    // width: 100%;
    position: relative;
    .overlay {
      position: relative;
      border: 1px solid $round-border;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .isotopeSelector {
    margin-bottom: 30px;

    img {
      transition: all 0.4s ease;
    }

    .overlay-background {
      transform: scale(0);
      transition: all 0.4s ease;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
        padding: 10px;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        color: $theme-primary;
        font-size: 22px;
        display: none;
        transition: all 0.4s ease;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      img {
        transform: scale(1.2) rotate(2deg);
        transition: all 0.4s ease;
      }

      .overlay-background {
        transform: scale(1);
        border: 3px solid white;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba($theme-primary, 0.3);
        transition: all 0.4s ease;

        i {
          display: flex;
          transition: all 0.4s ease;
        }
      }
    }
  }

  .border-portfolio {
    margin: 3px;
    overflow: hidden;
  }

  #form1 {
    padding-bottom: 20px;
  }

  .filter-button {
    padding: 0 24px;
    font-size: 20px;
    line-height: 35px;
    border: none;
    background-color: white;
    color: $theme-primary;

    &.active {
      background-color: $theme-primary;
      color: $white;
    }
  }

  &.fullwidth-portfolio {
    .isotopeSelector {
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 14px;
    }
  }

  &.metro-section {
    .product-box {
      .product-detail {
        opacity: 0;
        position: absolute;
        background-color: $white;
        padding: 10px;
        transition: all 0.5s ease;
        width: 65%;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -10px;
        text-align: center;

        h6 {
          color: #525252;
          padding-top: 0;
          margin-top: -2px;
        }

        h4 {
          font-weight: 400;
          color: $black;
        }
      }

      .cart-wrap {
        top: 7px;
        right: 7px;
        opacity: 1;

        button {
          border: 1px solid $white;
          border-radius: 100%;
          background-color: rgba($white, 0.8);
          opacity: 1;

          i {
            padding: 7px;
            color: $font-color;
          }
        }

        a {
          i {
            border-radius: 100%;
            border: 1px solid $white;
            margin: 5px 0;
            background-color: rgba($white, 0.8);
            padding: 7px;
            color: $font-color;
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &:hover {
        .product-detail {
          opacity: 1;
          transition: all 0.5s ease;
          bottom: 15px;
        }

        .cart-wrap {
          button {
            animation: none;
          }

          a {
            i {
              opacity: 1;
            }

            &:nth-child(2) {
              i {
                animation: fadeInDown 500ms ease-in-out;
              }
            }

            &:nth-child(3) {
              i {
                animation: fadeInDown 700ms ease-in-out;
              }
            }

            &:nth-child(4) {
              i {
                animation: fadeInDown 1000ms ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}
.filter-section {
  .filter-container {
    text-align: center;
    padding: 0 0 20px;

    ul {
      &.filter {
        > li {
          display: inline-block;
          padding: 0 25px;

          > a {
            color: $theme-primary;
            font-size: 20px;
            line-height: 40px;
            font-family: $font_3;

            &:hover,
            &:focus {
              text-decoration: none;
              color: $theme-primary;
              outline: none;
            }
          }

          &.active {
            background-color: $theme-primary;

            a {
              color: $white;
            }
          }

          span {
            color: #ddd;
            font-size: 20px;
            display: inline-block;
            margin: 0 5px;
          }
        }
      }
    }

    .filter {
      > li {
        padding: 0;
        margin: 0;
      }
    }
  }

  .title1 {
    .title-inner1 {
      margin-top: -5px;
      position: relative;
      text-align: center;
    }
  }

  h1 {
    text-align: center;
  }
}
.demo-colored-wrap {
  background: #ecf0f1;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  backface-visibility: hidden;
  transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-title {
  display: none;
}
.offer-box {
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  display: flex;
  left: -415px;
  transition: all 0.5s ease;
  z-index: 9;

  img {
    margin-right: 18px;
  }

  &.toggle-cls {
    left: 0;
    transition: all 0.5s ease;
  }

  .heading-right {
    h3 {
      position: absolute;
      top: 50%;
      right: 0;
      width: 230px;
      height: 36px;
      line-height: 36px;
      letter-spacing: 5px;
      font-size: 18px;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      transform: translate(50%, -50%) rotate(-90deg);
      text-align: center;
      background-color: $theme-primary;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}


/*=====================
   18. Add to Cart Panel
=======================*/
.add_to_cart {
  position: fixed;
  background-color: $white;
  z-index: 9999;
  transition: all 0.5s ease;
  &::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #dddddd;
  }
  &::-webkit-scrollbar-thumb {
    background: #888888;
    &:hover {
      background: #555555;
    }
  }
  .cart-inner {
    background-color: $white;
    z-index: 9;
    position: relative;
    top: 0;
    .cart_media {
      padding: 0 20px;
      li {
        position: relative;
        padding-bottom: 10px;
        width: 100%;
        .media {
          align-items: center;
          height: 100%;
          img {
            height: 90px;
          }
          .media-body {
            align-self: center;
            h4 {
              color: $theme-primary;
              span {
                color: $theme-primary;
                font-size: 16px;
              }
            }
            h6 {
              color: $theme-primary;
              text-transform: capitalize;
              margin-bottom: 0;
            }
          }
        }
        .total {
          border-top: 1px solid $brown13;
          border-bottom: 1px solid $brown13;
          padding-top: 10px;
          padding-bottom: 10px;
          h5 {
            text-transform: capitalize;
            margin-bottom: 0;
            color: $theme-primary;
            font-size: 18px;
            span {
              float: right;
              padding-top: 4px;
            }
          }
        }
        .buttons {
          a {
            font-size: 16px;
            color: $theme-primary;
            text-transform: capitalize;
            font-weight: 700;
            transition: all 0.5s ease;
            padding-left: 0;
            padding-right: 0;
            &:hover {
              color: $theme-primary-light;
              transition: all 0.5s ease;
            }
          }
          .checkout {
            float: right;
          }
        }
      }
      .close-circle {
        position: absolute;
        top: 0;
        right: 0;
        i {
          color: $theme-primary;
          font-size: 16px;
          transition: all 0.5s ease;
          &:hover {
            color: #000000;
            transition: all 0.5s ease;
          }
        }
      }
    }
    .cart_top {
      display: flex;
      padding: 20px;
      border-bottom: 1px solid $brown13;
      margin-bottom: 20px;
      align-items: center;
      h3 {
        font-size: 18px;
        color: $theme-primary;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .close-cart {
        margin-left: auto;
        i {
          color: $theme-primary;
          font-size: 18px;
        }
      }
    }
  }
  .overlay {
    visibility: hidden;
    opacity: 0;
    background-color: #212331;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  &.open-side {
    transition: all 0.5s ease;
    .overlay {
      opacity: 0.8;
      visibility: visible;

      transition: all 0.5s ease;
    }
  }
  &.left {
    left: -300px;
    top: 0;
    height: 100vh;
    width: 300px;
    .cart-inner {
      height: 100vh;
      overflow: auto;
    }
    &.open-side {
      left: 0;
    }
  }
  &.right {
    right: -300px;
    height: 100vh;
    width: 300px;
    top: 0;
    .cart-inner {
      height: 100vh;
      overflow: auto;
    }
    &.open-side {
      right: 0;
    }
  }
  &.top {
    height: 218px;
    width: 100%;
    top: -300px;
    .cart_media {
      display: flex;
      width: 100%;
      .cart_product {
        display: flex;
        width: 75%;
        overflow-y: auto;
        li {
          min-width: 245px;
          max-width: 225px;
          margin-right: 18px;
          padding-bottom: 0;
        }
      }
      .cart_total {
        width: 25%;
      }
    }
    .cart-inner {
      height: 218px;
      &.top-search {
        display: flex;
        justify-content: center;
        align-items: center;
        ::placeholder {
          color: $theme-primary;
          font-family: $font_3;
          font-size: 16px;
        }
        span {
          margin-left: 10px;
          background: $theme-primary;
          color: $white;
          height: 32px;
          width: 32px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        .searchbox-input {
          width: 70%;
          border: none;
          border-bottom:2px solid $theme-primary;
          color: $theme-primary;
          outline: unset;
          &:focus {
            outline: none;
          }
        }
      }
      .cart_top {
        margin-bottom: 0;
        background-color: #fbfbfb;
        padding: 15px 44px;
        border-bottom: none;
      }
      .cart_media {
        padding: 0;
        .cart_product {
          padding: 30px 44px;
        }
        .cart_total {
          padding: 30px 44px;
        }
      }
    }
    &.open-side {
      top: 0;
    }
  }
  &.bottom {
    height: 218px;
    width: 100%;
    bottom: -300px;
    .cart_media {
      display: flex;
      width: 100%;
      .cart_product {
        display: flex;
        width: 75%;
        overflow-y: auto;
        li {
          min-width: 245px;
          max-width: 225px;
          margin-right: 18px;
          padding-bottom: 0;
        }
      }
      .cart_total {
        width: 25%;
      }
    }
    .cart-inner {
      height: 218px;
      .cart_top {
        margin-bottom: 0;
        background-color: #fbfbfb;
        padding: 15px 44px;
        border-bottom: none;
      }
      .cart_media {
        padding: 0;
        .cart_product {
          padding: 30px 44px;
        }
        .cart_total {
          padding: 30px 44px;
        }
      }
    }
    &.open-side {
      bottom: 0;
    }
  }
}


/*=====================
   19. Animation section
=======================*/
.animation-section {
  position: relative;
  .decor-animation {
    .decor1 {
      position: absolute;
      left: 5%;
      bottom: 14%;
      animation: myOrbit 9s linear infinite;
    }
    .decor2 {
      position: absolute;
      right: 48%;
      bottom: 7%;
      animation: myOrbit 9s linear infinite;
    }
    .decor3 {
      position: absolute;
      right: 5%;
      bottom: 36%;
      animation: round-jump 9s linear infinite;
    }
    .decor4 {
      position: absolute;
      right: 5%;
      bottom: 25%;
      animation: star 7s linear infinite;
    }
    .decor5 {
      position: absolute;
      left: 5%;
      bottom: 25%;
      animation: star 7s linear infinite;
    }
    .decor6 {
      position: absolute;
      left: 5%;
      top: 25%;
      animation: star 7s linear infinite;
    }
    .decor7 {
      position: absolute;
      right: 8%;
      top: 7%;
      animation: myOrbit 9s linear infinite;
    }
    .decor8 {
      position: absolute;
      right: 5%;
      bottom: 10%;
      animation: star 7s linear infinite;
    }
    .decor9 {
      position: absolute;
      bottom: 10%;
      animation: run 7s linear infinite;
    }
    .decor10 {
      position: absolute;
      left: -10%;
      bottom: 10%;
      animation: movebounce 70s linear infinite;
    }
    .decor11 {
      position: absolute;
      left: -10%;
      top: 0;
      animation: movebounce 70s linear infinite;
    }
    .decor12 {
      position: absolute;
      left: 12%;
      bottom: 10%;
      animation: star 7s linear infinite;
    }
  }
}
@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg)
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg)
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg)
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg)
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg)
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg)
  }
}
@keyframes round-jump {
  0% {
    transform: translateY(-30px) translateX(15) rotate(0) scale(1.2);
    opacity: .7;
  }
  25% {
    transform: translateY(-20px) translateX(5px) rotate(50deg) scale(0.5);
    opacity: .8;
  }
  50% {
    transform: translateY(15px) translateX(-10px) rotate(20deg) scale(0.4);
    opacity: .9;
  }
  75% {
    transform: translateY(30px) translateX(10px) rotate(50deg) scale(0.65);
    opacity: .7;
  }
  100% {
    transform: translateY(-15px) translateX(10px) rotate(0) scale(0.4);
    opacity: .9;
  }
}
@keyframes star {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes myOrbit {
  from { -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg); }
}
@-webkit-keyframes movebounce {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes run {
  0% { right: 10%;}
  50%{ right : 11%;}
  100%{ right: 10%;}
}


/*=====================
    20. Footer
======================*/
.footer {
  background-size: cover;
  background-color :#231511;
  p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #a79995;
  }
  .common-decor {
    .circel {
      border: 4px solid #31231d;
    }
    .plus{
      &:before,&:after {
        background: linear-gradient(to top, #31231d 0%, #31231d 100%);
      }
    }
  }


  .portfolio{
    margin: -5px;
    li{
      display: inline-block;
      margin: 5px;
      position: relative;
      &:before{
        content: "";
        opacity: 0;
        transform: scale(3) rotate(90deg);
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($theme-primary , 0.4);
      }
      &:hover{
        &:before{

        }
      }
      img{
        height: 80px;
        width: auto;
      }
    }
  }
  .contact-list{
    i{
      position: absolute;
      left: 0;
      top: 5px;
    }
    li{
      position: relative;
      padding-left: 25px;
      color: #a79995;
      padding-bottom: 10px;
    }
  }
  .useful-link{
      li{
        position: relative;
        transition: all 0.3s ease;
        &:before{
          content: "\f101";
          font-family: $font-awesome;
          position: absolute;
          left: 0;
          color: #a79995;
          transition: all 0.3s ease;
        }
        &:hover{
          padding-left: 20px;
          transition: all 0.3s ease;
          &:before{
            left: 5px;
            transition: all 0.3s ease;
          }
        }
        padding-left: 15px;
        &+li{
          padding-top: 5px;
        }
      }
  }
  .social-icon{
      li{
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: rgba(178, 159, 133, 0.1);
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        border-radius: 100%;
        transition: all 0.3s ease;
        &:hover{
          background: rgba(178, 159, 133, 0.6);
          transition: all 0.3s ease;
          a{
            color: $white;
          }
        }
        &+li{
          margin-left: 5px;
        }
      }
  }
  .footer-title {
    margin-top: -5px;
    h5 {
      font-family: $font_1;
      color: lighten($theme-primary-light , 25%);
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 15px;
    }
  }

  .footer-content {
    .mo-number {
      margin-bottom: 24px;
    }
    .jvector-map-height {
      height: 0;
      .jvectormap-container {
        position: relative;
        height: 250px;
        svg {
          position: absolute;
          top: -40px;
          height: 210px;
        }
      }
    }

    li {
      text-transform: capitalize;
      font-family: $font_1;

      a {
        color: #a79995;
        opacity: 0.8;
      }
    }

    path {
      fill: $brown10;
    }
  }
}
.copyright {
  padding: 20px;
  background-color: #1d120f;

  h6 {
    font-weight: 500;
    opacity: 1;
    margin-top: -5px;
    margin-bottom: -2px;
    color: #a68c7d;
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
  }
}
.prod-timer-three {
  padding: 20px 0 17px 0;
  display: inline-block;
  font-weight: 400;

  .timer-dots {
    position: relative;
    top: -12px;
    font-weight: bold;
  }

  .timer li {
    display: inline-block;
    text-align: center;
    margin: 0 2px;

    span {
      font-family: $font_1;
      color: $black;
      display: block;
      font-size: 16px;
      line-height: 1;
    }

    .timer {
      font-family: $font_0;
      color: $black;
      display: inline-block;
      font-size: 12px;
      list-style-type: none;
      text-align: center;
      text-transform: capitalize;
    }
  }
}


/*================
    21. Loader SCSS
=================*/
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: block;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  > div {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    float: none;
    background-color: $theme-primary;
    border: 0 solid $theme-primary;
    bottom: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    transform: translateY(0) translateX(-50%) rotate(45deg) scale(0);
    animation: fire-diamonds 1.5s infinite linear;
    &:nth-child(1) {
      animation-delay: -0.85s;
    }
    &:nth-child(2) {
      animation-delay: -1.85s;
    }
    &:nth-child(3) {
      animation-delay: -2.85s;
    }
  }
}
@-webkit-keyframes fire-diamonds {
  0% {
    -webkit-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: translateY(-87.5%) translateX(-50%) rotate(45deg)
    scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    -webkit-transform: translateY(-212.5%) translateX(-50%) rotate(45deg)
    scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
  }
}
@-moz-keyframes fire-diamonds {
  0% {
    -moz-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
  }
  50% {
    -moz-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    -moz-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
  }
}
@-o-keyframes fire-diamonds {
  0% {
    -o-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
  }
  50% {
    -o-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    -o-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
  }
}
@keyframes fire-diamonds {
  0% {
    -webkit-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    -moz-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    -o-transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(75%) translateX(-50%) rotate(45deg) scale(0);
  }
  50% {
    -webkit-transform: translateY(-87.5%) translateX(-50%) rotate(45deg)
    scale(1);
    -moz-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    -o-transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
    transform: translateY(-87.5%) translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    -webkit-transform: translateY(-212.5%) translateX(-50%) rotate(45deg)
    scale(0);
    -moz-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    -o-transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
    transform: translateY(-212.5%) translateX(-50%) rotate(45deg) scale(0);
  }
}


/*=============
   22. Tap-To-Top
==============*/
.tap-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 8;
  display: none;
  color: $white;
  text-align: center;
  background: $theme-primary;
  border: none;
  font-size: 22px;
  padding: 8px;
  cursor: pointer;
}


/*=====================
  23. Inner-page
==========================*/
// breadcrumb section //
.theme-breadcrumb {
  background: url(../images/Bread-Crumb/Bread-bg.jpg) scroll top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #717171;
  h2 {
    color: $white;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 7px;
  }
  .breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: transparent;
    font-family: $font_1;
    margin-bottom: -5px;
    padding: 5px 0 0 0;

    > .breadcrumb-item {
      + .breadcrumb-item {
        &:before {
          color: $white;
        }
      }
    }
    li,
    a {
      color: $white;
      text-transform: uppercase;
    }
  }
}

// profile page //
.select_input {
  select {
    padding: 15px 20px;
    margin-bottom: 30px;
  }
}

// coming soon //
.template-password {
  background: url("../images/blog/bg-coming.png");
  background-size: cover;
  height: 100vh;

  .contain {
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
    }
    .login {
      h2 {
        color: $theme-primary;
        font-weight: 400;
      }

      .theme-form {
        h3 {
          color: $theme-primary;
          font-size: 20px;
        }

        input[type="password"] {
          border-color: $theme-primary-light;
          font-size: 12px;
          margin-bottom: 15px;
          padding: 12px 30px;
          border-radius: 25px;
        }
        input {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .owner {
      color: $theme-primary;
      a {
        color: $theme-primary-light;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: $theme-primary;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

// review page //
.review-page {
  .comment-section {
    border-bottom: none !important;

    li {
      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      h6 {
        font-size: 14px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .comnt-sec {
    margin-top: 10px;
    display: flex;

    li {
      margin-right: 15px;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      border-top: none !important;

      a {
        color: $theme-primary;
        font-size: 16px;

        i {
          margin-right: 5px;
        }
      }
    }
  }
}

// error section //
.error-section {
  padding: 130px 0;
  text-align: center;
  background-image: url("../images/hair_color/bg.png");
  background-size: cover;

  h1 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    color: $theme-primary;
    margin-top: -11px;
  }

  h2 {
    color: $theme-primary;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 40px 0;
  }
}

// breadcrumb section //
.breadcrumb-section {
  background-color: $top-header;
  padding: 30px 0;

  .page-title {
    display: flex;
    height: 100%;
    align-items: center;

    h2 {
      font-size: 16px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .breadcrumb {
    background-color: transparent;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    color: $grey2;
    font-weight: 600;
    margin: 0;
    padding-right: 0;

    a {
      color: $grey2;
      font-weight: 600;
    }
  }
}

// about page section
.about-head {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  height: 100%;

  h2 {
    color: $theme-primary;
    margin-bottom: 0;
  }
}
.about-page {
  text-align: center;
  .banner-section {
    border-radius: 5px;
    overflow: hidden;
  }
  p{
    font-size: 16px;
    line-height: 1.6;
  }
  h5 {
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 2;
    color: $theme-primary;
  }
}
.team {
  position: relative;
  h3{
    font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: $theme-primary;
    border-bottom: 1px solid lighten($theme-primary-light , 28%);
    padding-bottom: 5px;
  }
  img {
    margin: 0 auto;
    padding: 0 10px;
  }
  .slick-slide{
    border: 1px solid lighten($theme-primary-light , 28%);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    top: -45px;

    &:before {
      font: normal normal normal 30px/1 $font-awesome;
      color: $dark-font;
      opacity: 0.6;
    }
  }

  .slick-prev {
    left: unset;
    right: 20px;

    &:before {
      content: "\f104";
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: "\f105";
    }
  }

  h2 {
    padding-bottom: 15px;
    border-bottom: 1px solid $border;
    margin-bottom: 10px;
    color: $theme-primary;
    line-height: 30px;
  }

  h4,
  h6 {
    text-align: center;
  }

  h4 {
    color: $theme-primary;
    font-weight: 700;
    margin-top: 15px;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px;
  }

  h6 {
    margin-bottom: -2px;
  }
}

// category page //
.collection-filter-block {
  margin-bottom: 30px;
  padding: 0 30px 25px 30px;
  border: 1px solid $theme-primary-light;
  background-color: $white;

  .product-service {
    padding: 30px 0;

    .media {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $theme-primary-light;

      svg {
        height: 40px;
        width: 40px;

        path {
          fill: $theme-primary;
        }
      }

      .media-body {
        padding-left: 10px;

        h4 {
          font-size: 18px;
          margin-bottom: 5px;
          color: $theme-primary;
          font-family: $font_1;
        }

        p {
          font-size: 14px;
          margin-bottom: 0;
          text-transform: capitalize;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
.collection-collapse-block {
  &.open {
    margin-bottom: 12px;
  }
  .collapse-block-title {
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    color: $theme-primary;
    font-weight: 600;
    margin: 30px 0 0 0;
    font-family: $font_1;

    &:after {
      position: absolute;
      display: block;
      top: 0;
      right: -3px;
      content: "\f0d7";
      font-family: $font-awesome;
      color: $theme-primary;
    }
  }
  .collection-collapse-block-content {
    .color-selector {
      margin-top: 20px;
    }

    .collection-brand-filter {
      .category-list {
        li {
          display: flex;
          font-size: 16px;
          text-transform: uppercase;
          line-height: 18px;

          a {
            padding-bottom: 10px;
            font-family: $font_3;
            text-transform: capitalize;
            color: #33322d;
          }

          &:last-child {
            a {
              padding-bottom: 0;
            }
          }

          &:first-child {
            margin-top: 30px;
          }
        }
      }

      .collection-filter-checkbox {
        margin-top: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        label {
          font-family: $font_3;
          text-transform: capitalize;
          color: #33322d;
          line-height: 1;

          &:before {
            top: 1px;
            height: 15px;
            width: 15px;
            background: $white;
            border: 1px solid lighten($black, 46.5%);
          }

          &:after {
            top: 1px;
            height: 15px;
            width: 15px;
            background-size: 65%;
          }
        }
        .custom-control-input {
          &:checked {
            ~ .custom-control-label {
              &:after {
                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
              }
            }
          }
        }
      }
    }
  }
}
.color-selector {
  ul {
    li {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 5px;
      margin-bottom: 10px;
      cursor: pointer;
      border: 1px solid $grey4;
    }

    .color-1 {
      background-color: $white;
    }

    .color-2 {
      background-color: $cirkal-color2;
    }

    .color-3 {
      background-color: $theme-primary;
    }

    .color-4 {
      background-color: $cirkal-color4;
    }

    .color-5 {
      background-color: $cirkal-color5;
    }

    .color-6 {
      background-color: $theme-primary-light;
    }

    .color-7 {
      background-color: $cirkal-color7;
    }

    li.active {
      border: 1px solid black;
      position: relative;

      &:after {
        content: "";
        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
        top: 10px;
        right: 4px;
        height: 15px;
        width: 15px;
        background-size: 70%;
        background-repeat: no-repeat;
        position: absolute;
      }
    }
  }
}
.collection-sidebar-banner {
  margin-top: 30px;

  img {
    width: 100%;
  }
}
.top-banner-wrapper {
  .top-banner-content {
    h4 {
      font-size: 18px;
      font-weight: 600;
      color: $theme-primary;
      margin-bottom: 15px;
    }

    h5 {
      font-size: 16px;
      font-weight: 600;
      color: $theme-primary;
      letter-spacing: 1px;
      line-height: 1.2;
      margin-bottom: 10px;
      font-family: $font_1;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
}
.collection-filter {
  .theme-card{
    padding: 30px;
    border: 1px solid #c7ac85;
    background-color: white;
    h5.title-border{
      border-bottom: 1px solid #c7ac85;
      font-size: 18px;
      font-family: $font_1;
    }
  }
}
.product-wrapper-grid {
  &.list-view {
    .product_box {
      flex-direction: unset;
      display: flex;
      border: none;
      .product_image {
        width: 50%;
      }
      .card-body {
        background-color: transparent;
        display: flex;
        align-items: center;
        &.product_contain {
          padding: 0 0 0 15px;
          h6,
          h5,
          p {
            text-align: left !important;
          }
          h5 {
            font-size: 18px;
          }
          h6 {
            padding-bottom: 5px;
          }
          p {
            display: block !important;
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.collection-product-wrapper {
  .product-top-filter {
    border-bottom: 1px solid $theme-primary;
    border-top: 1px solid $theme-primary;

    .product-filter-content,
    .popup-filter {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .search-count,
      .sidebar-popup {
        border-right: 1px solid $theme-primary;
        padding: 20px;
        text-transform: capitalize;
        width: 35%;

        h5 {
          font-size: 16px;
          margin-bottom: 0;
          font-family: $font_1;
          color: $theme-primary;
        }
      }

      .collection-view {
        padding: 20px;
        width: 10%;

        ul {
          li {
            height: 100%;
            vertical-align: middle;
            cursor: pointer;
            color: $theme-primary;

            &:first-child {
              margin-right: 14px;
            }
          }
        }
      }

      .collection-grid-view {
        padding: 20px;
        width: 20%;
        border-right: 1px solid $theme-primary;

        ul {
          display: flex;
          justify-content: flex-end;

          li {
            margin-left: 10px;

            img {
              height: 18px;
              cursor: pointer;
            }
          }
        }
      }

      .product-page-per-view,
      .product-page-filter {
        position: relative;
        width: 20%;

        select {
          border: 0;
          padding: 20px;
          border-right: 1px solid $theme-primary;
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          color: $theme-primary;
          cursor: pointer;
          background: url(../images/dropdown.png) no-repeat 95%;

          &:focus {
            outline: unset;
            font-family: $font_1;
          }

          option {
            padding: 20px 0;

          }
        }
      }

      .product-page-filter {
        width: 15%;

        &:before {
          right: 10px;
        }

        select {
          border-right: none;
        }
      }
    }

    .popup-filter {
      justify-content: unset;

      ::-webkit-scrollbar {
        width: 3px;
      }

      ::-webkit-scrollbar-track {
        background: $round-border;
      }

      ::-webkit-scrollbar-thumb {
        background: $grey5;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: $grey2;
      }

      .sidebar-popup {
        position: relative;
        cursor: pointer;
        width: 20%;
        background: url(../images/dropdown.png) no-repeat 95%;

        a {
          font-size: 16px;
          color: $dark-font;
        }

        .collection-filter {
          background-color: $top-header;
          width: 270px;
          height: 600px;
          overflow-y: scroll;


          .theme-card h5{
            &.title-border{
              font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
          }
          .collection-filter-block {
            border: none;
          }

          .theme-card {
            padding: 0 30px;
          }

          .collection-sidebar-banner {
            padding: 0 30px;

            img {
              width: 100%;
            }
          }
        }
      }

      .open-popup {
        display: none;
        position: absolute;
        z-index: 9;
        top: 75px;
        left: 0;
        border: 1px solid $border-grey;
        box-shadow: 0 0 5px #ddd;
        min-width: 330px;

        &.open {
          display: block;
        }

        .collection-filter-block {
          margin-bottom: 0;
        }
      }

      .product-page-per-view,
      .product-page-filter {
        width: 20%;

        select {
          padding-right: 45px;
          font-size: 16px;
          font-family: $font_1;
        }

        &:before {
          right: 35px;
        }
      }

      .collection-grid-view {
        padding: 20px;
      }

      .collection-view {
        width: 20%;
      }

      .product-page-filter {
        &:before {
          right: 25px !important;
        }
      }
    }
  }

  .product-wrapper-grid {
    .product-box {
      margin-top: 50px;

      .product-detail {
        p {
          display: none;
          color: red;
        }

        h4 {
          font-size: 18px;
        }
      }
    }

    .product-five {
      flex: 0 0 20%;
      max-width: 20%;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }

    .col-lg-2 {
      .product-box {
        .product-detail {
          opacity: 0;
          position: absolute;
          background-color: $white;
          padding: 10px;
          transition: all 0.5s ease;
          width: 65%;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: -10px;
          text-align: center;

          h6 {
            color: #525252;
            padding-top: 0;
            margin-top: -2px;
          }

          h4 {
            font-weight: 400;
            color: $black;
          }
        }

        .cart-wrap {
          top: 7px;
          right: 7px;
          opacity: 1;

          button {
            border: 1px solid $white;
            border-radius: 100%;
            background-color: rgba($white, 0.8);
            opacity: 1;

            i {
              padding: 7px;
              color: $font-color;
            }
          }

          a {
            i {
              border-radius: 100%;
              border: 1px solid $white;
              margin: 5px 0;
              background-color: rgba($white, 0.8);
              padding: 7px;
              color: $font-color;
              opacity: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        &:hover {
          .product-detail {
            opacity: 1;
            transition: all 0.5s ease;
            bottom: 15px;
          }

          .cart-wrap {
            button {
              animation: none;
            }

            a {
              i {
                opacity: 1;
              }

              &:nth-child(2) {
                i {
                  animation: fadeInDown 500ms ease-in-out;
                }
              }

              &:nth-child(3) {
                i {
                  animation: fadeInDown 700ms ease-in-out;
                }
              }

              &:nth-child(4) {
                i {
                  animation: fadeInDown 1000ms ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}
.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer;
  span {
    font-size: 20px;
    background-color:$theme-primary;
    padding:5px 20px;
    color: $white;
    border-radius: 10px;
    &:hover{   
      color:$white !important;   
    }
  }
  
}
.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid $theme-primary-light;
  display: none;

  span {
    text-transform: uppercase;
    font-size: 14px;
    color: $theme-primary;
    cursor: pointer;

    i {
      font-size: 20px;
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
.product-pagination {
  border-bottom: 1px solid $theme-primary;
  border-top: 1px solid $theme-primary;
  margin: 50px 0;

  .pagination {
    border-radius: 0;

    .page-item {
      border-radius: 0;
      a {
        padding: 18px;
        border-bottom: 0;
        border-top: 0;
        color: $theme-primary;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-color: $theme-primary;
        border-right-color: $theme-primary;
        &:focus{
          box-shadow: none;
          background-color:$brown12;
        }
      }

      &:last-child {
        .page-link {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .page-link {
        &:hover {
          background-color: $brown12;
        }
      }
    }

    .page-item.active {
      a {
        color: $theme-primary;
        background-color: $white;
        border-color: $theme-primary;
      }
    }
  }

  .product-search-count-bottom {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid $theme-primary;
    border-right: 1px solid $theme-primary;
    padding-right: 15px;

    h5 {
      color: $theme-primary;
      font-size: 14px;
      margin-bottom: 0;
      padding: 5px 0;
      font-family: $font_1;
    }
  }
}
.bundle {
  margin-top: 10px;

  .bundle_img {
    display: flex;

    .img-box {
      img {
        max-width: 115px;
        border: 1px solid #ddd;
        padding: 2px;
      }
    }

    .plus {
      display: flex;
      align-items: center;
      padding: 0 7px;
      font-size: 22px;
    }
  }

  .bundle_detail {
    margin-top: 15px;

    .price_product {
      color: $black;
      padding-left: 3px;
      font-weight: 700;
    }
  }
}
.theme_checkbox {
  label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    text-transform: capitalize;
    color: $theme-primary;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $white;
      border: 1px solid $round-border;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

// collection page //
.collection {
  .collection-block {
    text-align: center;

    img,
    .bg-size {
      margin-bottom: 20px;
    }

    .collection-content {
      text-align: center;

      h3 {
        margin-bottom: 10px;
        color: $theme-primary;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 0.05em;
      }

      h4 {
        color: $theme-primary;
        margin-bottom: 10px;
        font-size: 16px;
      }

      p {
        line-height: 1.5;
      }

      .btn-solid,
      .btn-outline {
        padding: 10px 25px;
      }
    }
  }
}

//faq //

.faq-section {
  .accordion.theme-accordion {
    .accordion-item {
      margin-top: 10px;
      border-radius: 0;
      overflow: hidden;
      border-bottom: 1px solid lighten($brown6, 5%);
      border-color: lighten($brown6, 5%);
      :not(:first-of-type):not(:last-of-type),
      .theme-accordion .card:first-of-type {
        border-bottom: 1px solid lighten($brown6, 5%);
      }
      &:first-child {
        margin-top: 0;
      }
      .accordion-header {
        padding: 0;
        border-bottom: 0;
        transition: all 0.3s ease;
        background-color: lighten($brown6, 5%);
        button {
          color: $theme-primary;
          text-align: left;
          font-size: 17px;
          padding: 18px;
          font-weight: 600;
          width: 100%;
          letter-spacing: 1px;
          text-decoration:none;
          background-color: lighten($brown6, 5%);
          &.accordion-button{
            box-shadow: none;
            border-color: 1px solid lighten($brown6, 5%);
            &:focus{
              box-shadow: none;
              border-color: 1px solid lighten($brown6, 5%) !important;            
            }
          }
          &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 6px solid $theme-primary;
            right: 20px;
            top: 25px;
            transition: all 0.3s ease;
          }
          &[aria-expanded="true"] {
            &:before {
              border-top: 0;
              border-bottom: 6px solid $theme-primary;
              transition: all 0.3s ease;
            }
          }

          &:hover {
            text-decoration: none;
            color: $theme-primary;
            transition: all 0.3s ease;
          }
        }
      }

      .accordion-body {
        padding: 15px 18px;

        p {
          margin-bottom: 0;
          font-size: 14px;
          color: $theme-primary;
          line-height: 1.8;
        }
      }
    }
  }
}

//forget_pwd//
.pwd-page {
  text-align: center;

  h2 {
    margin-bottom: 25px;
    color: $theme-primary;
    margin-top: -5px;
    font-family: $font_1;
    line-height: -28px;
  }

  .theme-form {
    input {
      border-color: $theme-primary-light;
      font-size: 15px;
      padding: 15px 20px;
      margin-bottom: 15px;
      height: inherit;
      text-align: center;
      font-family: $font_1;
      border-radius: 100px;
    }
    ::placeholder {
      color: $theme-primary;
    }

    .btn-solid,
    .btn-outline {
      margin: 0 auto;
    }
  }
}

//login//
.login-page {
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: $theme-primary;
    font-weight: 700;
    margin-top: -5px;
  }

  .theme-card {
    padding: 30px;
    border: 1px solid $theme-primary;
    line-height: 1;

    .theme-form {
      label {
        text-transform: capitalize;
        color: $theme-primary;
        font-size: 14px;
        font-weight: 600;
        font-family: $font_1;
      }
      ::placeholder {
        color: $theme-primary;
      }

      input {
        border-color: $theme-primary-light;
        font-size: 12px;
        padding: 17px 25px;
        margin-bottom: 30px;
        height: inherit;
        font-family: $font_1;
      }
    }
  }

  .authentication-right {
    height: calc(100% - 48px);

    h6 {
      font-size: 14px;
      text-transform: uppercase;
      color: $theme-primary;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      color: $theme-primary;
      line-height: 2;
      margin-bottom: 1rem;
    }
  }
}

// register page //
.auth-page {
  h3 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: $theme-primary;
    font-weight: 700;
    margin-top: -5px;
    font-size: 24px;
  }
  .theme-card {
    padding: 30px;
    border: 1px solid #f1f1f1;
    line-height: 1;
    border-radius: 25px;
    background-color: white;
    .theme-form {
      .form-row{              
        display: flex;
            
        >div{
          &:nth-child(n+2){
            margin-left:10px;
          }
        }
      }
    
      label {
        text-transform: capitalize;
        color: $theme-primary;
        font-size: 14px;
        font-weight: 600;
        font-family: $font_1;
        margin-bottom:10px;
      }
      input ,select,textarea{
        border-color: $theme-primary-light;
        font-size: 15px;
        padding: 12px 30px;
        margin-bottom: 30px;
        height: inherit;
        border-radius: 25px;
        font-family: $font_1;
        color: $theme-primary;
      }
      ::placeholder {
        color: $theme-primary;
      }
    }
  }
}

// Search //
.search-block {
  padding: 0;
  background-image: url("../images/hair_color/bg.png");
  background-size: cover;
  .form-header {
    .input-group {
      // border-radius: 20px;     
      input {
        border-radius: 100px 5px 5px 100px;
        padding-left: 30px;       
      }
      i {
        padding-right: 10px;
      }
      .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &.btn_dark {
          &:after {
            background: $white;
            content: "";
            height: 155px;
            left: -90px;
            opacity: 0.2;
            position: absolute;
            top: -30px;
            transform: rotate(35deg);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            width: 50px;
            z-index: 1;
          }
          &:hover {
            &:after {
              left: 120%;
              transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            }
          }
        }
        z-index: 0;
      }
      .input-group-text{
        padding:0;      
        background-color: $white;
      }
    }
  }
}

// contacts //
.contact-page {
  .map {
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  .contact-right {
    padding-left: 10px;
    ul {
      li {
        position: relative;
        padding-left: 150px;
        margin-top: 30px;
        display: block;
        &:first-child {
          margin-top: 0;
        }
        .contact-icon {
          position: absolute;
          left: 0;
          text-align: center;
          width: 130px;
          border-right: 1px solid #dddddd;

          i {
            font-size: 25px;
            color: $theme-primary;
          }

          h6 {
            text-transform: uppercase;
            padding-top: 5px;
            font-size: 14px;
            font-weight: 600;
          }
        }

        p {
          margin-bottom: 0;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
  .theme-card {
    .theme-form {
      .form-row{
        display:block;
        >div{
          &:nth-child(n+2){
            margin-left: unset;
          }
        }
      }
    }
  }
}

// shopping cart //
.qty-box {
  .input-group {
    justify-content: center;
    span {
      color: $theme-primary;    
      button {
        background: $white;
        border: 1px solid $theme-primary-light;
        height:100%;
      }
    }

    .form-control {
      text-align: center;
      width: 80px;
      flex: unset;
      border-color: $theme-primary-light;
      color: $theme-primary;
    }

    button {
      background-color: transparent;
      border: 0;
      color: $theme-primary;
      cursor: pointer;
      padding-left: 12px;
      font-size: 12px;
      font-weight: 900;
      line-height: 1;

      i {
        font-weight: 900;
        color: $theme-primary;

      }
    }

    .icon {
      padding-right: 0;
    }
  }
}
.cart-section,
.wishlist-section {
  .table {
    color: $theme-primary;
    tbody {
      & + tbody {
        border-top: none;
      }
    }
  }

  .cart-buttons {
    padding-top: 35px;

    > div {
      &:last-child {
        text-align: right;
        padding-right: 59px;
      }
    }
  }

  .wishlist-buttons {
    padding-top: 35px;
    text-align: right;

    &:last-child {
      a {
        margin-left: 15px;
      }
    }
  }

  .cart-table {
    overflow: hidden;
    margin-bottom: 0;

    thead {
      th {
        border-bottom: 1px solid rgba($theme-primary, 0.2);
        font-weight: 900;
        color: $theme-primary;
        text-transform: uppercase;
        font-size: 14px;
        border-top: 0;
        text-align: center;
        padding: 0 0.75rem 0.75rem 0.75rem;
        line-height: 14px;
      }
    }
  }

  tbody {
    h5 {
      margin-bottom: 0;
    }

    tr {
      td {
        min-width: 210px;
        vertical-align: middle;
        color: $theme-primary;
        border-top: 0;
        border-bottom: 1px solid rgba($theme-primary, 0.2);
        text-align: center;

        a,
        p {
          color: $grey-para;
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
          margin-bottom: 0;

          img {
            height: 90px;
          }
        }

        .td-color {
          color: $theme-primary;
        }

        h2 {
          font-size: 24px;
          color: $dark-font;
          font-weight: 400;
        }

        .mobile-cart-content {
          display: none;
          justify-content: center;
          margin-top: 10px;

          .qty-box {
            border-radius: 5px;

            .input-group {
              .form-control {
                width: 48px;
                padding: 6px;
              }
            }
          }

          .col-xs-3 {
            align-self: center;
            margin-left: 10px;
            margin-right: 10px;
          }

          h2 {
            font-size: 20px;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      th {
        padding-top: 35px;
        text-align: right;
      }

      td {
        padding-top: 35px;
        padding-bottom: 0;
        text-align: right;
        border: none;
        padding-right: 63px;

        h2 {
          font-size: 24px;
          margin-bottom: 0;
        }

        &:first-child {
          width: 85%;
          font-size: 15px;
          padding-right: 10px;
          text-transform: capitalize;
          font-weight: 700;
        }
      }
    }
  }
}

// blog-details //
.blog-detail-page {
  .blog-detail {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    img {
      margin-bottom: 40px;
    }

    h3 {
      font-size: 24px;
      color: $theme-primary;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 1.8;
    }

    .post-social {
      text-align: left;
      padding-bottom: 15px;
      border-bottom: 1px solid lighten($theme-primary-light, 28%);
      margin-bottom: 15px;
      display: inline-flex;
      width: 100%;
      font-family: $font_1;
      li {
        & + li {
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px solid $theme-primary;
        }
      }
    }
  }

  .blog-advance {
    > div {
      &:last-child {
        p {
          font-size: 14px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    img,
    .bg-size {
      margin-bottom: 25px;
      margin-top: 25px;
      border: 1px solid #f1f1f1;
    }

    ul {
      list-style-type: decimal;
      list-style-position: inside;
      line-height: 2.5;
      font-size: 15px;

      li {
        display: list-item;
        font-weight: 700;
        font-family: $font_1;
        color: $theme-primary;
      }
    }

    p {
      line-height: 1.8;
      font-family: $font_1;
    }
  }

  .comment-section {
    border-bottom: 1px solid lighten($theme-primary-light, 28%);
    li {
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 1px solid lighten($theme-primary-light, 28%);

      img {
        height: 65px;
        width: 65px;
        margin-right: 20px;
        border-radius: 100%;
      }

      h6 {
        font-size: 16px;
        color: $theme-primary;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 5px;

        span {
          font-size: 13px;
          font-weight: 500;
          text-transform: capitalize;
          color: $theme-primary;
          margin-left: 20px;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.8;
        margin-bottom: 0;
      }
    }
  }

  .blog-contact {
    h2 {
      font-family: $font_1;
      font-size: 36px;
      margin-bottom: 30px;
      margin-top: -5px;
    }
  }
}

// blog-page //
.blog-page {
  .blog-sidebar {
    h6 {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 20px;
      color: $theme-primary;
      font-weight: 700;
      font-family: $font_1;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }

    h4 {
      font-size: 18px;
      color: $theme-primary;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 20px;
      letter-spacing: 1px;
    }

    .theme-card {
      border: 1px solid lighten($theme-primary-light, 20%);
      padding: 30px;
      background-color: $white;

      .recent-blog {
        li {
          .media {
            img {
              width: 100px;
              margin-right: 10px;
            }
          }

          & + li {
            margin-top: 30px;
          }
        }
      }

      .popular-blog {
        li {
          .blog-date {
            background-color: $theme-primary;
            height: 70px;
            width: 70px;
            margin: 0 15px 0 0;
            padding: 15px;

            span {
              display: block;
              text-align: center;
              color: $white;
              text-transform: uppercase;
            }
          }

          & + li {
            margin-top: 30px;
          }

          p {
            margin-top: 10px;
          }
        }
      }

      & + .theme-card {
        margin-top: 30px;
      }
    }
  }

  .blog-media {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .blog-right {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      h6 {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 18px;
        color: $theme-primary;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 15px;
      }
      h5 {
        color: $theme-primary;
      }

      p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 0;
      }
      ul {
        margin-bottom: 15px;
        li {
          font-size: 14px;
          line-height: 1.3;
          display: inline-block;

          i {
            padding-right: 3px;
          }

          & + li {
            padding-left: 15px;
            margin-left: 15px;
            border-left: 1px solid #dddddd;
          }
        }
      }
    }
  }
}

// product page //
.modal {
  padding-right: 0 !important;
}
.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
  .slick-prev,
  .slick-next {
    opacity: 0;
    transform: scale(1);
    transition: all 0.5s ease;
    margin-top: -10px;

    &:before {
      font: normal normal normal 14px/1 $font-awesome;
      opacity: 1;
      color: black;
      background-color: white;
      border-radius: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .slick-next {
    right: 1px;

    &:before {
      content: "\f105";
    }
  }

  .slick-prev {
    left: 1px;
    z-index: 1;

    &:before {
      content: "\f104";
    }
  }

  .slick-slide {
    > div {
      display: flex;
      margin-bottom: 15px;
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transform: scale(1.05);
      transition: all 0.5s ease;

      &:before {
        opacity: 1;
      }
    }

    .slick-next {
      right: 20px;
    }

    .slick-prev {
      left: 20px;
    }
  }
}
.slider-nav,
.rtl-slider-nav,
.slider-right-nav,
.rtl-slider-right-nav {
  .slick-slide {
    > div {
      display: flex;
      margin: 15px 15px 0 15px;
      border: 1px solid $round-border;
      transition: all 0.5s ease;
    }

    &.slick-current {
      > div {
        border-color: $font-grey;
        transition: all 0.5s ease;
      }
    }

    &.slick-active {
      transition: all 0.5s ease;
    }
  }
}
.slider-right-nav,
.rtl-slider-right-nav {
  .slick-slide {
    &:first-child {
      > div {
        margin-top: 0;
      }
    }
  }
}
.pro_sticky_info {
  border: 1px solid $round-border;
  padding: 20px;
}
.is_stuck {
  margin-top: 80px;
}
.image-scroll {
  div {
    > div {
      padding-bottom: 20px;

      &:last-child {
        padding: 0;
      }
    }
  }
}
.product-right {
  .btn_dark {
    padding: 10px 25px;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5em;
  }

  .product-title {
    color: $theme-primary;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
    a {
      color: $theme-primary;
    }
  }

  .border-product {
    padding-top: 15px;
    padding-bottom: 20px;
    border-top: 1px dashed $theme-primary-light;
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 1.2em;
    font-weight: 700;
    color: $theme-primary;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 15px;
    color: $theme-primary;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 7px;
    color: $theme-primary;

    del {
      color: $theme-primary;
      font-size: 14px;
    }

    span {
      padding-left: 5px;
      color: $theme-primary;
    }
  }

  .color-variant {
    margin-bottom: 10px;
    li {
      height: 30px;
      width: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      &.active{
        border: 1px solid $theme-primary ;
        &:before{
          content: "\f00c";
          position: absolute;
          top: 50%;
          left: 50%;
          font-size:80%;
          margin-right: -50%;
          transform: translate(-50%, -50%) ;
          font-family:$font-awesome;
        }
      }
    }
  }

  .product-buttons {
    margin-bottom: 20px;
    display:flex;   
    .btn-solid,
    .btn-outline {
      padding: 7px 25px;
    }

    a {
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .product-description {
    h6 {
      span {
        float: right;
      }
    }

    .qty-box {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .input-group {
        justify-content: unset;
        width: unset;
        .form-control {
          border-right: none;
          // padding-top: 10px;
          font-weight:500;
          &:focus{
            box-shadow:none;
          }
        }
      }
    }
  }

  .size-box {
    margin-top: 10px;
    margin-bottom: 10px;
    ul {
      li {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid $grey-about;
        text-align: center;
        display: inline-block;
        a {
          color: $theme-primary;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        &.active {
          background-color: rgba($brown6, 0.4);
        }
      }
    }
  }

  .product-icon {
    display: flex;

    .product-social {
      margin-top: 5px;

      li {
        padding-right: 30px;

        a {
          color: $theme-primary;
          transition: all 0.3s ease;

          i {
            font-size: 18px;
          }

          &:hover {
            color: $theme-primary;
          }
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .wishlist-btn {
      background-color: transparent;
      border: none;
      color: $theme-primary;
      font-family: $font_0;

      i {
        border-left: 1px solid $theme-primary;
        font-size: 18px;
        padding-left: 10px;
        margin-left: 5px;
        transition: all 0.5s ease;
        color: $theme-primary;
      }

      span {
        padding-left: 10px;
        font-size: 18px;
      }

      &:hover {
        i {
          color: $theme-primary;
          transition: all 0.5s ease;
        }
      }
    }
  }

  .payment-card-bottom {
    margin-top: 10px;

    ul {
      li {
        padding-right: 10px;
      }
    }
  }

  .timer {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    margin-top: 10px;
    background-color: rgba($brown6, 0.4);
    display: inline-block;
    p {
      font-size: 18px;
      color: $black;
      margin-bottom: 0;
    }
    span {
      width: 65px;
      display: inline-block;
      color: $theme-primary;
      .timer-cal {
        font-size: 12px;
        color: $theme-primary;
      }
      .padding-l {
        padding-left: 22px;
        display: inline;
      }
    }
  }

  &.product-form-box {
    text-align: center;
    border: 1px solid $round-border;
    padding: 20px;
    .product-description {
      .qty-box {
        margin-bottom: 5px;
        .input-group {
          justify-content: center;
          width: 100%;
        }
      }
    }

    .product-buttons {
      margin-bottom: 0;
    }

    .timer {
      margin-bottom: 10px;
      text-align: left;
    }
  }
}
.single-product-tables {
  display: flex;
  margin-top: 20px;
  color: $theme-primary;
  table {
    width: 20%;

    tr {
      height: 35px;

      td:first-child {
        font-weight: 600;
      }
    }
  }

  &.detail-section {
    margin-top: 0;

    table {
      width: 55%;
    }
  }
}
.product-accordion {
  .btn-link {
    color: $dark-font;

    &:hover {
      text-decoration: none;
    }
  }

  .card {
    border: none;
  }

  .card-body {
    width: 100%;
    height: 300px;
  }

  .card-header {
    padding: 5px 8px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
    background-color: rgba(127, 127, 127, 0.03);
  }
}
.product-related {
  h2 {
    color: $theme-primary;
    padding-bottom: 10px;
    border-bottom: 1px solid #dddada;
    margin-bottom: 20px;
    font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 20px;
  }
}
.rating {
  margin-top: 0;
  i {
    padding-right: 5px;
    &:nth-child(-n + 4) {
      color: $theme-primary;
    }

    &:last-child {
      color: $round-border;
    }
  }

  .three-star {
    padding-bottom: 5px;

    i {
      color: #acacac;

      &:nth-child(-n + 3) {
        color: $theme-primary;
      }
    }
  }
}
.tab-border {
  border: 1px solid $round-border;
  margin: 0;
  padding: 8px 0;

  .nav-border {
    border-bottom: 0;
    border-right: 1px solid $round-border;
  }
}
.vertical-tab {
  &.tab-product,
  .product-full-tab {
    .nav-material {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 15px 20px;
            color: $theme-primary;
          }
        }
      }
    }
  }
}
.tab-product,
.product-full-tab {
  padding-top: 30px;

  .nav-material {
    &.nav-tabs {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px solid $theme-primary-light;

      .nav-item {
        font-size: 14px;

        .nav-link {
          color: #212121;
          text-align: center;
          padding: 0 15px 20px 15px;
          text-transform: uppercase;
          border: 0;
        }

        .material-border {
          border-bottom: 2px solid $theme-primary;
          opacity: 0;
        }
      }

      .nav-link.active {
        color: $theme-primary;

        & ~ .material-border {
          transition: all 0.3s ease;
          opacity: 1;
        }
      }
    }
  }

  .theme-form {
    input {
      border-color: $theme-primary-light;
      font-size: 15px;
      padding: 15px 25px;
      margin-bottom: 15px;
      height: inherit;
      text-align: left;
      color: $theme-primary;
    }
    label {
      border-color: $theme-primary-light;
      color: $theme-primary;
      font-family: $font_0;
    }
    ::placeholder {
      color: $theme-primary;
      font-family: $font_1;
    }
    textarea {
      border-color: $theme-primary-light;
      font-size: 15px;
      padding: 17px 25px;
      margin-bottom: 15px;
      height: inherit;
      color: $theme-primary;
      font-family: $font_1;
    }
  }

  .tab-content.nav-material {
    p {
      font-size: 14px;
      padding: 20px 20px 0;
      margin-bottom: -8px;
      line-height: 2;
      letter-spacing: 0.05em;
    }

    .media {
      margin-top: 20px;
    }
  }

  .title {
    padding-right: 45px;
    color: $theme-primary;
    padding-bottom: 20px;
  }

  .theme-slider {
    .slick-arrow {
      top: -45px;
      height: auto;

      :before {
        color: $black;
        font-size: 18px;
      }
    }
  }

  .product-box {
    position: relative;
    margin: 5px;

    &:hover {
      box-shadow: 0 0 12px 0 #dddddd;

      .lbl-1 {
        opacity: 1;
        transition: all 0.3s ease;
      }

      .lbl-2 {
        opacity: 1;
        animation: flipInY 1000ms ease-in-out;
      }

      .color-variant li {
        opacity: 1 !important;
        animation: fadeInUp 500ms ease-in-out;
      }
    }

    .img-block {
      min-height: unset;
    }

    .cart-info {
      position: absolute;
      padding: 10px 0;
      top: 25%;
      right: 15px;
      width: 40px;
      margin-right: 0;

      i {
        padding-right: 0;
      }

      a,
      button {
        color: $font-color;
        transition: all 0.3s ease;
        background-color: $white;
        height: 35px;
        width: 35px;
        margin: 7px 0;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: 0 0 12px 0 $round-border;

        :hover {
          transition: all 0.3s ease;
          color: $theme-primary;
        }
      }
    }

    .lbl-1 {
      background-color: $theme-primary;
      padding: 2px 20px 2px 10px;
      display: inline-block;
      text-align: center;
      color: #ffffff;
      position: absolute;
      left: 0;
      top: 15px;
      font-size: 14px;
      line-height: 1.5;
      opacity: 0;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 12px solid $theme-primary;
        border-bottom: 13px solid $theme-primary;
        border-right: 7px solid $white;
      }
    }

    .lbl-2 {
      font-size: 14px;
      top: 15px;
      position: absolute;
      right: 10px;
      color: #333333;
      font-weight: 600;
      text-transform: capitalize;
      opacity: 0;
    }

    a {
      color: #0072bb;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1px;
    }

    .color-variant {
      position: absolute;
      top: -35px;
      width: 100%;
    }

    .slick-slide img {
      display: block;
    }

    .product-details {
      position: relative;

      .color-variant {
        position: absolute;
        top: -35px;
        width: 100%;

        li {
          opacity: 0;
          display: inline-block;
          height: 15px;
          width: 15px;
          border-radius: 10%;
          margin: 0 3px;
          transition: all 0.3s ease;
          cursor: pointer;
        }
      }

      h6 {
        color: #333333;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: capitalize;
      }

      .price {
        padding-bottom: 10px;
        font-size: 16px;
        color: $theme-primary;
      }
    }
  }
}
.product-full-tab {
  padding-top: 70px;
}
.product-load-more {
  .col-grid-box {
    display: none;
  }
}
.load-more-sec {
  margin-top: 40px;
  text-align: center;

  a {
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    border-top: 1px solid $round-border;
    border-bottom: 1px solid $round-border;
    width: 100%;
    color: $theme-primary;
  }
}
.product_image_4 {
  > div {
    &:nth-last-child(-n + 2) {
      margin-top: 25px;
    }
  }
}
.common-card{
  padding: 30px;
  border: 1px solid #f1f1f1;
  line-height: 1;
  border-radius: 25px;
  background-color: $white;
  height: 100%;
}
.theme-card {
  position: relative;
  &.center-align {
    display: flex;
    height: 100%;
    align-items: center;
    .offer-slider {
      .product-box2 {
        .media {
          .media-body {
            margin-top: 0;
          }
        }
      }
    }
  }
  &.card-border {
    border: 1px solid $round-border;
    h5 {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      margin-top: 0;
    }
    .slick-prev,
    .slick-next {
      top: -45px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-prev {
      right: 50px;
    }
    .offer-slider {
      padding-top: 0;
      padding-bottom: 10px;
      img {
        padding: 15px 15px 15px 30px;
      }
      .media {
        .media-body {
          a {
            h6 {
              margin-right: 25px;
            }
          }
        }
      }
    }
  }
  h5 {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-top: -5px;
    color: $theme-primary;
    &.title-border {
      border-bottom: 1px solid $theme-primary;
    }
  }
  .slick-prev,
  .slick-next {
    top: -35px;
    &:before {
      font: normal normal normal 30px/1 $font-awesome;
      color: $dark-font;
      opacity: 0.6;
    }
  }
  .slick-next {
    right: 0;
    &:before {
      content: "\f105";
      color: $theme-primary;
    }
  }
  .slick-prev {
    left: unset;
    right: 25px;
    &:before {
      content: "\f104";
      color: $theme-primary;
    }
  }
  .offer-slider {
    img {
      max-height: 130px;
      padding: 15px 15px 15px 0;
    }
    > div {
      .media {
        &:last-child {
          .media-body {
            margin-top: 15px;
          }
          img {
            margin-bottom: -15px;
          }
        }
      }
    }
    .media {
      .media-body {
        .rating {
          margin-top: 0;
          i {
            padding-right: 5px;
            &:nth-child(-n + 4) {
              color: $theme-primary;
            }
            &:last-child {
              color: $round-border;
            }
          }
        }
        a {
          h6 {
            font-size: 14px;
            margin-right: 61px;
            line-height: 17px;
            margin-top: 5px;
            margin-bottom: 0;
            color: #33322d;
          }
        }
        h4 {
          font-size: 18px;
          margin-top: 10px;
          font-weight: 700;
          color: $theme-primary;
          margin-bottom: 0;
        }
      }
    }
  }
}

// Quick-view //
.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative;
}
.quick-view-modal {
  .modal-body {
    .btn-close {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 5px;
      span {
        font-size: 22px;
      }
      &:focus{
        box-shadow:none;
      }
    }
  }
}

// Check-out page //
.checkout-page {
  .checkout-title {
    margin-bottom: 25px;

    h3 {
      font-size: 24px;
      color: $theme-primary;
      font-weight: 700;
    }
  }

  .checkout-form {
    .check-out {
      ::placeholder {
        color: $theme-primary;
      }
      .form-group {
        &:last-child {
          margin-bottom: -5px;

          label {
            margin-bottom: -5px;
          }
        }
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 25px;

      h3 {
        color: $theme-primary;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .field-label {
        line-height: 24px;
        text-transform: capitalize;
        color: $theme-primary;
        margin-bottom: 10px;
        font-weight: 700;
        font-family: $font_1;

        span {
          font-size: 16px;
          color: $theme-primary;
          font-weight: 600;
        }
      }

      label {
        color: $theme-primary;
      }
    }

    select {
      color: $theme-primary;
      cursor: pointer;
      background: url(../images/dropdown.png) no-repeat 95%;
      border-radius: 25px;
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="password"],
      &[type="tel"],
      &[type="number"],
      &[type="url"] {
        width: 100%;
        padding: 0 22px;
        height: 45px;
        border: 1px solid $theme-primary-light;
        border-radius: 25px;
      }
    }

    select,
    textarea {
      width: 100%;
      padding: 0 22px;
      height: 45px;
      border: 1px solid $theme-primary-light;
      -webkit-appearance: none;
    }
  }

  .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;

    label {
      position: relative;
      top: -1px;
      font-weight: normal;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .lower-content {
    margin-top: 30px;

    .order-column {
      margin-bottom: 40px;
    }
  }
}
.order-box {
  position: relative;
  margin-bottom: 50px;

  .title-box {
    position: relative;
    padding-bottom: 25px;
    color: $theme-primary;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid lighten($theme-primary-light, 30%);
    margin-bottom: 20px;

    span {
      font-size: 22px;
      position: relative;
      width: 35%;
      float: right;
      line-height: 1.2em;
    }
  }

  .qty {
    position: relative;
    border-bottom: 1px solid lighten($theme-primary-light, 30%);
    margin-bottom: 30px;

    li {
      position: relative;
      display: block;
      font-size: 15px;
      font-family: $font_1  ;
      line-height: 20px;
      margin-bottom: 20px;

      span {
        float: right;
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        width: 35%;
      }
    }
  }

  .sub-total {
    position: relative;
    border-bottom: 1px solid lighten($theme-primary-light, 30%);
    margin-bottom: 30px;

    li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 20px;
      font-family: $font_1;
      width: 100%;
      .count {
        font-size: 18px;

      }
    }

    .shopping-option {
      label {
        position: relative;
        font-size: 16px;
        line-height: 32px;
        padding-left: 10px;
      }
    }

    .shipping {
      width: 35%;
      float: right;
    }
  }

  .total {
    position: relative;
    margin-bottom: 40px;

    li {
      position: relative;
      display: block;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: $font_1;
      .count {
        position: relative;
        font-size: 20px;
        line-height: 20px;
        color: $theme-primary;
        font-weight: 400;
      }

      span {
        float: right;
        font-size: 15px;
        line-height: 20px;
        color: $theme-primary;
        font-weight: 400;
        width: 35%;
        display: block;
      }
    }
  }
}
.payment-box {
  position: relative;

  .upper-box {
    position: relative;
  }

  .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;

    li {
      display: flex;
      margin-bottom: 15px;

      .radio-option {
        position: relative;
        label {
          position: relative;
          padding-left: 30px;
          text-transform: capitalize;
          font-family: $font_1;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }

        input[type="radio"] {
          position: absolute;
          left: 0;
          top: 5px;
        }

        label {
          .small-text {
            position: relative;
            display: none;
            font-size: 15px;
            line-height: 25px;
            font-weight: 300;
            color: $theme-primary;
            margin-top: 10px;
          }

          img {
            position: relative;
            display: block;
            max-width: 100%;
            margin-left: -30px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

// Dashboard page
.dashboard-left {
  .block-title h2 {
    font-size: 24px;
    padding-bottom: 20px;
  }

  .block-content {
    ul li {
      display: flex;
      transition: all 0.5s ease;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      &.active {
        color: $theme-primary;

        a {
          color: $theme-primary;
          font-family: $font_0;
        }
        &:before {
          color: $theme-primary;
        }
      }

      &:before {
        content: "\f105";
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        line-height: 1;
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: $theme-primary;
      }

      a {
        color: $theme-primary;
      }

      &:hover {
        &:before {
          color: $theme-primary;
        }
        a {
          color: $theme-primary;
          transition: all 0.5s ease;
        }

        padding-left: 10px;
        color: $theme-primary;
        transition: all 0.5s ease;
      }
    }
  }
}
.padding-cls {
  padding-left: 50px;
  padding-right: 50px;
}
.dashboard-right {
  p {
    font-size: 14px;
    color: $theme-primary;
    line-height: 20px;
  }

  .dashboard {
    .page-title h2 {
      font-size: 22px;
      margin-bottom: 15px;
      color: $theme-primary;
      font-weight: 700;
      font-family: $font_1;
    }

    .welcome-msg {
      p {
        margin-bottom: 0;
      }
    }
  }
}
.dashboard {
  .box-account{
    >div{
      +div{
        margin-top: 20px;
      }
    }
  }
  .box-head {
    h2 {
      font-size: 22px;
      margin: 20px 0 0;
      text-transform: capitalize;
      font-weight: 700;
      font-family: $font_1;
      color: $theme-primary;
    }
  }

  .box {
    a {
      color: $theme-primary;
    }

    h6 {
      margin-bottom: 0;
    }

    .box-title {
      border-bottom: 1px solid lighten($theme-primary-light, 27%);
      display: inline-block;
      margin-bottom: 5px;
      padding: 12px 0;
      position: relative;
      width: 100%;

      h3 {
        font-size: 22px;
        margin: 0;
        text-transform: capitalize;
        color: $theme-primary;
      }

      > a {
        position: absolute;
        right: 0;
        top: 12px;
        color: $theme-primary;
      }
    }

    address {
      margin-bottom: 0;
      color: $theme-primary;
      line-height: 2;
      margin-top: 10px;
    }
  }
}
.box-content {
  h6 {
    font-size: 14px;
    line-height: 25px !important;
    margin-bottom: 0;
    color: $theme-primary;
  }

  a {
    color: $theme-primary;
  }
}
.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: $theme-primary;
  color: $white;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
}

// Order-success //
.light-layout {
  background-color:$grey-lighter ;
}
.success-text {
  text-align: center;

  i {
    font-size: 50px;
    color: #4ead4e;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    text-transform: capitalize;
  }

  &.order-fail {
    i {
      color: $theme-primary;
    }
  }
}
.order-success-sec {
  h4 {
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    color: $theme-primary;
  }

  .order-detail {
    li {
      display: flex;
      text-transform: capitalize;
      line-height: 2;
      font-size: 16px;
      color: #33322d;
      font-family: "Work Sans", sans-serif;
    }
  }

  .payment-mode {
    p {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: rgba($brown6, 0.4);

  h2,
  h3 {
    color: $theme-primary;

    text-transform: capitalize;
  }

  h3 {
    font-size: 24px;
    margin-top: -5px;
  }

  h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: -10px;
  }
}
.product-order {
  h3 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: $theme-primary;
    line-height: 23px;
  }

  .product-order-detail {
    margin-top: 15px;
    img {
      max-height: 130px;
    }
    .order_detail {
      display: flex;
      align-items: center;
      h4 {
        font-size: 18px;
        margin-bottom: 5px;
        text-transform: capitalize;
        font-weight: 700;
        color: $theme-primary;
      }
      h5 {
        font-size: 16px;
        text-transform: capitalize;
        margin-bottom: 0;
        margin-top: 15px;
      }
    }
  }

  .total-sec {
    border-top: 1px solid $round-border;
    border-bottom: 1px solid $round-border;
    margin: 15px 0;
    padding: 15px 0;
    ul {
      li {
        text-transform: capitalize;
        line-height: 2;
        font-family:  $font_1;
        span {
          float: right;
          color: $theme-primary;
        }
      }
    }
  }

  .final-total {
    h3 {
      display: block;
      margin-bottom: 0;

      span {
        float: right;
      }
    }
  }
}

// compare //
.compare-section {
  .compare-part {
    position: relative;

    .close-btn {
      background-color: transparent;
      border: none;
      font-size: 25px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .img-secton {
      padding: 25px 15px 15px;

      img,
      .bg-size {
        width: 50%;
        margin: 0 auto;
      }

      a {
        h5 {
          margin-bottom: 0;
          text-transform: capitalize;
          margin-top: 10px;
        }
      }

      h5 {
        margin-bottom: 0;
        line-height: 1.2;
      }
    }

    .detail-part {
      .title-detail {
        background-color: #f7f8fa;
        text-align: center;
        padding: 8px 0;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;

        h5 {
          margin-bottom: 0;
          text-transform: uppercase;
        }
      }

      .inner-detail {
        padding: 15px;

        p {
          margin-bottom: 0;
          line-height: 1.2;
          letter-spacing: 0.05em;
        }
      }
    }

    .btn-part {
      text-align: center;
      padding: 15px;
      border-top: 1px solid #ddd;

      .btn-solid,
      .btn-outline {
        padding: 5px 10px;
      }
    }
  }

  .slick-slide {
    > div {
      border: 1px solid #ddd;
      border-left: none;
    }

    &:first-child {
      border-left: 1px solid #ddd;
    }
  }
}
.compare-padding {
  margin-bottom: -20px;

  h2 {
    margin-bottom: 20px;
  }
}
.compare-lable {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1;
}
.compare-page {
  .table-wrapper {
    padding-bottom: 0;
    .table {
      border: 1px solid $theme-primary-light;
      text-transform: capitalize;
      color: $theme-primary;
      .product-name {
        width: 15%;
      }
      .featured-image {
        width: 185px;
      }
      td {
        border-top: none;
      }

      thead {
        .th-compare {
          td {
            font-size: 14px;
            font-weight: 700;
            background: lighten($theme-primary-light, 30%);
            border-right: 1px solid $theme-primary-light;
          }

          th {
            text-align: left;
            border-top: 1px solid $theme-primary-light;
            border-bottom: 1px solid $theme-primary-light;
            border-right: 1px solid $theme-primary-light;
            button {
              color: $theme-primary;
              cursor: pointer;
            }

            .remove-compare {
              float: left;
              border: none;
              background: transparent;
              padding: 0;
              font-size: 14px;
              font-weight: 500;
              font-family: $font_1;
            }
          }
        }
      }

      tbody {
        tr {
          th {
            background: lighten($theme-primary-light, 30%);
            font-family: $font_1;
          }

          td {
            border-right: 1px solid $theme-primary-light;
            position: relative;
            font-family: $font_1;
          }

          th,
          td {
            padding: 15px;
            vertical-align: top;
            border-bottom: 1px solid $theme-primary-light;
            border-top: 1px solid $theme-primary-light;
            border-right: 1px solid $theme-primary-light;
            font-family: $font_1;
          }

          .grid-link__title {
            font-size: 15px;
            font-family: $font_1;
          }

          .product_price {
            margin: 15px 0;

            span.money {
              padding-left: 5px;
            }
          }
          p {
            font-size: 13px;
            line-height: 20px;
            margin: 0;
            font-family: $font_1;
          }
          .add-to-cart,
          .select-option-cls {
            padding: 10px 18px;
          }
        }
      }
    }
  }
}

// typography page //
.typography_section {
  .typography-box {
    margin-bottom: 30px;

    p {
      line-height: 22px;
    }

    .headings {
      border-bottom: 1px solid #f1f1f1;

      margin-bottom: 20px;
      background-color: rgba($grey-about, 0.5);
      padding: 8px 12px;

      h3 {
        text-transform: capitalize;
        color: #2d2d2d;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 20px;
      }

      span {
        color: $grey;

        code {
          color: $grey;
        }
      }
    }

    .typo-content {
      &.heading_content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 400;
          color: black;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 10px;
        }
      }

      .sub-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 5px;
        margin-bottom: 8px;
        font-size: 18px;
        color: $dark-font;
        text-transform: capitalize;
      }

      &.product-pagination {
        border: none;
        margin: 0;

        .pagination {
          .page-item {
            border-top: 1px solid $theme-primary-light;
            border-bottom: 1px solid $theme-primary-light;

            a {
              padding: 10px 14px;
            }
          }
        }
      }

      &.input_button {
        input {
          margin-bottom: 10px;
        }

        label {
          font-size: 16px;
          text-transform: capitalize;
          padding-left: 5px;
        }
      }

      &.loader-typo {
        width: 100%;
        justify-content: center;
        display: flex;

        .pre-loader {
          position: relative;
          width: 50px;
          height: 50px;
          background-color: $theme-primary;
          border-radius: 50%;

          &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            border: 0 solid white;
            transform: translate(-50%, -50%);
            animation: loader-wrapper 1000ms ease-out forwards infinite;
            transition: all 0.3s ease;
          }
        }
      }

      .footer-social {
        margin-top: 0;
        justify-content: center;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  code {
    color: rgba($theme-primary, 0.6);
    background-color: #f7f7f7;
    padding: 3px;
    margin: 0 3px;
    border-radius: 2px;
  }
}

// account page //
.profile-section {
  background-color: #f5f5f5;
  border: 1px solid $round-border;
  padding: 15px;

  .profile-detail {
    .profile-name {
      font-weight: 700;
    }

    p {
      margin-bottom: 8px;
    }
  }
}
.btn-theme {
  background-color: $theme-primary;
}
